import { Directive, Injector, Input } from '@angular/core'
import { AbstractControl, NgControl } from '@angular/forms'

/**
 * Generic integration of ControlValueAccessor for custom input components
 */
@Directive()
export abstract class ControlValueAccessorBase {
  @Input() hint?: string
  @Input() required?: boolean
  @Input() disabled?: boolean = false

  @Input() classes?: string[] | string
  currentValue?: any

  _control!: NgControl

  constructor(public injector: Injector) {
    // istanbul ignore next line
    if (this.classes) {
      // istanbul ignore next line
      this.classes = (<string[]>this.classes)?.join(' ')
    }
    this._control = this.injector.get(NgControl)
    this._control.valueAccessor = this
    //console.log('this._control', this._control)
  }

  // ngOnInit() {
  //   this._control = this.injector.get(NgControl)
  // }

  onChange = (value: any) => {
    //console.log('this._control', this._control)
  }

  onTouched = () => {}

  writeValue(obj: any): void {
    this.currentValue = obj
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {}
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  radioChange(event: any, control: any) {
    console.log(event, control)
  }
}
