import { ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core'
import {
  FormControl,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators
} from '@angular/forms'
import { RadioTextGroupConfig, CommonValidators } from '@helvetia-italia/angular-libs/ng-shared'
import { Observable, of, Subscription } from 'rxjs'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { GroupValueAccessorBase } from '../helpers/group-value-accessor-base'

@Component({
  selector: 'helvetia-italia-radio-text-group',
  templateUrl: './radio-text-group.component.html',
  styleUrls: ['./radio-text-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioTextGroupComponent,
      multi: true
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: RadioTextGroupComponent,
      multi: true
    }
  ]
})
export class RadioTextGroupComponent extends GroupValueAccessorBase implements OnInit {
  form = new FormGroup({})
  @Input() config!: RadioTextGroupConfig
  public subscriptions: Subscription[] = []

  options: { label: string; value: string }[] = []

  constructor(
    public cdRef: ChangeDetectorRef,
    private controlErrorsMessageService: ControlErrorsMessageService,
    public commonValidators: CommonValidators
  ) {
    super(cdRef)
  }

  ngOnInit() {
    this.initForm()
    this.form.get('option')?.valueChanges.subscribe((value) => {
      const input = this.getLinkedInput(value)
      if (input) {
        this.form.get(input?.name)?.enable({ emitEvent: false })
        Object.keys(this.form.controls).forEach((control) => {
          if (control !== input.name && control !== 'option') {
            this.form.get(control)?.disable({ emitEvent: false })
          }
        })
      } else {
        Object.keys(this.form.controls).forEach((control) => {
          if (control !== 'option') {
            this.form.get(control)?.disable({ emitEvent: false })
          }
        })
      }
    })
  }

  initForm() {
    if (!this.config || !this.config.options) {
      throw Error('No config provided for RadioTextGroup')
    }

    this.form.addControl('option', new FormControl({}, Validators.required))

    this.config.inputs.forEach((input) => {
      this.form.addControl(input.name, new FormControl({}, this.getValidatorsFromDictionary(input.mappedValidators)))
      this.form.get(input.name)?.disable({ emitEvent: false })
    })

    this.config.formGroupFn(this.form)
  }

  getLinkedInput(radioName: string) {
    return this.config.inputs.find((input) => input.linkedOption === radioName)
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  getValidatorsFromDictionary(validators: string[] = []) {
    const result: ValidatorFn[] = []
    validators.forEach((validator) => {
      if (validator) {
        result.push(this.commonValidators.getValidator(validator))
      }
    })

    return result
  }

  validate() {
    return of(this.form.valid ? null : { notValid: true })
  }
}
