import { Component, Input } from '@angular/core'
import { TableDefaultColumn } from '../table-default-column'

@Component({
  selector: 'helvetia-italia-default-cell',
  templateUrl: './table-default-cell.component.html',
  styleUrls: ['./table-default-cell.component.scss']
})
export class TableDefaultCellComponent {
  @Input() col: TableDefaultColumn<any> = {}
  @Input() row: any = {}
}
