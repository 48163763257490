export interface Document {
  documentType?: string
  mediaId?: string
  mediaUrl?: string
  status?: string
  uploadDate?: Date | string
}

export enum CB_DOCUMENT_STATUS {
  TO_VERIFY = 'to_verify',
  APPROVED = 'accepted',
  REJECTED = 'rejected'
}

export enum CB_DOCUMENT_TYPE {
  FISCAL_CODE = 'fiscal_code',
  IDENTITY_CARD = 'identity_card'
}
