import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BoxInputComponent } from './box-input.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { InputWithLabelModule } from '../input-with-label/input-with-label.component.module'
import { SelectModule } from '../select/select.module'
import { CheckboxModule } from '../checkbox/checkbox.module'
import { MatIconModule } from '@angular/material/icon'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
@NgModule({
  declarations: [BoxInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatCardModule,
    InputWithLabelModule,
    SelectModule,
    CheckboxModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [BoxInputComponent]
})
export class BoxInputModule {}
