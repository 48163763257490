export interface Environment {
  [x: string]: any
  production: boolean
  MOCK: boolean
  apiDevelServer: string
  apiServer: string
  apiLogin: string
  btcProductNode: string
  googleApiKey: string
  publicKey: string
  agentLoginUrl: string
  liquidatoreLoginUrl: string
  customerLoginUrl: string
  shopLoginUrl: string
  remoteSellingLoginUrl: string
  affinityLoginUrl: string
  fiscalCodeValidityUrl: string
  fiscalCodeValiditySecret: string
  sendEmailVerificationUrl: string
  sendOtpVerificationUrl: string
  checkEmailVerificationUrl: string
  checkOtpVerificationUrl: string
  registerUrl: string
  signUpLoginUrl: string
  updateUrl: string
  forgotPasswordUrl: string
  changePasswordUrl: string
  uploadMediaIdUrl: string
  uploadToMediaStorageUrl: string
  oneTrustGetConsentsUrl: string
  oneTrustSaveConsentsUrl: string
  oneTrustStoredConsentsUrl: string
  signUpBackRedirect: {
    [key: string]: string
  }
  oneTrustConsentsId: string
  formErrorMessages: {
    [key: string]: string
  }
  features: string
  nonOperationalAgent: string
}
export const Features = {
  REMOTE_SELLING: 'REMOTE_SELLING',
  PRE_SELLING: 'PRE_SELLING',
  SHOPS: 'SHOPS',
  AFFINITY: 'AFFINITY'
}
