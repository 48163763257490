import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Footer, Header, HeaderTemplate } from '../../interfaces'

@Injectable({
  providedIn: 'root'
})
export class StateService {
  headerTemplate$ = new BehaviorSubject<HeaderTemplate>(HeaderTemplate.Default)
  headerTitle$ = new BehaviorSubject<string>('')

  header$ = new BehaviorSubject<Header>({})
  footer$ = new BehaviorSubject<Footer>({})
  constructor() {}
}
