import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GoogleMapsAutocompleterComponent } from './google-maps-autocompleter.component'
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

const material_modules = [MatFormFieldModule, MatInputModule]

@NgModule({
  declarations: [GoogleMapsAutocompleterComponent],
  imports: [CommonModule, MatGoogleMapsAutocompleteModule, ReactiveFormsModule, FormsModule, ...material_modules],
  exports: [GoogleMapsAutocompleterComponent]
})
export class GoogleMapsAutocompleterModule {}
