import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms'
import { Consent, RegistrationFormService } from '@helvetia-italia/angular-libs/ng-shared'
import { Subscription } from 'rxjs'

import { GroupValueAccessorBase } from '../../inputs'
import { ControlErrorsMessageService } from '../../inputs/helpers/control-errors-message.service'

@Component({
  selector: 'helvetia-italia-consensi-utente',
  templateUrl: './consensi-utente.component.html',
  styleUrls: ['./consensi-utente.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConsensiUtenteComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ConsensiUtenteComponent,
      multi: true
    }
  ]
})
export class ConsensiUtenteComponent extends GroupValueAccessorBase implements OnDestroy {
  form: FormGroup = new FormGroup({})
  consents: Consent[] = []
  public subscriptions: Subscription[] = []

  constructor(
    private builder: FormBuilder,
    public controlErrorsMessageService: ControlErrorsMessageService,
    public registrationFormService: RegistrationFormService,
    public cdRef: ChangeDetectorRef
  ) {
    super(cdRef)
    this.getConsents$()
  }

  getConsents$() {
    this.registrationFormService.getConsents().subscribe((consents) => {
      if (consents && consents.length > 0) {
        consents.forEach((consent) => {
          this.form.addControl(consent.name, this.builder.control('', consent.validators))
          this.form.updateValueAndValidity()
          this.consents.push(consent)
        })
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  validate() {
    return this.form.valid ? null : { consentsNotValid: true }
  }
}
