export const demandNeedsJobs = [
  {
    value: '101',
    label: 'Impiegato di Azienda Privata'
  },
  {
    value: '102',
    label: 'Impiegato di Azienda Elettrica'
  },
  {
    value: '103',
    label: 'Impiegato di Azienda Telefonica'
  },
  {
    value: '104',
    label: 'Operaio di Azienda Privata'
  },
  {
    value: '105',
    label: 'Operaio di Azienda Elettrica'
  },
  {
    value: '106',
    label: 'Operaio di Azienda Telefonica'
  },
  {
    value: '107',
    label: 'Operaio agricolo Comune'
  },
  {
    value: '108',
    label: 'Operaio agricolo Qualificato'
  },
  {
    value: '109',
    label: 'Operaio agricolo Specializzato'
  },
  {
    value: '110',
    label: 'Operaio agricolo Tempo determinato'
  },
  {
    value: '111',
    label: 'Personale di volo di Comp. Aerea'
  },
  {
    value: '112',
    label: 'Socio di Cooperativa'
  },
  {
    value: '113',
    label: 'Colf in fascia alta'
  },
  {
    value: '114',
    label: 'Colf in fascia media'
  },
  {
    value: '115',
    label: 'Colf in fascia minima'
  },
  {
    value: '116',
    label: 'Autoferrotranviere viaggiante'
  },
  {
    value: '117',
    label: 'Autoferrotranviere a terra'
  },
  {
    value: '118',
    label: 'Bancario'
  },
  {
    value: '119',
    label: 'Giornalista dipendente'
  },
  {
    value: '120',
    label: 'Ferrovieri Viaggianti'
  },
  {
    value: '121',
    label: 'Ferrovieri a terra'
  },
  {
    value: '201',
    label: 'Agente di Custodia'
  },
  {
    value: '202',
    label: 'Avvocato o Procuratore dello Stato'
  },
  {
    value: '203',
    label: 'Carabiniere'
  },
  {
    value: '204',
    label: 'Guardia di Finanza'
  },
  {
    value: '205',
    label: 'Impiegato Ministeriale'
  },
  {
    value: '206',
    label: 'Impiegato Azienda Municipalizzata'
  },
  {
    value: '207',
    label: 'Impiegato Camera Comm. o ISTAT'
  },
  {
    value: '208',
    label: 'Impiegato Comunale'
  },
  {
    value: '209',
    label: 'Impiegato di Ente di Assistenza'
  },
  {
    value: '210',
    label: 'Impiegato Enti Turismo'
  },
  {
    value: '211',
    label: 'Insegnante Asilo'
  },
  {
    value: '212',
    label: 'Insegnante di ruolo o supplente'
  },
  {
    value: '213',
    label: 'Magistrato ordinario o ammin.'
  },
  {
    value: '214',
    label: 'Medico dipendente A.S.L'
  },
  {
    value: '215',
    label: 'Medico ufficiale sanitario'
  },
  {
    value: '216',
    label: 'Militare Esercito, Aeron., Marina'
  },
  {
    value: '217',
    label: 'Operaio Azienda Ente Locale'
  },
  {
    value: '218',
    label: 'Operaio Azienda Statale'
  },
  {
    value: '219',
    label: 'Personale non medico A.S.L. ospedali'
  },
  {
    value: '220',
    label: 'Polizia di Stato'
  },
  {
    value: '221',
    label: 'Professore Universitario'
  },
  {
    value: '222',
    label: 'Ufficiale giudiziario'
  },
  {
    value: '223',
    label: 'Veterinario di Comuni o Provincie'
  },
  {
    value: '224',
    label: 'Vigile del Fuoco'
  },
  {
    value: '301',
    label: 'Dirigente Azienda Industriale'
  },
  {
    value: '302',
    label: 'Dirigente Azienda Comm. o Trasporti'
  },
  {
    value: '303',
    label: 'Dirigente Azienda Pubblica'
  },
  {
    value: '401',
    label: 'Amministr., sindaco, o revisore di società'
  },
  {
    value: '402',
    label: 'Imprenditore agricolo'
  },
  {
    value: '403',
    label: 'Imprenditore servizi o terziario'
  },
  {
    value: '404',
    label: 'Imprenditore Socio Accomandatario di s.a.s'
  },
  {
    value: '405',
    label: 'Imprenditore Socio Unico di s.r.l.'
  },
  {
    value: '406',
    label: 'Intermediario'
  },
  {
    value: '407',
    label: 'Partecipatore a collegi o commissioni'
  },
  {
    value: '501',
    label: 'Agente Rappresentante di commercio'
  },
  {
    value: '502',
    label: 'Artigiano Titolare'
  },
  {
    value: '503',
    label: 'Auton. abituale senza iscr. REA'
  },
  {
    value: '504',
    label: 'Auton. occasionale senza iscr. REA'
  },
  {
    value: '505',
    label: 'Autotrasportatore'
  },
  {
    value: '506',
    label: 'Collab. Coord. prog. attività aggiuntiva'
  },
  {
    value: '507',
    label: 'Collab. Coord. Prog. attività primaria'
  },
  {
    value: '508',
    label: 'Collaboratore di giornali e riviste'
  },
  {
    value: '509',
    label: 'Coltivatore Diretto Titolare in fascia 1'
  },
  {
    value: '510',
    label: 'Coltivatore Diretto Titolare in fascia 2'
  },
  {
    value: '511',
    label: 'Coltivatore Diretto Titolare in fascia 3'
  },
  {
    value: '512',
    label: 'Coltivatore Diretto Titolare in fascia 4'
  },
  {
    value: '513',
    label: 'Commerciante Titolare'
  },
  {
    value: '514',
    label: 'Esercente ausiliario di commercio'
  },
  {
    value: '515',
    label: 'Esercente turistico'
  },
  {
    value: '516',
    label: 'Famigliare coadiuvante di Artigiano'
  },
  {
    value: '517',
    label: 'Famigliare coadiuvante di Commerciante'
  },
  {
    value: '518',
    label: 'Famigliare Coltivatore Diretto in fascia 1'
  },
  {
    value: '519',
    label: 'Famigliare Coltivatore Diretto in fascia 2'
  },
  {
    value: '520',
    label: 'Famigliare Coltivatore Diretto in fascia 3'
  },
  {
    value: '521',
    label: 'Famigliare Coltivatore Diretto in fascia 4'
  },
  {
    value: '522',
    label: 'Venditore a domicilio'
  },
  {
    value: '523',
    label: 'Promotore Finanziario'
  },
  {
    value: '524',
    label: 'Agente di Assicurazione'
  },
  {
    value: '601',
    label: 'Agrotecnico'
  },
  {
    value: '602',
    label: 'Attuario'
  },
  {
    value: '603',
    label: 'Avvocato'
  },
  {
    value: '604',
    label: 'Biologo'
  },
  {
    value: '605',
    label: 'Chimico'
  },
  {
    value: '606',
    label: 'Consulente del Lavoro'
  },
  {
    value: '607',
    label: 'Dottore Agronomo'
  },
  {
    value: '608',
    label: 'Dottore Commercialista'
  },
  {
    value: '609',
    label: 'Farmacista'
  },
  {
    value: '610',
    label: 'Geologo'
  },
  {
    value: '611',
    label: 'Geometra'
  },
  {
    value: '612',
    label: 'Giornalista'
  },
  {
    value: '613',
    label: 'Giornalista praticante o pubblicitario'
  },
  {
    value: '614',
    label: 'Infermiere'
  },
  {
    value: '615',
    label: 'Ingegnere'
  },
  {
    value: '616',
    label: 'Medico della mutua, guardia med., ecc.'
  },
  {
    value: '617',
    label: 'Medico dipendente, non libero prof.'
  },
  {
    value: '618',
    label: 'Medico in clinica convenzionata al SSN'
  },
  {
    value: '619',
    label: 'Medico con studio special. convenz. SSN'
  },
  {
    value: '620',
    label: 'Medico con attività di libera professione'
  },
  {
    value: '621',
    label: 'Notaio'
  },
  {
    value: '622',
    label: 'Perito agrario'
  },
  {
    value: '623',
    label: 'Perito Industriale'
  },
  {
    value: '624',
    label: 'Psicologo'
  },
  {
    value: '625',
    label: 'Ragioniere'
  },
  {
    value: '626',
    label: 'Veterinario'
  },
  {
    value: '627',
    label: 'Clero'
  },
  {
    value: '628',
    label: 'Architetto'
  },
  {
    value: '700',
    label: 'Pensionato'
  },
  {
    value: '800',
    label: 'Non Occupato'
  }
]

export const vehicleColors = [
  {
    value: '04',
    label: 'Arancione'
  },
  {
    value: '14',
    label: 'Arancione - chiaro'
  },
  {
    value: '54',
    label: 'Arancione - metal dark'
  },
  {
    value: '44',
    label: 'Arancione - metal light'
  },
  {
    value: '34',
    label: 'Arancione - metal'
  },
  {
    value: '24',
    label: 'Arancione - scuro'
  },
  {
    value: '60',
    label: 'Beige'
  },
  {
    value: '00',
    label: 'Bianco'
  },
  {
    value: '30',
    label: 'Bianco - metal'
  },
  {
    value: '07',
    label: 'Blu'
  },
  {
    value: '17',
    label: 'Blu - chiaro'
  },
  {
    value: '57',
    label: 'Blu - metal dark'
  },
  {
    value: '47',
    label: 'Blu - metal light'
  },
  {
    value: '37',
    label: 'Blu - metal'
  },
  {
    value: '27',
    label: 'Blu - scuro'
  },
  {
    value: '05',
    label: 'Giallo'
  },
  {
    value: '15',
    label: 'Giallo - chiaro'
  },
  {
    value: '55',
    label: 'Giallo - metal dark'
  },
  {
    value: '45',
    label: 'Giallo - metal light'
  },
  {
    value: '35',
    label: 'Giallo - metal'
  },
  {
    value: '25',
    label: 'Giallo - scuro'
  },
  {
    value: '02',
    label: 'Grigio'
  },
  {
    value: '12',
    label: 'Grigio - chiaro'
  },
  {
    value: '52',
    label: 'Grigio - metal dark'
  },
  {
    value: '42',
    label: 'Grigio - metal light'
  },
  {
    value: '32',
    label: 'Grigio - metal'
  },
  {
    value: '22',
    label: 'Grigio - scuro'
  },
  {
    value: '09',
    label: 'Marrone'
  },
  {
    value: '19',
    label: 'Marrone - chiaro'
  },
  {
    value: '59',
    label: 'Marrone - metal dark'
  },
  {
    value: '49',
    label: 'Marrone - metal light'
  },
  {
    value: '39',
    label: 'Marrone - metal'
  },
  {
    value: '29',
    label: 'Marrone - scuro'
  },
  {
    value: '01',
    label: 'Nero'
  },
  {
    value: '31',
    label: 'Nero - metal'
  },
  {
    value: '61',
    label: 'Rosa'
  },
  {
    value: '03',
    label: 'Rosso'
  },
  {
    value: '13',
    label: 'Rosso - chiaro'
  },
  {
    value: '53',
    label: 'Rosso - metal dark'
  },
  {
    value: '43',
    label: 'Rosso - metal light'
  },
  {
    value: '33',
    label: 'Rosso - metal'
  },
  {
    value: '23',
    label: 'Rosso - scuro'
  },
  {
    value: '06',
    label: 'Verde'
  },
  {
    value: '16',
    label: 'Verde - chiaro'
  },
  {
    value: '56',
    label: 'Verde - metal dark'
  },
  {
    value: '46',
    label: 'Verde - metal light'
  },
  {
    value: '36',
    label: 'Verde - metal'
  },
  {
    value: '26',
    label: 'Verde - scuro'
  },
  {
    value: '08',
    label: 'Viola'
  },
  {
    value: '18',
    label: 'Viola - chiaro'
  },
  {
    value: '58',
    label: 'Viola - metal dark'
  },
  {
    value: '48',
    label: 'Viola - metal light'
  },
  {
    value: '38',
    label: 'Viola - metal'
  },
  {
    value: '28',
    label: 'Viola - scuro'
  },
  {
    value: '99',
    label: 'Non definito'
  }
]

export const timeList = [
  { label: '00:00', value: '00:00' },
  { label: '00:30', value: '00:30' },
  { label: '01:00', value: '01:00' },
  { label: '01:30', value: '01:30' },
  { label: '02:00', value: '02:00' },
  { label: '02:30', value: '02:30' },
  { label: '03:00', value: '03:00' },
  { label: '03:30', value: '03:30' },
  { label: '04:00', value: '04:00' },
  { label: '04:30', value: '04:30' },
  { label: '05:00', value: '05:00' },
  { label: '05:30', value: '05:30' },
  { label: '06:00', value: '06:00' },
  { label: '06:30', value: '06:30' },
  { label: '07:00', value: '07:00' },
  { label: '07:30', value: '07:30' },
  { label: '08:00', value: '08:00' },
  { label: '08:30', value: '08:30' },
  { label: '09:00', value: '09:00' },
  { label: '09:30', value: '09:30' },
  { label: '10:00', value: '10:00' },
  { label: '10:30', value: '10:30' },
  { label: '11:00', value: '11:00' },
  { label: '11:30', value: '11:30' },
  { label: '12:00', value: '12:00' },
  { label: '12:30', value: '12:30' },
  { label: '13:00', value: '13:00' },
  { label: '13:30', value: '13:30' },
  { label: '14:00', value: '14:00' },
  { label: '14:30', value: '14:30' },
  { label: '15:00', value: '15:00' },
  { label: '15:30', value: '15:30' },
  { label: '16:00', value: '16:00' },
  { label: '16:30', value: '16:30' },
  { label: '17:00', value: '17:00' },
  { label: '17:30', value: '17:30' },
  { label: '18:00', value: '18:00' },
  { label: '18:30', value: '18:30' },
  { label: '19:00', value: '19:00' },
  { label: '19:30', value: '19:30' },
  { label: '20:00', value: '20:00' },
  { label: '20:30', value: '20:30' },
  { label: '21:00', value: '21:00' },
  { label: '21:30', value: '21:30' },
  { label: '22:00', value: '22:00' },
  { label: '22:30', value: '22:30' },
  { label: '23:00', value: '23:00' },
  { label: '23:30', value: '23:30' }
]
