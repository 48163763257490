import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { EnvironmentService, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { Consent, OnetrustRegisterPayload } from '../../interfaces/consent'
import { MOCK_REGISTRATION_FORM_CONSENTS } from '../../mocks/registration-form'

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormService {
  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private environmentService: EnvironmentService
  ) {}

  getConsents(company?: string): Observable<Consent[]> {
    return this.http
      .get<{ consents: Consent[] }>(`${this.environmentService.oneTrustGetConsentsUrl}?id=${company}`)
      .pipe(map((data) => data.consents))
  }

  getStoredConsents(email: any): Observable<any> {
    return this.http.get(`${this.environmentService.oneTrustGetConsentsUrl}/${email}`)
  }

  registerConsents(data: OnetrustRegisterPayload): Observable<any> {
    if (data.requestInformation === '') {
      data.requestInformation = this.environmentService.ONETRUST_REGISTER_TOKEN
    }
    return this.http.post(`${this.environmentService.oneTrustSaveConsentsUrl}`, data)
  }
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormMockService {
  getConsents(company?: string): Observable<Consent[]> {
    const _consents = MOCK_REGISTRATION_FORM_CONSENTS
    return of(_consents)
  }
}
