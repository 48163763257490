import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Observable, of, throwError } from 'rxjs'
import { map } from 'rxjs/operators'

import { TipoRichiesta, UserRequest, TipoRichiestaLabel } from '../../interfaces/user-request'
import {
  annulloDirittoRipensamento,
  annulloRecessoSinistroContraente,
  Mock_lista_richieste,
  reInvioDocumentoPolizza,
  richiestaGenerica,
  richiestaInformativaProdotto,
  annulloDisdettaContraente
} from '../../mocks/user-request'
import { AbstractItemsService } from '../abstract-item.service'

export interface UserRequestServiceEnvironment {
  getListUserRequestUrl: string
  getDetailUserRequestUrl: string
  createRequestUrl: string
  createRequestExceptionUrl: string
}
@Injectable({
  providedIn: 'root'
})
export class UserRequestService implements AbstractItemsService<any> {
  constructor(private http: HttpClient, @Inject(EnvironmentServiceToken) private env: UserRequestServiceEnvironment) {}

  getList(): Observable<UserRequest[]> {
    return this.http.get<UserRequest[]>(this.env.getListUserRequestUrl).pipe(
      map((requests) => {
        return requests.sort(
          (a, b) => new Date(a.createdAt as string).getTime() - new Date(b.createdAt as string).getTime()
        )
      })
    )
  }

  getDetail(id: string): Observable<UserRequest> {
    return this.http.get<UserRequest>(`${this.env.getDetailUserRequestUrl}/${id}`)
  }

  create(data: UserRequest): Observable<any> {
    return this.http.post(this.env.createRequestUrl, data)
  }

  createException(policyNumber: number, requestType: string, body = {}) {
    return this.http.post(`${this.env.createRequestExceptionUrl}/${requestType}/${policyNumber}`, body)
  }

  getFormDefinition(tipoRichiesta: TipoRichiesta): Observable<any> {
    switch (tipoRichiesta) {
      default:
        return throwError('Form definition not found')
      case TipoRichiesta.DomandaProdotto:
        return of(richiestaInformativaProdotto)
      // case 'RichiestaOperativaSpecificaPiattaforma':
      //   return of(richiestaOperativaPiattaforma)
      case TipoRichiesta.DomandaGenerica:
        return of(richiestaGenerica)
      case TipoRichiesta.ReInvioDocumentoPolizza:
        return of(reInvioDocumentoPolizza)
      case TipoRichiesta.AnnulloDisdettaContraente:
        return of(annulloDisdettaContraente)
      case TipoRichiesta.AnnulloDirittoRipensamento:
        return of(annulloDirittoRipensamento)
      case TipoRichiesta.AnnulloRecessoSinistroContraente:
        return of(annulloRecessoSinistroContraente)
      // case TipoRichiesta.AnnulloCessazioneRischio:
      //   return of(annulloCessazioneRischio)
    }
  }

  getFilterList(): { value: string; label: string }[] {
    const filters = Object.keys(TipoRichiestaLabel).map((tipo) => {
      return {
        value: tipo,
        // @ts-ignore
        label: TipoRichiestaLabel[tipo]
      }
    })
    return filters
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserRequestMockService implements AbstractItemsService<any> {
  constructor() {}
  /* istanbul ignore next */
  getList() {
    return of(Mock_lista_richieste)
  }
  /* istanbul ignore next */
  getFormDefinition(tipoRichiesta: string): Observable<any> {
    switch (tipoRichiesta) {
      default:
        return throwError('Form definition not found')
      case 'contract-clarification':
        return of(richiestaInformativaProdotto)
      // case 'RichiestaOperativaSpecificaPiattaforma':
      //   return of(richiestaOperativaPiattaforma)
      case 'generic-question':
        return of(richiestaGenerica)
      case 'resend-insurance-document':
        return of(reInvioDocumentoPolizza)
      // case 'AnnulloDisdettaContraente':
      //   return of(annulloDisdettaContraente)
      // case 'AnnulloCessazioneRischio':
      //   return of(annulloCessazioneRischio)
      case 'cancel-by-right-to-reconsider':
        return of(annulloDirittoRipensamento)
      case 'cancel-by-withdrawal-contractor-claim':
        return of(annulloRecessoSinistroContraente)
    }
  }
  /* istanbul ignore next */
  getFilterList(): { value: string; label: string }[] {
    const filters = Object.keys(TipoRichiesta).map((tipo) => {
      return {
        value: tipo,
        // @ts-ignore
        label: TipoRichiesta[tipo]
      }
    })
    return filters
  }
  /* istanbul ignore next */
  create(data: UserRequest) {
    return of({ created: true })
  }
}
