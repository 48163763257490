import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ChangePasswordComponent } from './change-password.component'

import { MatButtonModule } from '@angular/material/button'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
import { InputWithLabelModule } from '../../inputs'

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, InputWithLabelModule, MatButtonModule],
  exports: [ChangePasswordComponent]
})
export class ChangePasswordModule {}
