import { Component, EventEmitter, Injector, Input, Output } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'
@Component({
  selector: 'helvetia-italia-input-with-label',
  templateUrl: './input-with-label.component.html',
  styleUrls: ['./input-with-label.component.scss']
})
export class InputWithLabelComponent extends ControlValueAccessorBase implements ControlValueAccessor {
  @Input()
  placeholder?: string

  @Input()
  type = 'text'

  @Input()
  showCancel = true

  @Input()
  autocomplete!: string

  @Output()
  changeValueInput: EventEmitter<any> = new EventEmitter()

  @Output()
  keyDownInput: EventEmitter<any> = new EventEmitter()

  @Output()
  ngChangeValueInput: EventEmitter<any> = new EventEmitter()

  value = ''
  constructor(public injector: Injector, private controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
  }

  reset() {
    this.currentValue = ''
    this._control.reset()
  }
  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  onChangeValueInput(event: any) {
    this.changeValueInput.emit(event.value)
  }

  onNgModelChangeInput(event: any) {
    this.ngChangeValueInput.emit(event.value)
  }

  onKeyDownInput(event: any) {
    this.keyDownInput.emit(event.value)
  }
}
