import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { ExpandableFooterComponentsComponent } from './expandable-footer-component/expandable-footer-components.component'
import { PremioComponent } from './premio-component/premio.component'
import { IconExpandableComponentComponent } from './icon-expandable-component/icon-expandable-component.component'
import { SurveyComponent } from './survey-component/survey.component'
import { FatFooterComponent } from './fat-footer/fat-footer.component'
import { UxNgFatFooterMenuModule, UxNgFatFooterModule } from '@helvetiait/ux-ng-material-library'
import { PipesModule } from '@helvetia-italia/angular-libs/ng-shared'

const material_modules = [MatDialogModule, MatIconModule, MatButtonModule]
const helvetia_modules = [UxNgFatFooterMenuModule, UxNgFatFooterModule]
@NgModule({
  imports: [CommonModule, FlexLayoutModule, ...material_modules, ...helvetia_modules, PipesModule],
  exports: [
    ExpandableFooterComponentsComponent,
    PremioComponent,
    SurveyComponent,
    IconExpandableComponentComponent,
    FatFooterComponent
  ],
  declarations: [
    ExpandableFooterComponentsComponent,
    PremioComponent,
    SurveyComponent,
    IconExpandableComponentComponent,
    FatFooterComponent
  ],
  entryComponents: [PremioComponent, SurveyComponent]
})
export class FooterComponentsModule {}
