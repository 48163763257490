import { Component, EventEmitter, Injector, Input, Output } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent extends ControlValueAccessorBase implements ControlValueAccessor {
  @Input()
  placeholder?: string
  @Input()
  autocomplete!: string
  @Output()
  changeValueInput: EventEmitter<any> = new EventEmitter()
  constructor(public injector: Injector, private controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
  }
  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  onChangeValueInput(event: any) {
    this.changeValueInput.emit(event.value)
  }
}
