import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { RouterModule } from '@angular/router'
import { PipesModule } from '@helvetia-italia/angular-libs/ng-shared'
import { MatTableExporterModule } from 'mat-table-exporter'
import { MatTooltipModule } from '@angular/material/tooltip'

import { TableDefaultCellComponent } from './table-default-cell/table-default-cell.component'
import { TableDefaultComponent } from './table-default.component'

const material_modules = [
  MatTableModule,
  MatTableExporterModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatBadgeModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatTooltipModule
]
@NgModule({
  declarations: [TableDefaultComponent, TableDefaultCellComponent],
  imports: [CommonModule, RouterModule, PipesModule, FlexLayoutModule, ...material_modules],
  exports: [TableDefaultComponent]
})
export class TableDefaultModule {}
