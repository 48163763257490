import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  AfterViewChecked,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ChangeDetectorRef
} from '@angular/core'
import { ControlValueAccessor, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subscription } from 'rxjs'

import { DynamicForm } from '../model/models'
import { FormComposerDynamicComponentBuilderService } from '../services/form-composer-dynamic-component-builder.service'
import { FormComposerGroupService } from '../services/form-composer-group.service'

@Component({
  selector: 'helvetia-italia-form-composer',
  templateUrl: './composer.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ComposerComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ComposerComponent,
      multi: true
    }
  ]
})
export class ComposerComponent implements ControlValueAccessor, OnChanges, AfterViewChecked, OnDestroy {
  @ViewChild('vc', { read: ViewContainerRef, static: true })
  vc?: ViewContainerRef

  @Input()
  formConfig!: DynamicForm

  form!: FormGroup

  public subscriptions: Subscription[] = []

  get value(): any {
    return this.form.getRawValue()
  }

  set value(value: any) {
    this.form.patchValue(value, { emitEvent: false })
    this.onChange(value)
    this.onTouched()
  }

  constructor(
    private formComposerDynamicComponentBuilderService: FormComposerDynamicComponentBuilderService,
    private formComposerGroupService: FormComposerGroupService,
    private changeDetector: ChangeDetectorRef
  ) {}

  // Composer life cycle
  ngOnChanges(changes: SimpleChanges): void {
    // TODO
    /* istanbul ignore next */
    if (changes['formConfig']) {
      this.verifyFormDefinition()
      this.form = this.formComposerGroupService.getForm(this.formConfig)
      // TODO
      /* istanbul ignore next */
      if (!this.formConfig) return
      const newComponentRef = this.formComposerDynamicComponentBuilderService.createComponentFromRaw(this.formConfig)
      newComponentRef.instance.theForm = this.form
      // TODO
      /* istanbul ignore next */
      if (this.vc) {
        this.vc?.clear()
        this.vc.insert(newComponentRef.hostView)
      }
    }
  }

  ngAfterViewChecked(): void {
    //@ts-ignore
    // this.subscriptions.push(
    //   this.form.valueChanges.subscribe((value) => {
    //     this.onChange(value)
    //     this.onTouched()
    //   })
    // )
    this.changeDetector.detectChanges()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  private verifyFormDefinition() {
    /* istanbul ignore next line */
    if (!this.formConfig) throw new Error('[FormComposer] missing form definition')
  }

  // Control Value Accessor Implementation
  onChange: any = () => {}
  onTouched: any = () => {}

  registerOnChange(fn: any) {
    this.form.valueChanges.subscribe(fn)
  }

  writeValue(value: any) {
    if (value) {
      this.value = value
    }

    if (value === null) {
      this.form.reset()
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn
  }

  validate() {
    return this.form.valid ? null : { profile: { valid: false } }
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable()
  }
}
