import { CanActivate } from '@angular/router'

export class AgentsGuard implements CanActivate {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(): boolean {
    return true
  }
}
