import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { ENVIRONMENT, Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import moment from 'moment'
import { Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'

import { ApsaQuoteDetail, Guarantee, InfoCar, QuotationRequest, QuoteDataRetrieve, Vehicle } from '../../interfaces'
import { guaranteeListBike, guaranteeListMotorBike, guaranteeListVehicle } from '../../mocks/guarantee'
import { UserService } from '../user/user.service'

const cookie = 'connect.sid=s%3AY07u72LEj7lro2k9e7MSa4HmZ_IwVCwE.LPJs73fgJ520gvC6%2BhJHaGaYQdvgSWKtng01llPd7iA'
@Injectable({ providedIn: 'root' })
export class ApsaService {
  toDay: string = moment().format('YYYY-MM-DD')

  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private config: Environment,
    private userService: UserService
  ) {}

  dataRetrieve(vehicle: Vehicle) {
    // this.spinnerController.show()
    let url = ''
    if (vehicle.secondPlate) url += `&formatoTarga2=T&targa2=${vehicle.secondPlate}`
    if (vehicle.codiceFiscale2) url += `&codiceFiscale2=${vehicle.codiceFiscale2}`
    if (vehicle.tipoOpzione) url += `&tipoOpzione=${vehicle.tipoOpzione}`
    if (vehicle.dataImmatricolazione) url += `&dataImmatricolazione=${vehicle.dataImmatricolazione}`
    if (vehicle.provincia) url += `&provincia=${vehicle.provincia}`

    return this.http
      .get<any>(
        `${this.config.apiServer}api/apsa/quoteDataRetrieve?formatoTarga=T&targa=${vehicle.plate}&dataDecorrenza=${this.toDay}&scadenzaContratto=${this.toDay}&dataRiferimento=${this.toDay}${url}`,
        {
          withCredentials: true,
          headers: {
            Authorization: cookie
          }
        }
      )
      .pipe(take(1))
  }

  infoCar(cavalli: string, codiceOmologazione: string, dataImmatricolazione: string): Observable<InfoCar> {
    // this.spinnerController.show()
    return this.http
      .get<InfoCar>(
        `${this.config.apiServer}api/apsa/infocar` +
          `?settore=1&classe=01&uso=01&cavalli=${cavalli}&codiceOmologazione=${codiceOmologazione}&dataImmatricolazione=${dataImmatricolazione}`
        // {
        //   withCredentials: true,
        //   headers: {
        //     Authorization: cookie
        //   }
        // }
      )
      .pipe(take(1))
  }

  infoCarLists(
    cavalli: string,
    dataImmatricolazione: string,
    codiceMarca?: string,
    codiceModello?: string,
    codiceAllestimento?: string
  ): Observable<Array<any>> {
    let url = ''
    if (codiceMarca) url += `&codiceMarca=${codiceMarca}`
    if (codiceModello) url += `&codiceModello=${codiceModello}`
    if (codiceAllestimento) url += `&codiceAllestimento=${codiceAllestimento}`
    return this.http
      .get<Array<any>>(
        `${this.config.apiServer}api/apsa/infocar/lists?cavalli=${cavalli}&dataImmatricolazione=${dataImmatricolazione}${url}`,
        {
          withCredentials: true
        }
      )
      .pipe(take(1))
  }

  infocarDetail(
    cavalli: string,
    dataImmatricolazione: string,
    codiceMarca: string,
    codiceModello: string,
    codiceAllestimento: string
  ): Observable<any> {
    const url = `&codiceMarca=${codiceMarca}&codiceModello=${codiceModello}&codiceAllestimento=${codiceAllestimento}`
    return this.http
      .get<any>(
        `${this.config.apiServer}api/apsa/infocar/detail?cavalli=${cavalli}&dataImmatricolazione=${dataImmatricolazione}${url}`,
        {
          withCredentials: true
        }
      )
      .pipe(take(1))
  }

  infoBikeLists(
    dataImmatricolazione: string,
    cilindrata?: any,
    codiceMarca?: string,
    codiceModello?: string,
    codiceAllestimento?: string
  ): Observable<Array<any>> {
    let url = ''
    if (codiceMarca) url += `&codiceMarca=${codiceMarca}`
    if (codiceModello) url += `&codiceModello=${codiceModello}`
    if (cilindrata) url += `&cilindrata=${cilindrata}`
    return this.http
      .get<Array<any>>(
        `${this.config.apiServer}api/apsa/infobike/lists?dataImmatricolazione=${dataImmatricolazione}${url}`,
        {
          withCredentials: true
        }
      )
      .pipe(take(1))
  }

  infoBike(
    dataImmatricolazione: string,
    codiceMarca?: string,
    codiceModello?: number,
    cilindrata?: any,
    codiceAllestimento?: string,
    marca?: string,
    costruttore?: string,
    numeroGiri?: string,
    pesoVeicolo?: string,
    cavalliFiscali?: string,
    potenza?: string,
    progressivo?: string
  ): Observable<Array<any>> {
    let url = ''
    if (codiceMarca) url += `&codiceMarca=${codiceMarca}`
    if (codiceModello) url += `&codiceModello=${codiceModello}`
    if (cilindrata) url += `&cilindrata=${cilindrata}`
    if (codiceAllestimento) url += `&codiceAllestimento=${codiceAllestimento}`
    if (marca) url += `&marca=${marca}`
    if (costruttore) url += `&costruttore=${costruttore}`
    if (numeroGiri) url += `&numeroGiri=${numeroGiri}`
    if (pesoVeicolo) url += `&pesoVeicolo=${pesoVeicolo}`
    if (cavalliFiscali) url += `&cavalliFiscali=${cavalliFiscali}`
    if (potenza) url += `&potenza=${potenza}`
    if (progressivo) url += `&progressivo=${progressivo}`
    return this.http
      .get<Array<any>>(`${this.config.apiServer}api/apsa/infobike?dataImmatricolazione=${dataImmatricolazione}${url}`, {
        withCredentials: true
      })
      .pipe(take(1))
  }

  guarantees(vehicle: Vehicle): Observable<Guarantee[]> {
    switch (vehicle.type) {
      default:
        return of([])
      case 'Vehicle':
        return of(guaranteeListVehicle)
      case 'Motorbike':
        return of(guaranteeListMotorBike)
      case 'Bike':
        return of(guaranteeListBike)
    }
  }

  new_quote(action: 'store' | 'calc', quotation: QuotationRequest) {
    if (action === 'calc') {
      return this.http
        .post<any>(`${this.config.apiServer}api/apsa/quote`, quotation, {
          withCredentials: true
        })
        .pipe(take(1))
    } else {
      return this.http
        .put<any>(`${this.config.apiServer}api/apsa/quote`, quotation, {
          withCredentials: true
        })
        .pipe(take(1))
    }
  }

  quote(preventivoNumero: string): Observable<ApsaQuoteDetail> {
    // this.spinnerController.show('Interrogo la base dati');
    return this.http
      .get<ApsaQuoteDetail>(`${this.config.apiServer}api/apsa/quote?chiaveDiStart=${preventivoNumero}`, {
        withCredentials: true
      })
      .pipe(take(1))
  }

  quoteMissingData(quote: ApsaQuoteDetail) {
    return this.http
      .post<any>(`${this.config.apiServer}api/apsa/quoteMissingData`, quote, {
        withCredentials: true
      })
      .pipe(take(1))
  }
}
