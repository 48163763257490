import { CommonModule } from '@angular/common'
import {
  Compiler,
  Component,
  ComponentFactory,
  ComponentRef,
  Injectable,
  Injector,
  Input,
  ModuleWithComponentFactories,
  NgModule,
  NgModuleRef
} from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'

import map from 'lodash/map'

import { FormElementComponent } from '../form-element/form-element.component'
import { DynamicForm, FormRow } from '../model/models'
import { FormComposerHtmlCompilerService } from './form-composer-html-compiler.service'
import { FormComposerLoaderService } from './form-composer-loader.service'

const SELECTOR_DYNAMIC_COMPONENT = 'my-dyn-form'

class AnonymousComponent {
  @Input()
  theForm?: FormGroup
}

/*
@Injectable({
  providedIn: NgFormComposerModule
})
*/
@Injectable()
export class FormComposerDynamicComponentBuilderService {
  constructor(
    private formComposerLoaderService: FormComposerLoaderService,
    private compiler: Compiler,
    private injector: Injector,
    private moduleRef: NgModuleRef<any>,
    private formComposerHtmlCompilerService: FormComposerHtmlCompilerService
  ) {}

  /**
   * Use this method to create component that wrap a formGroup with a list of component
   * this system use a flexbot to handle the view organization
   * @param dynamicForm
   * @returns
   */
  createComponentFromRaw(dynamicForm: DynamicForm): ComponentRef<AnonymousComponent> {
    // const formComponents = dynamicForm.components
    const formComponents = dynamicForm.rows
    const components = this.buildTemplates(formComponents)
    const template = `
        <div [formGroup]="theForm" class="form_container">
          ${components}
        </div>
      `
    const component = Component({
      selector: SELECTOR_DYNAMIC_COMPONENT,
      template
    })(AnonymousComponent)

    const toImport = [CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule].concat(
      this.formComposerLoaderService.get()
    )

    const dynamicModule = NgModule({
      imports: toImport,
      declarations: [component, FormElementComponent]
      // providers: [] - e.g. if your dynamic component needs any service, provide it here.
    })(class {})

    const compiledModule = this.compiler.compileModuleAndAllComponentsSync(dynamicModule)

    const factory = this.findFactory(compiledModule, SELECTOR_DYNAMIC_COMPONENT)

    const componentReference = factory.create(this.injector, [], null, this.moduleRef)
    return componentReference
  }

  private findFactory(
    module: ModuleWithComponentFactories<any>,
    selector: string
  ): ComponentFactory<AnonymousComponent> {
    const factory = module.componentFactories.filter((e) => e.selector === selector)
    /* istanbul ignore next line */
    if (factory.length !== 1) throw new Error(`Cannot find the Factory for ${selector}`)
    return factory[0]
  }

  private buildTemplates(formComponents: FormRow[]): string {
    const res = map(formComponents, (row) => {
      return `${this.getRowHeader(row)}
      <div ${this.getRowAttributes(row)}>
      ${map(row.components, (component) => {
        return this.formComposerHtmlCompilerService.createHtml(component)
      }).join('')}
      </div>`
    }).join('')
    return res
  }

  // TODO
  /* istanbul ignore next function */
  private getRowAttributes(row: FormRow) {
    return map(row.attributes, (attributeValue, attributeName) => {
      return `${attributeName}="${attributeValue}"`
    }).join('')
  }

  // TODO
  /* istanbul ignore next function */
  private getRowHeader(row: FormRow) {
    if (!row.label) return ''
    return `<div fxLayout="row" style="margin-bottom:1em" ${this.getRowAttributes(row)}>
             <span fxFlex="60" style="text-align:left"> ${row.label ? '<h3>' + row.label + '</h3>' : ''}</span>
            </div>
           `
  }
}
