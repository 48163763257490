// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  cdn: '/cdn/consents/config/environment.json',
  production: true,
  MOCK: false
  //  oneTrustGetConsentsUrl: 'https://api.selly-devel.helvetia.it/api/v2/consents',
  //  oneTrustSaveConsentsUrl: 'https://api.selly-devel.helvetia.it/api/v2/consents/register',
  //  oneTrustConsentsId: '3342e030-a0f7-426b-9e0e-9bdded1cf30f',
  //  formErrorMessages: {
  //    required: 'È necessario esprimere il consenso'
  //  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
