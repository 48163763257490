import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'helvetia-italia-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
