import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CurrencyPipe } from './currency/currency.pipe'
import { FiltraProdottiPipe } from './filtra-prodotti/filtra-prodotti.pipe'
import { TransformDatePipe } from './date/date.pipe'
import { NumberFormatPipe } from './number/number.pipe'

@NgModule({
  declarations: [CurrencyPipe, FiltraProdottiPipe, TransformDatePipe, NumberFormatPipe],
  imports: [CommonModule],
  exports: [CurrencyPipe, FiltraProdottiPipe, TransformDatePipe, NumberFormatPipe],
  providers: [CurrencyPipe, FiltraProdottiPipe, TransformDatePipe, NumberFormatPipe]
})
export class PipesModule {}
