import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { Observable } from 'rxjs'
import { UserService } from '../ui-services/user/user.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public user: UserService, public router: Router) {}
  //  TODO: Serve un authguard generico?
  // istanbul ignore next
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userSate = this.user.getLocalUserState()
    if (this.user.getLocalUserState()) {
      return userSate !== undefined && userSate.logged === true
    }
    window.location.href = '/sign-up'
    return false
    // return this.user.isAuthenticated$.pipe(
    //   map((isAuth) => {
    //     if (isAuth) {
    //       return true
    //     } else if (route.queryParams.origin) {
    //       this.router.navigate(['/accedi'], route.queryParams.origin)
    //       return false
    //     } else {
    //       this.router.navigate(['/accedi'], route.queryParams.origin)
    //       return false
    //     }
    //   })
    // )
  }
}
