export * from './lib/angular-libs-ng-shared.module'

export * from './lib/ui-services'
export * from './lib/interfaces'
export * from './lib/mocks'
export * from './lib/interceptors'
export * from './lib/utils'
export * from './lib/pipes/pipes.module'
export * from './lib/pipes/currency/currency.pipe'

export * from './lib/validators'
// services
export * from './lib/ui-services/product-list/product-list.service'
export * from './lib/ui-services/abstract-item.service'
export * from './lib/ui-services/registration-form/registration-form.service'
export * from './lib/ui-services/user/user.service'
export * from './lib/ui-services/user/user-mock.service'

export * from './lib/interceptors/http-response.interceptor'

// Interfaces
export * from './lib/interfaces/modal-input'
export * from './lib/interfaces/product-teaser.models'
export * from './lib/interfaces/header'
export * from './lib/interfaces/stepper'
export * from './lib/interfaces/summary-questionario'
export * from './lib/interfaces/user.models'
export * from './lib/interfaces/consent'
export * from './lib/interfaces/thankyou'

// Login
export * from './lib/ui-services/login/fake-backend'
export * from './lib/ui-services/login/authentication.service'

// Mock
export * from './lib/mocks/user'

//Guards
// export * from './lib/guards/thank-you.guard'
export * from './lib/guards/application-guard-by-usertype-builder.guard'

//Code
export * from './lib/services/core/'
// export * from './lib/guards/affinity.guard'
// export * from './lib/guards/customer.guard'
export * from './lib/guards/auth.guard'
export * from './lib/guards/agent.guard'
export * from './lib/guards/login.guard'
export * from './lib/guards/fea.agent.guard'
export * from './lib/guards/fea.direction.guard'
export * from './lib/guards/4direction.guard'
export * from './lib/guards/zero-imprevisti.agent.guard'
