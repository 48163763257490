import { Injectable } from '@angular/core'
import { BehaviorSubject, fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { DeviceScreenType } from '../../interfaces'

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  deviceScreenType$: BehaviorSubject<any> = new BehaviorSubject(DeviceScreenType.Desktop)
  constructor() {}

  watchDeviceChanges() {
    this.getMediaBreakPoint(window.innerWidth)
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000))
      .subscribe((event: any) => {
        if (event && event.target) {
          const width = event.target.innerWidth
          this.getMediaBreakPoint(width)
        }
      })
  }

  getMediaBreakPoint(width: number) {
    this.deviceScreenType$.next(this.getSize(width))
  }

  getSize(width: number) {
    if (width < 576) return DeviceScreenType.Mobile
    if (width >= 576 && width < 768) return DeviceScreenType.Mobile
    if (width >= 768 && width < 992) return DeviceScreenType.Tablet
    if (width >= 992 && width < 1200) return DeviceScreenType.Desktop
    if (width >= 1200 && width < 1600) return DeviceScreenType.Desktop
    if (width < 576) return DeviceScreenType.Desktop
    return DeviceScreenType.Desktop
  }
}
