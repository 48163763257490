import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { ModalComponentsModule } from '../../modal-components/modal-components.module'
import { InputWithLabelModule } from '../../inputs/input-with-label/input-with-label.component.module'

import { ForgotPasswordComponent } from './forgot-password.component'

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    InputWithLabelModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ModalComponentsModule
  ],
  exports: [ForgotPasswordComponent]
})
export class ForgotPasswordModule {}
