import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  ProdottiService,
  RichiestaRegistrazione,
  TipoRichiestaLabel,
  UserRequest,
  UserRequestService
} from '@helvetia-italia/angular-libs/ng-shared'
import { forkJoin, Observable } from 'rxjs'
import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
@Component({
  selector: 'helvetia-italia-modal-dettaglio-richiesta',
  templateUrl: './modal-dettaglio-richiesta.component.html',
  styleUrls: ['./modal-dettaglio-richiesta.component.scss']
})
export class ModalDettaglioRichiestaComponent {
  request!: UserRequest
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { request_id: string; origin: string },
    public dialogRef: MatDialogRef<ModalDettaglioRichiestaComponent>,
    public userRequestService: UserRequestService,
    public prodottiService: ProdottiService,
    public spinner: UxNgSpinnerService
  ) {
    this.init()
  }

  init() {
    forkJoin([
      this.userRequestService.getDetail(this.data.request_id),
      this.prodottiService.getList(this.data.origin)
    ]).subscribe((res) => {
      const request: UserRequest = res[0]
      const prodotti = res[1]
      if (request.case.productCode) {
        request.case.productCode = prodotti.find((prodotto: any) => prodotto.code === request.case.productCode)?.name
      }
      this.request = request
    })
  }

  getTipoRichiestaLabel(tipo: string) {
    // @ts-ignore
    return TipoRichiestaLabel[tipo] || RichiestaRegistrazione[tipo]
  }
}
