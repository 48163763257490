import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ApsaQuoteDetail, Insurable, PolicyHolder, QuotationRequest, Survey } from '../../interfaces'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  private _currentRequest: QuotationRequest | undefined = {}

  constructor(
    private userService: UserService,
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private env: Environment
  ) {}

  init(request: QuotationRequest) {
    this._currentRequest = JSON.parse(JSON.stringify(request))
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  /**
   * EX UploadFileService.getQuoteDetails() (ng-selly)
   * @param quoteNumber
   * @returns
   */
  getQuoteDetails(quoteNumber: number | string): Observable<ApsaQuoteDetail & { _id: string }> {
    // get detail quote per verificare esistenza del quote
    return this.http.get<any>(`${this.env.apiServer}api/quotes/${quoteNumber}`).pipe(map((result) => result.data[0]))
  }

  /**
   * EX UploadFileService.postQuotes() (ng-selly)
   * @param quote
   * @returns
   */
  postQuotes(quote: any) {
    return this.http.post<any>(`${this.env.apiServer}api/quotes`, quote, {
      observe: 'response',
      withCredentials: true
    })
  }

  /**
   * EX UploadFileService.putQuotes() (ng-selly)
   * @param quote
   * @param quoteId
   * @returns
   */
  putQuotes(quote: any, quoteId: string) {
    delete quote._id
    return this.http.put<any>(`${this.env.apiServer}api/quotes/${quoteId}`, quote, {
      observe: 'response',
      withCredentials: true
    })
  }

  /**
   * EX UploadFileService.evaluateBuyableQuote() (ng-selly)
   */
  evaluateBuyableQuote(quoteId: string): Observable<{ isBuyable: boolean }> {
    return this.http.get<{ isBuyable: boolean }>(`${this.env.apiServer}api/quote-status/${quoteId}`, {
      withCredentials: true
    })
  }

  /**
   * EX UploadFileService.buyQuote() (ng-selly)
   */
  buyQuote(quoteId: string, body: any, paymentType?: string) {
    let url: string
    if (paymentType) {
      url = `${this.env.apiServer}api/buy-quote/${paymentType}/${quoteId}`
    } else {
      url = `${this.env.apiServer}api/buy-quote/${quoteId}`
    }

    return this.http.post<any>(url, body, {
      withCredentials: true
    })
  }

  /**
   * EX UploadFileService.checkFeaAcceptance() (ng-selly)
   */
  checkFeaAcceptance(quoteId: string, body: any) {
    const url = `${this.env.apiServer}api/check-fea-acceptance/${quoteId}`

    return this.http.post<any>(url, body, {
      withCredentials: true
    })
  }

  public get currentRequest(): QuotationRequest {
    // if (!this._currentRequest)
    if (!sessionStorage.getItem('quotation')) {
      sessionStorage.setItem('quotation', '{}')
    }
    this._currentRequest = JSON.parse(sessionStorage.getItem('quotation') || '{}') as QuotationRequest

    return this._currentRequest
  }

  // public getDefaultValue(key: string | string[], defaultValue: string, postProcess?: any) {
  //   const value = _.get(this.currentRequest, ['variables'].concat(key), defaultValue)

  //   if (value === defaultValue || _.isNil(postProcess)) return value

  //   return postProcess(value)
  // }

  public newQuotation(productCode: string, productNode: string, visitorId?: string): QuotationRequest {
    this._currentRequest = {
      referer: sessionStorage.getItem('referrer') || '',
      visitorId: visitorId,
      productCode: productCode,
      packageCode: 'DEFAULT',
      productNode: productNode,
      variables: {},
      guarantees: {},
      insured: [],
      asShop: {}
    }
    if (this.userService.getLocalUserState()?.shop) {
      this._currentRequest.asShop = this.userService.getLocalUserState()?.shop.code
    }

    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
    return this._currentRequest
  }

  public changeVehicleType(
    productCode: string,
    productNode: string,
    insured: Insurable,
    visitorId?: string
  ): QuotationRequest | undefined {
    sessionStorage.removeItem('wizardStatus')
    sessionStorage.removeItem('listBrands')
    sessionStorage.removeItem('listModels')
    sessionStorage.removeItem('listCarFittings')

    // let wizardSteps = this.wizardService.getWizardByCode(productCode)
    // let step1 = wizardSteps.find((e) => e.code === 'step1')
    // let step2 = wizardSteps.find((e) => e.code === 'step2')
    // _.set(step1, 'enabled', true)
    // _.set(step2, 'enabled', true)
    // sessionStorage.setItem('wizardStatus', JSON.stringify(wizardSteps))
    if (this._currentRequest) {
      this._currentRequest.insured = [insured]
      this._currentRequest.variables = {}

      sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
      sessionStorage.removeItem('prevVehicle')

      return this._currentRequest
    } else return undefined
  }

  public clearQuotation() {
    this._currentRequest = undefined
  }

  public setAgeDescription(ageDescription: string) {
    this._currentRequest ? (this._currentRequest.ageDescription = ageDescription) : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public setAgent(ageDescription: any) {
    this._currentRequest ? (this._currentRequest.agent = ageDescription) : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public addGuarantee(name: string, value: any) {
    this._currentRequest && this._currentRequest.guarantees ? (this._currentRequest.guarantees[name] = value) : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public removeGuarantee(name: string) {
    this._currentRequest && this._currentRequest.guarantees ? delete this._currentRequest.guarantees[name] : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public checkGuarantee(name: string) {
    return this._currentRequest && this._currentRequest.guarantees ? this._currentRequest.guarantees[name] : null
  }

  public addVariable(name: string, value: any) {
    if (this._currentRequest && !this._currentRequest.variables) this._currentRequest.variables = {}
    this._currentRequest && this._currentRequest.variables ? (this._currentRequest.variables[name] = value) : null

    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public removeVariable(name: string) {
    this._currentRequest && this._currentRequest.variables ? delete this._currentRequest.variables[name] : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public setPolicyHolder(policyHolder: PolicyHolder) {
    this._currentRequest ? (this._currentRequest.policyHolder = policyHolder) : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public setInsured(list: Array<Insurable>) {
    this._currentRequest ? (this._currentRequest.insured = list) : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public setPackageCode(value: string) {
    this._currentRequest ? (this._currentRequest.packageCode = value) : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public setSurvey(value: Survey | null) {
    // @ts-ignore
    this._currentRequest ? (this._currentRequest.survey = _.cloneDeep(value)) : null
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest))
  }

  public setPrevVehicle(vehicle: Insurable) {
    // tslint:disable-next-line:no-unused-expression
    vehicle && sessionStorage.setItem('prevVehicle', JSON.stringify({ type: vehicle.type }))
  }

  getInsured(): Insurable | undefined {
    if (
      this.currentRequest &&
      this.currentRequest.insured &&
      this.currentRequest.insured[0] &&
      this.currentRequest.insured[0].plate
    ) {
      return this.currentRequest.insured[0]
    } else return undefined
  }
}
