import { Component } from '@angular/core'

@Component({
  selector: 'helvetia-italia-fat-footer',
  templateUrl: './fat-footer.component.html',
  styleUrls: ['./fat-footer.component.scss']
})
export class FatFooterComponent {
  language = 'it'
  navigationLinks: string

  mandator: 'helvetia' | 'swisscanto' = 'helvetia'

  constructor() {
    this.navigationLinks = JSON.stringify([
      {
        title: 'Lavorare con noi',
        links: [
          {
            text: 'Posti vacanti',
            url: 'http://jobs.helvetia.com/?lang=it&filter_20=1189523',
            target: '_blank'
          },
          {
            text: 'Lavorare per l’Helvetia',
            url: 'https://www.helvetia.com/ch/web/it/ci-presentiamo/jobs-e-carriera.html'
          }
        ]
      },
      {
        title: 'Portale',
        links: [
          {
            text: 'Per clienti privati',
            url: 'https://www.helvetia.com/ch/web/it/clienti-privati/contatto/servizi/portale-clienti.html'
          },
          {
            text: 'Per i partner di distribuzione',
            url: 'https://www.helvetia.com/ch/web/it/ci-presentiamo/servizi/contatti/portale-dei-nostri-partner-di-distribuzione.html'
          }
        ]
      },
      {
        title: 'Helvetia Svizzera',
        links: [
          {
            text: 'Ci presentiamo',
            url: 'https://www.helvetia.com/ch/web/it/ci-presentiamo.html'
          },
          {
            text: 'Blog',
            url: 'https://www.helvetia.com/ch/web/it/ci-presentiamo/blog-e-news/stories/ultimo-stories.html'
          },
          {
            text: 'ClimatePartner',
            url: 'https://fpm.climatepartner.com/tracking/12826-1805-1006/en'
          }
        ]
      },
      {
        title: 'Gruppo Helvetia',
        links: [
          {
            text: 'Home',
            url: 'https://www.helvetia.com/corporate/web/de/home.html'
          },
          {
            text: 'Pubblicazioni',
            url: 'https://www.helvetia.com/corporate/web/en/home/investor-relations/overview/publications.html'
          },
          {
            text: 'Comunicati stampa',
            url: 'https://www.helvetia.com/corporate/web/en/home/media-and-topics/overview/media-releases.html'
          }
        ]
      }
    ])
  }
}
