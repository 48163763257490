import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'

import { InputWithLabelModule } from '../../inputs'
import { ChangeOldPasswordComponent } from './change-old-password.component'

@NgModule({
  declarations: [ChangeOldPasswordComponent],
  imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, InputWithLabelModule, MatButtonModule],
  exports: [ChangeOldPasswordComponent]
})
export class ChangeOldPasswordModule {}
