import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RadioGroupComponent } from './radio-group.component'
import { MatRadioModule } from '@angular/material/radio'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
@NgModule({
  declarations: [RadioGroupComponent],
  imports: [CommonModule, MatRadioModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, FlexLayoutModule],
  exports: [RadioGroupComponent]
})
export class RadioGroupModule {}
