import { Survey, SurveyQuestionType } from '../interfaces'

export const MOCK_SURVEY: Survey = {
  code: 'OKTRAVEL-SURVEY',
  title: 'Questionario per la valutazione della coerenza del contratto "OK Travel Helvetia"',
  text:
    'Gentile Cliente, \n le risposte alle domande che seguono consentiranno al distributore di valutare la coerenza del ' +
    'contratto rispetto alle Sue esigenze assicurative. \n Può anche scegliere di sottoscrivere il contratto senza rispondere ad ' +
    'alcuna domanda, con la consapevolezza che ciò non consente al distributore la valutazione della coerenza del contratto rispetto ' +
    'alle Sue esigenze assicurative.',
  questions: {
    Q1: {
      code: 'Q1',
      type: SurveyQuestionType.MULTICHOISE,
      title: 'Esigenze assicurative',
      text: 'Quali sono gli obiettivi che Si prefigge con la stipula del contratto?',
      textNONADEGUATO:
        'Gli obiettivi di copertura assicurativa non risultano coerenti perché la risposta alla domanda:',
      formula: {
        ADEGUATO:
          'Q1R1 && (OKTRAVEL-RCT ^^ !Q1R2) && (OKTRAVEL-ANNULLAMENTO ^^ !Q1R3) && (OKTRAVEL-TUTELA ^^ !Q1R4) ' +
          '&& ((OKTRAVEL-9000-INFORTUNI || OKTRAVEL-4AGENTS-INFORTUNI) ^^ !Q1R5) && !Q1R6',
        NONADEGUATO:
          '!Q1R1 || (OKTRAVEL-RCT ^^ Q1R2) || (OKTRAVEL-ANNULLAMENTO ^^ Q1R3) || (OKTRAVEL-TUTELA ^^ Q1R4) || ' +
          '((OKTRAVEL-9000-INFORTUNI || OKTRAVEL-4AGENTS-INFORTUNI) ^^ Q1R5) || Q1R6',
        NONVALUTABILE: 'Q1R7'
      },
      answers: {
        Q1R1: {
          code: 'Q1R1',
          text:
            'Voglio proteggere il mio patrimonio da eventuali necessità di assistenza immediata durante il viaggio, ' +
            'voglio un rimborso per spese necessarie per eventuali infortuni e/o malattie',
          textNONADEGUATO: '1.1 - non sono stati compresi degli aspetti fondamentali del contratto.'
        },
        Q1R2: {
          code: 'Q1R2',
          text: 'Voglio proteggere il mio patrimonio da richieste di risarcimento per i danni che posso provocare a terzi',
          textNONADEGUATO:
            '1.2 - è in contrasto con la scelta effettuata per la garanzia relativa alla responsabilità civile per danni a terzi.'
        },
        Q1R3: {
          code: 'Q1R3',
          text: 'Voglio avere una tutela in caso di annullamento del viaggio per cause imprevedibili',
          textNONADEGUATO:
            "1.3 - è in contrasto con la scelta effettuata per la garanzia relativa all'annullamento viaggio."
        },
        Q1R4: {
          code: 'Q1R4',
          text: 'Voglio proteggere il mio patrimonio da spese legali in caso di controversie con terzi',
          textNONADEGUATO: '1.4 - è in contrasto con la scelta effettuata per la garanzia relativa alla tutela legale.'
        },
        Q1R5: {
          code: 'Q1R5',
          text: 'Voglio esclusivamente proteggere la mia persona in caso di infortunio durante il viaggio',
          textNONADEGUATO: '1.5 - è in contrasto con la scelta effettuata per la garanzia relativa agli infortuni.'
        },
        Q1R6: {
          code: 'Q1R6',
          text: 'Voglio protezione per altri eventi come per esempio "inabilità temporanea da infortunio e/o da malattia"',
          textNONADEGUATO: '1.6 - la garanzia non è prevista dal presente prodotto'
        },
        Q1R7: { code: 'Q1R7', text: 'Non rispondo' }
      }
    },
    Q2: {
      code: 'Q2',
      type: SurveyQuestionType.SINGLECHOISE,
      title: 'Altre Assicurazioni',
      text: 'Ha in corso altre coperture assicurative che coprono i medesimi rischi della presente polizza?',
      formula: { ADEGUATO: 'Q2R1 || Q2R3', NONADEGUATO: 'Q2R2', NONVALUTABILE: 'Q2R4' },
      answers: {
        Q2R1: { code: 'Q2R1', text: 'Sì, ma intendo acquistare una copertura complementare' },
        Q2R2: {
          code: 'Q2R2',
          text: 'Sì, e non intendo integrare la copertura in corso',
          textNONADEGUATO: "2 - E' già esistente un'altra copertura assicurativa per i medesimi rischi"
        },
        Q2R3: { code: 'Q2R3', text: 'No' },
        Q2R4: { code: 'Q2R4', text: 'Non rispondo' }
      }
    },
    Q3: {
      code: 'Q3',
      type: SurveyQuestionType.SINGLECHOISE,
      title: 'Delimitazioni di garanzia e limitazioni d’indennizzo',
      text:
        'È consapevole che le garanzie prestate dal prodotto OK Travel Helvetia operano esclusivamente durante il periodo in ' +
        'cui è in viaggio e prevede limiti alle coperture fornite ed alle prestazioni erogabili?',
      formula: { ADEGUATO: 'Q3R1', NONADEGUATO: 'Q3R2', NONVALUTABILE: 'Q3R3' },
      answers: {
        Q3R1: { code: 'Q3R1', text: 'Sì' },
        Q3R2: {
          code: 'Q3R2',
          text: 'No',
          textNONADEGUATO: '3 - Mancata comprensione degli aspetti fondamentali del contratto'
        },
        Q3R3: { code: 'Q3R3', text: 'Non rispondo' }
      }
    }
  }
}
