import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'

import { ComposerComponent } from './composer/composer.component'
import { FormComposerDynamicComponentBuilderService } from './services/form-composer-dynamic-component-builder.service'
import { FormComposerGroupService } from './services/form-composer-group.service'
import { FormComposerHtmlCompilerService } from './services/form-composer-html-compiler.service'
import { FormComposerLoaderService } from './services/form-composer-loader.service'

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule],
  declarations: [ComposerComponent],
  exports: [ComposerComponent],
  providers: [
    FormComposerLoaderService,
    FormComposerHtmlCompilerService,
    FormComposerDynamicComponentBuilderService,
    FormComposerGroupService
  ]
})
export class NgFormComposerModule {}
