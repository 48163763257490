import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { isNil } from 'lodash'
import { UserService } from '../ui-services'

@Injectable({
  providedIn: 'root'
})
export class ZeroImprevistiAgentGuard implements CanActivate {
  constructor(private user: UserService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(): boolean {
    const user = this.user.loggedInAs()?.username
    if (isNil(user)) {
      return false
    }
    const group = this.user.getLocalUserState()?.groups?.find((group) => group === 'Helv Agenti Test Tariffa')
    if (!group) {
      return false
    }
    return true
  }
}
