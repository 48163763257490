import { Injectable } from '@angular/core'

export enum ConfigurationFile {
  Devel = 'remote-devel.env.json',
  PreProd = 'remote-preprod.env.json',
  Demo = 'remote-demo.env.json',
  Prod = 'remote-prod.env.json'
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor() {}
  configuration!: any
  /**
   *
   * @param environment
   * @returns Promise
   * Not using httpClient because configuration loading has to be indipendant from HttpInterceptors
   */
  // istanbul ignore next
  loadConfiguration(url: string) {
    const environment = this.getEnvironment(url)

    return fetch(
      `https://api.selly${this.getEnvironmentLink(
        environment
      )}.helvetia.it/api/v2/json/env/${this.getEnvironmentFilename(environment)}`,
      {
        method: 'get'
      }
    )
      .then((response) => response.json())
      .then((config) => {
        this.configuration = config
        return config
      })
  }

  getEnvironment(url: string) {
    if (url.includes('devel')) return 'devel'
    if (url.includes('pprod')) return 'preprod'
    if (url.includes('demo')) return 'demo'
    if (url.includes('selly.helvetia.it')) return 'prod'
    return 'preprod'
  }

  getEnvironmentLink(environment: string) {
    switch (environment) {
      default:
        return '-preprod'
      case 'devel':
        return '-devel'
      case 'preprod':
        return '-preprod'
      case 'demo':
        return '-demo'
      case 'prod':
        return ''
    }
  }

  getEnvironmentFilename(environment: string) {
    switch (environment) {
      default:
        return ConfigurationFile.PreProd
      case 'devel':
        return ConfigurationFile.Devel
      case 'preprod':
        return ConfigurationFile.PreProd
      case 'demo':
        return ConfigurationFile.Demo
      case 'prod':
        return ConfigurationFile.Prod
    }
  }
}
