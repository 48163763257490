import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, AfterViewInit } from '@angular/core'
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms'
import { of, Subscription, timer } from 'rxjs'
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators'

import { GroupValueAccessorBase } from '../../inputs'
import { ControlErrorsMessageService } from '../../inputs/helpers/control-errors-message.service'
import { Anagrafica, AnagraficaOptions, ValidateFiscalCodeRequestPayload } from './anagrafica'
import { AnagraficaService } from './anagrafica-utente/anagrafica.service'
import moment from 'moment'
@Component({
  selector: 'helvetia-italia-anagrafica-codice-fiscale',
  templateUrl: './anagrafica-codice-fiscale.component.html',
  styleUrls: ['./anagrafica-codice-fiscale.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AnagraficaCodiceFiscaleComponent,
      multi: true
    },

    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: AnagraficaCodiceFiscaleComponent,
      multi: true
    }
  ]
})
export class AnagraficaCodiceFiscaleComponent
  extends GroupValueAccessorBase
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() data!: Anagrafica
  @Input() options!: AnagraficaOptions
  subscriptions: Subscription[] = []

  constructor(
    private builder: FormBuilder,
    public anagraficaService: AnagraficaService,
    public controlErrorMessageService: ControlErrorsMessageService,
    public cdRef: ChangeDetectorRef
  ) {
    super(cdRef)
  }

  ngOnInit() {
    this.initForm()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  initForm() {
    console.log(this.options)
    this.form = this.builder.group({
      gender: ['', Validators.required],
      name: ['', { validators: [Validators.required] }],
      surname: ['', { validators: [Validators.required] }],
      fiscalCode: ['', { validators: [Validators.required] }],
      dateOfBirth: ['', [Validators.required, this.dateOfBirthValidator]]
    })
    if (!this.options) return

    if (this.options.type === 'complete') {
      this.form.addControl(
        'dateOfBirth',
        this.builder.control('', { validators: [Validators.required, this.dateOfBirthValidator] })
      )
      this.form.addControl('placeOfBirth', this.builder.control('', { validators: [Validators.required] }))

      this.form.addControl('district', this.builder.control(''))
      this.form.addControl('nation', this.builder.control(''))
      this.form.setAsyncValidators(this.completeUserValidator())
    } else if (this.options.type === 'light') {
      this.form.get('fiscalCode')?.setAsyncValidators(this.lightUserValidator())
    }
    if (this.data) {
      this.form.patchValue(this.data)
    }
  }

  getPlaceInfo(event: google.maps.places.PlaceResult) {
    if (event && event.address_components && event.address_components.length > 0) {
      const country = event.address_components.find((component) => component.types.includes('country'))?.short_name
      const provincia = event.address_components.find((component) =>
        component.types.includes('administrative_area_level_2')
      )?.short_name
      this.form.get('district')?.setValue(provincia)
      this.form.get('nation')?.setValue(country)
    }
  }

  lightUserValidator() {
    return (control: AbstractControl) => {
      const payload: ValidateFiscalCodeRequestPayload = {
        validationType: 'light',
        physicalPeople: {
          fiscalCode: control.value
        }
      }
      return this.anagraficaService.getFiscalCodeValidity(payload).pipe(
        map((res: any) => {
          if (!res.isValid) {
            return { fiscalCodeNotValid: true }
          } else {
            return null
          }
        })
      )
    }
  }

  completeUserValidator() {
    return (control: AbstractControl) => {
      if (
        control.get('name')?.value &&
        control.get('surname')?.value &&
        control.get('gender')?.value &&
        control.get('fiscalCode')?.value &&
        control.get('dateOfBirth')?.value &&
        control.get('placeOfBirth')
      ) {
        const payload: ValidateFiscalCodeRequestPayload = {
          validationType: 'full',
          physicalPeople: {
            name: control.get('name')?.value,
            surname: control.get('surname')?.value,
            gender: control.get('gender')?.value,
            fiscalCode: control.get('fiscalCode')?.value,
            italianResidence: true, // API returns error if this is set to false
            birth: {
              date: control.get('dateOfBirth')?.value.format('yyyy-MM-DD'),
              city: control.get('placeOfBirth')?.value,
              district: control.get('district')?.value,
              nation: control.get('nation')?.value
            }
          }
        }

        return timer(2000).pipe(
          distinctUntilChanged(),
          switchMap(() => this.anagraficaService.getFiscalCodeValidity(payload)),
          map((data: any) => {
            setTimeout(() => {
              this.onValidatorChange()
            })
            return !data.isValid ? { anagraficaNotValid: true } : null
          })
        )
      } else {
        return of(null)
      }
    }
  }

  dateOfBirthValidator(formControl: FormControl): ValidationErrors | null {
    if (!formControl.value) {
      return null
    }
    const value = formControl.value as string
    if (!moment(value).isValid()) return null
    const ageDifferee = moment().diff(value, 'years')
    return ageDifferee < 18 ? { underage: true } : null
  }
  //TODO

  validate() {
    return of(this.form.valid ? null : { anagraficaNotValid: true })
  }
}
