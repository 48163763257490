import { InjectionToken } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Environment } from '@helvetia-italia/angular-libs/environment'

export const ApplicationGuard = new InjectionToken('ApplicationGuard')

export abstract class BaseGuard implements CanActivate {
  constructor(protected router: Router, protected environment: Environment) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const agent = window.navigator.userAgent.toLowerCase()
    if (agent.indexOf('trident') > -1) {
      // find if agent is 'IE' and redirect to this page
      this.router.navigate([this.environment.nonOperationalAgent])
      return false
    }
    return true
  }
}
