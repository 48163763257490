import { Component, Input } from '@angular/core'
import { ModalSurvey } from '@helvetia-italia/angular-libs/ng-shared'

@Component({
  selector: 'helvetia-italia-invalid-question',
  templateUrl: './invalid-question.component.html',
  styleUrls: ['./invalid-question.component.scss']
})
export class InvalidQuestionComponent {
  opened = false
  @Input() invalidSurvey: ModalSurvey = {}

  constructor() {}
}
