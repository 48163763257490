import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

export interface NgSharedModuleConfiguration {
  features: string[]
  publicKey: string
  agentLoginUrl: string
  affinityLoginUrl: string
  customerLoginUrl: string
  remoteSellingLoginUrl: string
  liquidatoreLoginUrl: string
  shopLoginUrl: string
}

@NgModule({
  imports: [CommonModule],
  declarations: []
})
export class AngularLibsNgSharedModule {}
