import { Component, OnInit } from '@angular/core'
import { ControlErrorsMessageService } from '@helvetia-italia/angular-libs/ng-ui-components'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HeaderFeaService, SASService } from '@helvetia-italia/angular-libs/ng-shared'
import { CondizioneAggiuntiva, Garanzia, PremioResponse, ZeroImprevisti } from '@helvetia-italia/shared/business'
const s = (window as any).s

@Component({
  selector: 'helvetia-italia-zero-imprevisti-sm',
  templateUrl: './zero-imprevisti-sm.component.html',
  styleUrls: ['./zero-imprevisti-sm.component.scss']
})
export class ZeroImprevistiSMComponent implements OnInit {
  testNum = 134567.3423
  form: FormGroup
  zeroImprevistiFormObj: any
  listGaranzie: any
  premiumResponse: PremioResponse = { premiofinale: 0 }
  showSpinner = false
  totalSM: any
  franchigiaIP = new FormControl([])
  franchigiaCondAggIPM = new FormControl([])
  franchigiaDiaria = new FormControl([])
  franchigiaDiariaSelected: any
  franchigiaIPSelected: any
  franchigiaCondAggIPMSelected: any
  speseCuraInfortunioSelected: any
  morteSM = new FormControl([])
  invaliditaPermanenteDaInfortunioSM = new FormControl([])
  raddoppioDiriaSupplConvSM = new FormControl([])
  inabilitaTemporaneaSM = new FormControl([])
  speseCuraInfortunioSM = new FormControl([])
  speseCuraIndSM = new FormControl([])
  invaliditaPermanenteSM = new FormControl([])
  diariaImmAppGessSM = new FormControl([])
  diariaConvPostRicInfSM = new FormControl([])
  diariaMalattiaSM = new FormControl([])
  listFranchigiaIP: { value: string; label: string }[]
  listFranchigiaCondAggIPM: { value: string; label: string }[]
  listFranchigiaDiaria: { value: string; label: string }[]
  listSpeseCuraInfortunio: { value: string; label: string }[]
  garanzieNotOver500 = ['morte', 'invaliditaPermanenteDaInfortunio', 'invaliditaPermanente']
  constructor(
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public controlErrorsMessageService: ControlErrorsMessageService,
    public sasService: SASService,
    private headerFeaService: HeaderFeaService
  ) {
    this.form = this.builder.group({
      morteSM: [],
      invaliditaPermanenteDaInfortunioSM: [],
      inabilitaTemporaneaSM: [],
      diariaRicoveraInfortunioSM: [],
      speseCuraInfortunioSM: [],
      raddoppioDiriaSupplConvSM: [],
      apparecchiGessatiSM: [],
      invaliditaPermanenteSM: [],
      diariaImmAppGessSM: [],
      diariaConvPostRicInfSM: [],
      diariaMalattiaSM: []
    })
    this.listFranchigiaDiaria = [
      { value: '', label: '' },
      { value: 'Franchigia 3 giorni', label: 'Franchigia 3 giorni' },
      { value: 'Nessuna franchigia', label: 'Nessuna franchigia' }
    ]
    this.listSpeseCuraInfortunio = [
      { value: '5000', label: '5000' },
      { value: '10000', label: '10000' },
      { value: '20000', label: '20000' },
      { value: '25000', label: '25000' }
    ]
    this.listFranchigiaIP = [
      { value: '', label: '' },
      { label: 'Modulata 3%', value: 'Franchigia modulata 3%' },
      { label: 'Modulata 5%', value: 'Franchigia modulata 5%' },
      { label: 'Supervalutazione 225%', value: 'Franchigia con supervalutazione 225%' },
      { label: 'Assoluta 5%', value: 'Franchigia Assoluta 5%' },
      { label: 'Assoluta 10%', value: 'Franchigia Assoluta 10%' },
      { label: 'Assoluta 50%', value: 'Franchigia Assoluta 50%' },
      { label: 'Scaglioni di somme', value: 'Franchigia a scaglioni di somme' }
    ]
    this.listFranchigiaCondAggIPM = [{ value: '65%', label: '65%' }]
  }

  ngOnInit() {
    this.headerFeaService.onHide$.emit(true)
    this.route.queryParams.subscribe((params) => {
      this.zeroImprevistiFormObj = JSON.parse(params['zeroImprevistiFormObj'])
      console.log(this.zeroImprevistiFormObj)
      this.listGaranzie = this.zeroImprevistiFormObj.garanzie
      this.listGaranzie.forEach((element: { sommaAssicurata: number; value: string; checked: any }) => {
        if (this.form && element.sommaAssicurata) {
          this.form?.get(`${element.value}SM`)?.setValue(element.sommaAssicurata)
          if (this.garanzieNotOver500.find((val) => val === element.value) && element.sommaAssicurata > 500000) {
            this.form.get(`${element.value}SM`)?.setValue(undefined)
          }
        }
        if (element.checked && this.granzieHasSommaAssicurata(element)) {
          const validators = []
          validators.push(Validators.required)
          this.form.get(`${element.value}SM`)?.setValidators(validators)
        }
      })
      this.totalSM = this.zeroImprevistiFormObj.totalSM ? this.zeroImprevistiFormObj.totalSM : 0
      this.franchigiaDiariaSelected = this.zeroImprevistiFormObj.franchigiaDiariaSelected
        ? this.zeroImprevistiFormObj.franchigiaDiariaSelected
        : null
      this.franchigiaIPSelected = this.zeroImprevistiFormObj.franchigiaIPSelected
        ? this.zeroImprevistiFormObj.franchigiaIPSelected
        : null
      this.franchigiaCondAggIPMSelected = this.zeroImprevistiFormObj.franchigiaCondAggIPMSelected
        ? this.zeroImprevistiFormObj.franchigiaCondAggIPMSelected
        : null
      this.speseCuraInfortunioSelected = this.zeroImprevistiFormObj.speseCuraInfortunioSelected
        ? this.zeroImprevistiFormObj.speseCuraInfortunioSelected
        : null
    })
  }

  sumSommaAssicurata(garanzia: any) {
    let sumSM = 0
    const sommaAssicurata = this.form.controls[`${garanzia.value}SM`].value
    if (this.garanzieNotOver500.find((val) => val === garanzia.value) && sommaAssicurata > 500000) {
      this.form
        .get(`${garanzia.value}SM`)
        ?.setErrors({ '"La somma assicurata per questa garanzia deve essere minore di 500.000"': true })
    } else {
      this.form.get(`${garanzia.value}SM`)?.setErrors(null)
    }
    const garanziaMatch = this.listGaranzie.find((element: { value: any }) => element.value === garanzia.value)
    garanziaMatch.sommaAssicurata = sommaAssicurata
    this.listGaranzie.forEach((element: { sommaAssicurata: any }) => {
      if (element.sommaAssicurata) {
        sumSM += Number(element.sommaAssicurata)
      }
      console.log(sumSM)
    })

    this.totalSM = sumSM
  }

  checkIsOneGaranziaChecked() {
    if (this.listGaranzie.find((element: { checked: boolean }) => element.checked === true)) return true
    return false
  }

  isSpeseCuraInfortunio(element: { value: string; checked: any }) {
    if (element.value == 'speseCuraInfortunio' && element.checked) return true
  }

  selecFranchigiaIP(event: any) {
    this.franchigiaIPSelected = event.value
  }

  selecFranchigiaCondAggIPM(event: any) {
    this.franchigiaCondAggIPMSelected = event.value
  }

  displayFranchigiaDiaria(garanzia: { checked: any; value: string }) {
    return garanzia.checked && garanzia.value === 'diariaMalattia'
  }

  displayFranchigiaIP(garanzia: { checked: any; value: string }) {
    return garanzia.checked && garanzia.value === 'invaliditaPermanenteDaInfortunio'
  }

  displayFranchigiaCondAggIPM(garanzia: { checked: any; value: string }) {
    return garanzia.checked && garanzia.value === 'condAggIPM'
  }

  checkGaranzia(element: { value: any }) {
    const garanzia = this.listGaranzie.find((garanzia: { value: any }) => garanzia.value === element.value)
    return garanzia.checked && this.granzieHasSommaAssicurata(garanzia)
  }

  selectFranchigiaDiaria(event: any) {
    this.franchigiaDiariaSelected = event.value
  }

  selectSpeseCuraInfortunio(event: any) {
    const garanzia = this.listGaranzie.find((garanzia: { value: string }) => garanzia.value === 'speseCuraInfortunio')
    garanzia.sommaAssicurata = event.value
    this.form.get('speseCuraInfortunioSM')?.setValue(event.value)
    this.speseCuraInfortunioSelected = event.value
  }

  smChanged(garanzia: any) {
    if (this.garanzieNotOver500.includes(garanzia.value) && garanzia.sommaAssicurata > 500000) {
      this.form
        .get(`${garanzia.value}SM`)
        ?.setErrors({ '"La somma assicurata per questa garanzia deve essere minore di 500.000"': true })
    }
  }

  previous() {
    this.zeroImprevistiFormObj.totalSM = this.totalSM
    this.zeroImprevistiFormObj.garanzie = this.listGaranzie
    this.zeroImprevistiFormObj.franchigiaDiariaSelected = this.franchigiaDiariaSelected
    this.zeroImprevistiFormObj.speseCuraInfortunioSelected = this.speseCuraInfortunioSelected
    this.zeroImprevistiFormObj.franchigiaIPSelected = this.franchigiaIPSelected
    this.zeroImprevistiFormObj.franchigiaCondAggIPMSelected = this.franchigiaCondAggIPMSelected
    this.router.navigate(['zero-imprevisti'], {
      queryParams: { zeroImprevistiFormObj: JSON.stringify(this.zeroImprevistiFormObj) },
      skipLocationChange: true
    })
  }

  granzieHasSommaAssicurata(garanzia: { sommaAssicurata?: number; value: any; checked?: any }) {
    return ![
      'raddoppioDiriaRicGrandIntChirurgico',
      'assistenza',
      'condAgg9',
      'condAggIPM',
      'parificazioneGragoIP',
      'sportBR',
      'sportAR',
      'calamitaNaturali',
      'capAggIPGrave'
    ].includes(garanzia.value)
  }

  calcPreventivo() {
    this.premiumResponse = { premiofinale: 0 }
    const garanzie: Garanzia[] = []
    const condizioniAggiuntive: CondizioneAggiuntiva[] = []
    this.listGaranzie.forEach((gar: { checked: any; name: any; sommaAssicurata: number; value: string }) => {
      if (gar.checked) {
        const garanzia = {
          nome: gar.name
        } as Garanzia
        if (gar.sommaAssicurata) {
          garanzia['sommaAssicurata'] = gar.sommaAssicurata
          if (gar.value === 'diariaMalattia' && this.franchigiaDiariaSelected) {
            garanzia['franchigia'] = this.franchigiaDiariaSelected
          }
          if (gar.value === 'invaliditaPermanenteDaInfortunio' && this.franchigiaIPSelected) {
            garanzia['franchigia'] = this.franchigiaIPSelected
          }
          garanzie.push(garanzia)
        } else {
          const condAggiuntiva = {
            nome: gar.name
          } as CondizioneAggiuntiva
          if (gar.value === 'condAggIPM' && this.franchigiaCondAggIPMSelected) {
            condAggiuntiva['franchigia'] = this.franchigiaCondAggIPMSelected
          }
          condizioniAggiuntive.push(condAggiuntiva)
        }
      }
    })
    const zeroImprevisti = {
      dataEffetto: this.zeroImprevistiFormObj.dataEffetto as string,
      dataScadenza: this.zeroImprevistiFormObj.dataScadenza as string,
      durata: this.zeroImprevistiFormObj.durataSelected as string,
      frazionamento: this.zeroImprevistiFormObj.frazionamentoSelected as string,
      anni: this.zeroImprevistiFormObj.anni as string,
      professione: this.zeroImprevistiFormObj.attivitaProfessionaleSelected as string,
      provincia: this.zeroImprevistiFormObj.provinciaSelected as string,
      classeRischio: this.zeroImprevistiFormObj.classeRischio as string,
      combinazioneRischioAssicurato: this.zeroImprevistiFormObj.combinazioneAssicuratoSelected as string,
      categoriaLavorativa: this.zeroImprevistiFormObj.categoariaLavorativaSelected as string,
      garanzie: garanzie,
      condizioniAggiuntive: condizioniAggiuntive
    } as ZeroImprevisti
    console.log(zeroImprevisti)
    this.showSpinner = true
    this.sasService.calculatePreventivoSAS(zeroImprevisti).subscribe(
      (res) => {
        console.log('Premi results:')
        console.log(res.body)
        this.premiumResponse = res.body
        this.showSpinner = false
      },
      (err) => {
        console.error(err)
        this.showSpinner = false
      }
    )
  }
}
