import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { isNil } from 'lodash'
import { UserService } from '../ui-services'

@Injectable({
  providedIn: 'root'
})
export class FeaDirectionGuard implements CanActivate {
  constructor(private user: UserService) {}

  canActivate(): boolean {
    const user = this.user.loggedInAs()?.username
    if (isNil(user)) {
      return false
    }
    const group = this.user.getLocalUserState()?.groups?.find((group) => group === 'Helv Direzione FEA')
    if (!group) {
      return false
    }
    return true
  }
}
