import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CredenzialiUtenteComponent } from './credenziali-utente.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatFormFieldModule } from '@angular/material/form-field'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InputWithLabelModule } from '../../inputs/input-with-label/input-with-label.component.module'

@NgModule({
  declarations: [CredenzialiUtenteComponent],
  imports: [CommonModule, FlexLayoutModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, InputWithLabelModule],
  exports: [CredenzialiUtenteComponent]
})
export class CredenzialiUtenteModule {}
