import { Injectable, InjectionToken } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { interpolate } from '@helvetia-italia/angular-libs/ng-utils'

export const EnvironmentServiceToken = new InjectionToken<string>('EnvironmentService')
export const EnvironmentUriDownload = new InjectionToken<string>('EnvironmentUriDownload')

export let internalPromise: Promise<any>
export function EnvironmentServiceFactory(
  environmentService: EnvironmentService,
  httpClient: HttpClient,
  uri: string
): () => Promise<any> {
  console.log('EnvironmentServiceFactory', uri)
  return () => {
    internalPromise = new Promise((resolve) => {
      httpClient
        .get(uri)
        .toPromise()
        .then((envSettings: any) => {
          Object.keys(envSettings).forEach((key: string) => {
            const value = envSettings[key]
            environmentService[key] = interpolate(value, {
              ...environmentService,
              ...envSettings
            })
          })
          console.log('environmentService', internalPromise)
          resolve(environmentService)
        })
    })
    console.log('internalPromise', internalPromise)
    return internalPromise
  }
}

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  [field: string]: any
}
