export enum PurchaseQuoteUploadableFiles {
  CERTCIRC = 'Certificato libretto circolazione',
  PASSPROP = 'Documento passaggio proprietà provvisorio',
  POLPREC = 'Documento polizza precedente',
  CERTANTF = 'Certificato antifurto installato',
  POLSCAD = 'Documento polizza scaduta',
  CERTLEAS = 'Contratto di leasing',
  OTHER = 'Altro documento (opzionale)'
}

export interface PurchaseData {
  quoteNumber: string
  secondPlate?: string
  customerName?: string
  customerLastname?: string
  attachmentList?: Attachment[]
}

export interface Attachment {
  filename: string
  content: any
  name: string
  code: string
  type: string
}
