import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'

import { DateRangeInputComponent } from './date-range-input.component'

const material_modules = [MatFormFieldModule, MatDatepickerModule, MatInputModule, MatMomentDateModule]

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...material_modules],
  declarations: [DateRangeInputComponent],
  exports: [DateRangeInputComponent],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it' }]
})
export class DateRangeInputModule {}
