import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import isNil from 'lodash/isNil'
import { take } from 'rxjs/operators'
import { EnvironmentService, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Product } from '../../interfaces/product-teaser.models'
import { MOCK_PRODUCT_LIST } from '../../mocks/product-list'

import _ from 'lodash'
import { InsuranceProduct } from '../../interfaces/thankyou'

const SESSION_STORAGE_KEY = 'ProductListService.list'

@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  _list: Array<Product> = []
  apiUrl = this.env.apiServer

  constructor(private http: HttpClient, @Inject(EnvironmentServiceToken) private env: EnvironmentService) {}

  /* istanbul ignore next */
  getList(): Promise<Array<Product>> {
    console.log('[ProductListService][getList]')
    const data = sessionStorage.getItem(SESSION_STORAGE_KEY)
    if (!isNil(data)) {
      this._list = JSON.parse(data)
      return Promise.resolve(this._list)
    }
    console.log(this.env.MOCK)
    return new Promise<Array<Product>>((resolve, reject) => {
      this.http
        .get<Array<Product>>(`${this.apiUrl}api/prodotti`, {
          withCredentials: true
        })
        .pipe(take(1))
        .subscribe(
          (productList: Array<Product>) => {
            // productList = _.sortBy(productList, [(product: Product) => product.weight]);
            console.log(productList)
            sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(productList))

            this._list = productList
            resolve(this._list)
          },
          (err) => {
            reject(err)
          }
        )
    })
  }

  async getByCode(code: string): Promise<any> {
    console.log('chiamo la getByCode', this._list)
    if (this._list.length === 0) {
      return this.getList().then(() => this.getByCode(code))
    } else {
      const foundProduct = _.find(this._list, (product: InsuranceProduct) => product.code === code)
      return Promise.resolve(foundProduct)
    }
  }
}

@Injectable()
export class ProductListMockService extends ProductListService {
  constructor(http: HttpClient, @Inject(EnvironmentServiceToken) env: EnvironmentService) {
    super(http, env)
    this._list = MOCK_PRODUCT_LIST
  }
  public getList(): Promise<Array<Product>> {
    return Promise.resolve(this._list)
  }
}
