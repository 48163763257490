import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators'
import { User } from '../../interfaces/user.models'
import { USER_MOCK_AFFINITY } from '../../mocks/user'

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize())

    function handleRoute() {
      switch (true) {
        case url.endsWith('/users/authenticate') && method === 'POST':
          return authenticate()
        default:
          // pass through any requests not handled above
          /* istanbul ignore next */
          return next.handle(request)
      }
    }

    // route functions
    function authenticate() {
      console.log('faccio autenticazione fake')
      const userMock = USER_MOCK_AFFINITY
      const { username, password } = body
      let user: User
      console.log(body)
      // const user = userMock.find((x) => x.username === username && x.password === password);
      /* istanbul ignore else */
      if (userMock.username === username && userMock.password === password) user = userMock
      // else return error('Username or password is incorrect')
      else return throwError('Username or password is incorrect')

      return ok({
        user
      })
    }

    // @ts-ignore
    function ok(body) {
      return of(new HttpResponse({ status: 200, body }))
    }

    // function error(message: string) {
    //   return throwError({ error: { message } })
    // }

    // function unauthorized() {
    //   return throwError({ status: 401, error: { message: 'Unauthorised' } })
    // }
    //
    // function isLoggedIn() {
    //   return headers.get('Authorization') === `Basic ${window.btoa('test:test')}`
    // }
  }
}
