import { NgModule, Injector, APP_INITIALIZER } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { createCustomElement } from '@angular/elements'

import { AppComponent } from './app.component'

import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { NgFormComposerModule } from '@helvetia-italia/angular-libs/ng-form-composer'
import { RegistrationFormMockService, RegistrationFormService } from '@helvetia-italia/angular-libs/ng-shared'
import { environment } from '../environments/environment'
import {
  ENVIRONMENT,
  EnvironmentService,
  EnvironmentServiceFactory,
  EnvironmentServiceToken,
  EnvironmentUriDownload
} from '@helvetia-italia/angular-libs/environment'
import { UxNgSpinnerModule } from '@helvetiait/ux-ng-material-library'
import { MatButtonModule } from '@angular/material/button'
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ConsentsInterceptor } from './consents.interceptor'
import { MatFormFieldModule } from '@angular/material/form-field'
import { CheckboxModule, RadioGroupModule } from '@helvetia-italia/angular-libs/ng-ui-components'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgFormComposerModule,
    UxNgSpinnerModule,
    MatButtonModule,
    HttpClientModule,
    MatFormFieldModule,
    CheckboxModule,
    RadioGroupModule
  ],
  providers: [
    {
      provide: RegistrationFormService,
      useClass: environment.MOCK ? RegistrationFormMockService : RegistrationFormService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: EnvironmentServiceFactory,
      deps: [EnvironmentServiceToken, HttpClient, EnvironmentUriDownload],
      multi: true
    },
    {
      provide: EnvironmentUriDownload,
      useValue: environment.cdn
    },
    {
      provide: EnvironmentServiceToken,
      useClass: EnvironmentService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConsentsInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const el: any = createCustomElement(AppComponent, { injector: this.injector })
    customElements.define('helvetia-italia-web-consents', el)
  }
}
