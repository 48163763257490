import { CB_DOCUMENT_STATUS, CB_DOCUMENT_TYPE } from './document'

export interface QuoteDocument {
  code?: string
  documentId?: string
  error?: string
  fileCode?: string
  fileDescription?: string
  fileName?: string
  mediaType?: string
  quoteNumber?: string
  status?: CB_DOCUMENT_STATUS
  statusHistory?: any
  storage?: { data?: string; provider?: string }
  subType?: string
  type?: CB_DOCUMENT_TYPE
  _id?: string
}

export enum QuoteStatus {
  APPROVED = 'approved',
  EXPIRED = 'expired',
  FEA_NOT_SIGNED = 'fea_not_signed',
  INCOMPLETE = 'incomplete',
  NEW = 'new',
  PAID = 'paid',
  REJECTED = 'rejected',
  TO_VERIFY = 'toVerify'
}
