import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class ConsentsInterceptor implements HttpInterceptor {
  constructor() {}
  // istanbul ignore next
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        // @ts-ignore
        'x-call-id': '0000'
      }
    })
    return next.handle(request)
  }
}
