import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'helvetia-italia-premio',
  templateUrl: './premio.component.html',
  styleUrls: ['./premio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PremioComponent {
  // @Input() premioComponent = { label: '', description: '', premio: '', info: false }
  @Input() premioLabel: string | undefined
  @Input() premioPrice: number | undefined
  @Input() premioInfo: boolean | undefined
  @Input() premioDescription: string | undefined
  @Output() openDrawer = new EventEmitter()
  constructor() {}

  open() {
    this.openDrawer.emit('enter')
  }
}
