import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthenticationService, User } from '@helvetia-italia/angular-libs/ng-shared'
import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
import { BehaviorSubject } from 'rxjs'
import { first } from 'rxjs/operators'

@Component({
  selector: 'helvetia-italia-popover-login',
  templateUrl: './popover-login.component.html',
  styleUrls: ['./popover-login.component.scss']
})
export class PopoverLoginComponent implements OnInit {
  @Input() position = 'top-left'
  @Output() closeFunction = new EventEmitter()
  form: FormGroup
  error: string | undefined

  currentUserSubject: BehaviorSubject<User>
  currentUser: User

  constructor(
    private builder: FormBuilder,
    @Inject(AuthenticationService) private authenticationService: AuthenticationService,
    private router: Router,
    private spinnerService: UxNgSpinnerService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(<string>sessionStorage.getItem('userState')))
    this.currentUser = this.authenticationService.currentUserValue
    this.form = this.builder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  ngOnInit(): void {}

  // istanbul ignore next
  login() {
    this.spinnerService.start()
    this.authenticationService
      .login(this.form.get('username')?.value, this.form.get('password')?.value)
      .pipe(first())
      .subscribe(
        (data) => {
          //this.authenticationService.redirectUser()

          this.spinnerService.stop()
          this.hidePopover(this.authenticationService.currentUserValue)
          this.currentUser = this.authenticationService.currentUserValue
        },
        (error) => {
          this.error = error.error.message
          this.spinnerService.stop()
        }
      )
  }
  // istanbul ignore next
  logout() {
    this.authenticationService.logout()
    this.currentUser = this.authenticationService.currentUserValue
  }
  // istanbul ignore next
  hidePopover(user: User) {
    this.closeFunction.emit(user)
  }
  // istanbul ignore next
  register() {}
  // istanbul ignore next
  forgotPassword() {
    this.router.navigate(['/forgot-password'])
  }
}
