import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'

import { CheckboxComponent } from './checkbox.component'

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatFormFieldModule],
  exports: [CheckboxComponent]
})
export class CheckboxModule {}
