import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'helvetia-italia-expandable-footer',
  templateUrl: './expandable-footer-components.component.html',
  styleUrls: ['./expandable-footer-components.component.scss']
})
export class ExpandableFooterComponentsComponent implements OnInit {
  @Input() absolute: 'top' | 'bottom' | undefined
  @Input() fixed: 'top' | 'bottom' | undefined
  @Input() color: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method,@typescript-eslint/no-empty-function
  ngOnInit(): void {}
}
