import { Injectable } from '@angular/core'

import { Observable, of, throwError } from 'rxjs'
import { LoggedInAs, User, UserState } from '../../interfaces'
import { UserService } from './user.service'

/**
 * Mocked User service
 */
@Injectable({
  providedIn: 'root'
})
export class UserMockService extends UserService {
  loggedInAs(): LoggedInAs | undefined {
    const loggedInAs: LoggedInAs = {
      fiscalCode: 'FRNDNL75D09I480E',
      name: 'Daniele',
      surname: 'Fornaciari',
      district: 'TO',
      clientId: '050252167',
      dateOfBirth: '1999-08-27T00:00:00.000',
      address: 'Via dei Guinceri 118',
      postalCode: '20032',
      city: 'Cormano',
      email: 'davide.graceffa@trilog.it'
    }
    return loggedInAs
  }
  /**
   *
   * @param user
   */
  setLoginToken(token: string): void {
    /* istanbul ignore next */
    localStorage.setItem('loginToken', token)
  }

  /**
   *
   */
  getLoginToken(): string | undefined {
    /* istanbul ignore next */
    const token = localStorage.getItem('loginToken')
    /* istanbul ignore next */
    if (token) {
      return token
    } else return undefined
  }

  createUserState(loginState: any): string {
    return ''
  }

  getLocalUserState(): UserState | undefined {
    const userState: UserState = {
      loginToken: 'mocked_token',
      productNode: '09000',
      logged: true
    }
    /* istanbul ignore next */
    return userState
  }

  /**
   *
   */
  deleteLoginToken() {
    /* istanbul ignore next */
    localStorage.removeItem('loginToken')
  }
  /**
   *
   * @param user
   */
  /* istanbul ignore next */
  setPartialUser(user: User): void {
    localStorage.setItem('partialUser', JSON.stringify(user))
  }

  /**
   *
   */
  /* istanbul ignore next */
  getPartialUser(): User | undefined {
    const partialUser = localStorage.getItem('partialUser')
    if (partialUser) {
      return JSON.parse(partialUser) || null
    } else return undefined
  }

  /**
   *
   */
  /* istanbul ignore next */
  deletePartialUser() {
    localStorage.removeItem('partialUser')
  }

  /**
   *
   * @param token
   */
  /* istanbul ignore next */
  setRegistrationSessionToken(token: string): void {
    localStorage.setItem('RegistrationToken', token)
  }

  /**
   *
   * @returns
   */
  /* istanbul ignore next */
  getRegistrationSessionToken(): string | null | undefined {
    const token = localStorage.getItem('RegistrationToken')
    if (token) {
      return token || null
    } else return undefined
  }

  /**
   *
   */
  /* istanbul ignore next */
  deleteRegistrationSessionToken() {
    localStorage.removeItem('RegistrationToken')
  }

  /**
   *
   * @param user
   * @param token
   * @returns
   */
  /* istanbul ignore next */
  sendEmailVerificationToken(user: User, token: string): Observable<any> {
    return of()
  }

  /**
   *
   * @param otp
   * @param token
   * @returns
   */
  /* istanbul ignore next */
  checkEmailVerificationToken(otp: string, token: string): Observable<{ userData: any; valid: boolean }> {
    return of()
  }

  /**
   *
   * @param telephone
   * @returns
   */
  /* istanbul ignore next */
  sendOTPVerificationToken(telephone: string): Observable<{ message: string; token: string }> {
    // return of({ message: 'Otp sent', token: 'mocked_token' })
    return throwError('error')
  }

  /**
   *
   * @param otp
   * @param token
   * @returns
   */
  /* istanbul ignore next */
  checkOTPVerificationToken(otp: string, token: string): Observable<{ valid: true }> {
    return of({ valid: true })
  }

  /**
   *
   * @param user
   * @returns
   */
  /* istanbul ignore next */
  register(user: User): Observable<any> {
    return of()
  }

  /**
   *
   * @param username
   * @param password
   * @returns
   */
  /* istanbul ignore next */
  login(username: string, password: string) {
    return of()
  }

  /**
   *
   * @param user
   * @returns
   */
  /* istanbul ignore next */
  update(user: User): Observable<any> {
    return of()
  }

  /**
   *
   * @param email
   * @returns
   */
  /* istanbul ignore next */
  forgotPassword(email: string, origin: string): Observable<any> {
    return of()
  }

  /**
   *
   * @param email
   * @param token
   * @param password
   * @returns
   */
  /* istanbul ignore next */
  updatePassword(email: string, token: string, password: string, origin: string): Observable<any> {
    return of()
  }

  /**
   *
   * @param formData
   * @param mimeType
   * @returns
   */
  /* istanbul ignore next */
  uploadDocumentToMediaStorage(formData: any, mimeType: string): Observable<any> {
    return of()
  }

  /**
   *
   * @param documentPayload
   * @returns
   */
  /* istanbul ignore next */
  uploadDocumentMediaId(documentPayload: {
    userId: string
    owner: string
    mediaId: string
    documentType: string
  }): Observable<any> {
    return of()
  }
}
