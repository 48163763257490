import { Component, Inject, OnInit } from '@angular/core'
import { ControlErrorsMessageService } from '@helvetia-italia/angular-libs/ng-ui-components'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import moment from 'moment'
import { ActivatedRoute, Router } from '@angular/router'
import { HeaderFeaService } from '@helvetia-italia/angular-libs/ng-shared'
const s = (window as any).s

@Component({
  selector: 'helvetia-italia-prev',
  templateUrl: './zero-imprevisti.component.html',
  styleUrls: ['./zero-imprevisti.component.scss']
})
export class ZeroImprevistiComponent implements OnInit {
  form: FormGroup
  listCategoriaLavorative: { value: string; label: string }[]
  listProfessioni: { value: string; label: string; classeRischio: string }[] = []
  listProvincia: { value: string; label: string }[] = []
  listDurata: { value: string; label: string }[] = []
  listFrazionamento: { value: string; label: string }[] = []
  listClasseRischio: { value: string; label: string }[]
  listCombinazioneRischioAssicurato: { value: string; label: string }[]
  listGaranzie: {
    value: string
    label: string
    name: string
    checked?: boolean
    sommaAssicurata?: number
  }[]
  categoriaLavorativa = new FormControl([])
  professione = new FormControl([])
  provincia = new FormControl([])
  durata = new FormControl([])
  frazionamento = new FormControl([])
  classeRischio = new FormControl([])
  combinazioneRischioAssicurato = new FormControl([])
  radioLayout = 'row'
  radioClasseRischioLabel = 'Classe Rischio'
  displayedColumns = ['label', 'checked']
  totalSM = 0
  franchigiaIP = ''
  franchigiaCondAggIPM = ''
  franchigiaDiaria = ''
  speseCuraInfortunio = ''
  durataSelected = 'Annuale'
  combinazioneAssicuratoSelected = ''
  frazionamentoSelected = 'Annuale'
  attivitaProfessionaleSelected = ''
  provinciaSelected = ''
  categoariaLavorativaSelected = ''

  constructor(
    private builder: FormBuilder,
    public controlErrorsMessageService: ControlErrorsMessageService,
    @Inject(EnvironmentServiceToken) private env: Environment,
    private router: Router,
    private route: ActivatedRoute,
    private headerFeaService: HeaderFeaService
  ) {
    this.form = this.builder.group({
      dataEffetto: ['', Validators.required],
      dataScadenza: ['', Validators.required],
      anni: ['', Validators.required],
      garanzia: [''],
      durata: [''],
      frazionamento: [''],
      professione: [''],
      provincia: [''],
      classeRischio: ['', Validators.required],
      morte: [],
      invaliditaPermanenteDaInfortunio: [],
      inabilitaTemporanea: [],
      speseCuraInfortunio: [],
      diariaRicoveraInfortunio: [],
      speseCuraInd: [],
      invaliditaPermanente: [],
      diariaImmAppGess: [],
      diariaConvPostRicInf: [],
      apparecchiGessati: [],
      parificazioneGragoIP: [],
      condAgg9: [],
      condAggIPM: [],
      calamitaNaturali: [],
      capAggIPGrave: [],
      diariaMalattia: [],
      raddoppioDiriaRicGrandIntChirurgico: [],
      assistenza: [],
      raddoppioDiriaSupplConv: [],
      sportBR: [],
      sportAR: []
    })
    this.env.attivitaProfessionaleMap.forEach((prof: { DESCRIZIONE: any; CLASSE_RISCHIO: any }) => {
      const professione = { value: prof.DESCRIZIONE, label: prof.DESCRIZIONE, classeRischio: prof.CLASSE_RISCHIO }
      this.listProfessioni.push(professione)
    })
    this.env.provinciaMap.forEach((prov: { CODICE: any }) => {
      const provincia = { value: prov.CODICE, label: prov.CODICE }
      this.listProvincia.push(provincia)
    })
    this.listCategoriaLavorative = [
      { value: 'Lavoratore dipendente', label: 'Lavoratore dipendente' },
      { value: 'Lavoratore autonomo Libero professionista', label: 'Lavoratore autonomo Libero professionista' },
      { value: 'Altro', label: 'Altro' }
    ]
    this.listDurata = [{ value: 'Annuale', label: 'Annuale' }]
    this.listFrazionamento = [{ value: 'Annuale', label: 'Annuale' }]
    this.listCombinazioneRischioAssicurato = [
      {
        value: 'Infortuni professionali ed extraprofessionali',
        label: 'Infortuni professionali ed extraprofessionali'
      },
      { value: 'Infortuni solo professionali', label: 'Infortuni solo professionali' },
      { value: 'Infortuni solo extraprofessionali', label: 'Infortuni solo extraprofessionali' }
    ]
    this.listClasseRischio = [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' }
    ]
    this.listGaranzie = [
      {
        value: 'invaliditaPermanenteDaInfortunio',
        label: 'Invalidità permanente da Infortunio',
        name: 'Invalidità permanente da Infortunio',
        checked: false
      },
      { value: 'morte', label: 'Morte', checked: false, name: 'Morte' },
      {
        value: 'inabilitaTemporanea',
        label: 'Inabilità Temporanea',
        name: 'Inabilità Temporanea',
        checked: false
      },
      {
        value: 'speseCuraInfortunio',
        label: 'Spese di cura da infortunio',
        name: 'Spese di cura da infortunio',
        checked: false
      },
      {
        value: 'diariaRicoveraInfortunio',
        label: 'Diaria da ricovero da infortunio',
        name: 'Diaria da ricovero da infortunio',
        checked: false
      },
      {
        value: 'diariaImmAppGess',
        label: 'Diaria per immobilizzazione con apparecchio gessato',
        name: 'Diaria per immobilizzazione con apparecchio gessato',
        checked: false
      },
      {
        value: 'diariaConvPostRicInf',
        label: 'Diaria da convalescenza post ricovero da infortunio',
        name: 'Diaria da convalescenza post ricovero da infortunio',
        checked: false
      },
      {
        value: 'invaliditaPermanente',
        label: 'Invalidità permanente da malattia',
        name: 'Invalidità Permanente Malattia',
        checked: false
      },
      {
        value: 'diariaMalattia',
        label: 'Diaria da Malattia',
        name: 'Diaria da Malattia',
        checked: false
      },
      {
        value: 'raddoppioDiriaSupplConv',
        label: 'Diaria supplementare post ricovero da malattia',
        name: 'Diaria supplementare post ricovero da malattia',
        checked: false
      },
      {
        value: 'sportBR',
        label: 'Sport a basso Rischio',
        name: 'Sport a basso Rischio',
        checked: false
      },
      {
        value: 'sportAR',
        label: 'Sport a alto rischio',
        name: 'Sport a alto rischio',
        checked: false
      },
      {
        value: 'parificazioneGragoIP',
        label: 'Cond. Agg. 8 - Parificazione del grado IP - Eliminazione franchigia per IP grave',
        name: 'Cond. Agg. 8 - Parificazione del grado IP - Eliminazione franchigia per IP grave',
        checked: false
      },
      {
        value: 'condAgg9',
        label: 'Cond. Agg. 9 - Tabella accertamento del grado IP (INAIL)',
        name: 'Cond. Agg. 9 - Tabella accertamento del grado IP (INAIL)',
        checked: false
      },
      {
        value: 'capAggIPGrave',
        label: 'Capitale aggiuntivo per IP grave',
        name: 'Capitale aggiuntivo per IP grave',
        checked: false
      },
      {
        value: 'calamitaNaturali',
        label: 'Calamità naturali (si riferiscono al capoluogo)',
        name: 'Calamità naturali (si riferiscono al capoluogo)',
        checked: false
      },
      {
        value: 'condAggIPM',
        label: 'Cond Agg IPM',
        name: 'Cond Agg IPM',
        checked: false
      },
      {
        value: 'raddoppioDiriaRicGrandIntChirurgico',
        label: 'Raddoppio della diaria da ricovero in caso di patologia oncologica',
        name: 'Raddoppio della diaria da ricovero in caso di patologia oncologica',
        checked: false
      }
    ]
  }

  ngOnInit(): void {
    console.log(this.env.attivitaProfessionaleMap)
    this.headerFeaService.onHide$.emit(false)
    this.form.get('dataScadenza')?.disable()
    this.form.get('durata')?.disable()
    this.form.get('frazionamento')?.disable()
    this.route.queryParams.subscribe((params) => {
      const obj = JSON.parse(params['zeroImprevistiFormObj'])
      console.log(obj)
      this.form.get('dataEffetto')?.setValue(obj.dataEffettoValue)
      this.form.get('dataScadenza')?.setValue(obj.dataScadenzaValue)
      this.form.get('anni')?.setValue(obj.anni)
      this.form.get('classeRischio')?.setValue(obj.classeRischio)
      this.form.get('classeRischio')?.disable()
      this.attivitaProfessionaleSelected = obj.attivitaProfessionaleSelected
      this.provinciaSelected = obj.provinciaSelected
      this.categoariaLavorativaSelected = obj.categoariaLavorativaSelected
      this.combinazioneAssicuratoSelected = obj.combinazioneAssicuratoSelected
      this.totalSM = obj.totalSM
      this.franchigiaIP = obj.franchigiaIPSelected
      this.franchigiaCondAggIPM = obj.franchigiaCondAggIPMSelected
      this.franchigiaDiaria = obj.franchigiaDiariaSelected
      this.speseCuraInfortunio = obj.speseCuraInfortunioSelected
      obj.garanzie.forEach((garanzia: { checked: any; name: string; sommaAssicurata: number | undefined }) => {
        if (garanzia.checked) {
          const match = this.listGaranzie.find((element) => garanzia.name === element.name)
          if (match) {
            match.checked = true
            match.sommaAssicurata = garanzia.sommaAssicurata
          }
        }
      })
      this.listGaranzie = obj.garanzie
    })
  }

  displayValue(event: any) {
    console.log(event.value)
  }

  selectDurata(event: any) {
    this.durataSelected = event.value
  }

  selectCombinazioneAssicurato(event: any) {
    this.combinazioneAssicuratoSelected = event.value
  }

  selecCategoariaLavorativa(event: any) {
    this.categoariaLavorativaSelected = event.value
  }

  selecAttivitaProfessionale(event: any) {
    const professione = this.listProfessioni.find((element) => element.value === event.value)
    if (professione) {
      this.form.get('classeRischio')?.setValue(professione.classeRischio)
    }
    this.form.get('classeRischio')?.disable()
    this.attivitaProfessionaleSelected = event.value
  }

  selectProvincia(event: any) {
    this.provinciaSelected = event.value
  }

  changeDataEffetto(event: any) {
    const date = moment(event).add(1, 'y')
    this.form.get('dataScadenza')?.setValue(date)
  }

  checkIsOneGaranziaChecked() {
    if (this.listGaranzie.find((element) => element.checked === true)) return true
    return false
  }

  uncheckCondizioniAggiuntiveInfortuni(
    garanzia:
      | {
          value: string
          label: string
          name: string
          checked?: boolean | undefined
          sommaAssicurata?: number | undefined
        }
      | undefined
  ) {
    if (garanzia && garanzia.value == 'invaliditaPermanenteDaInfortunio' && !garanzia.checked) {
      const sportBR = this.listGaranzie.find((element) => element.value === 'sportBR')
      if (sportBR) {
        sportBR.checked = false
      }
      const sportAR = this.listGaranzie.find((element) => element.value === 'sportAR')
      if (sportAR) {
        sportAR.checked = false
      }
      const parificazioneGragoIP = this.listGaranzie.find((element) => element.value === 'parificazioneGragoIP')
      if (parificazioneGragoIP) {
        parificazioneGragoIP.checked = false
      }
      const condAgg9 = this.listGaranzie.find((element) => element.value === 'condAgg9')
      if (condAgg9) {
        condAgg9.checked = false
      }
      const capAggIPGrave = this.listGaranzie.find((element) => element.value === 'capAggIPGrave')
      if (capAggIPGrave) {
        capAggIPGrave.checked = false
      }
      const calamitaNaturali = this.listGaranzie.find((element) => element.value === 'calamitaNaturali')
      if (calamitaNaturali) {
        calamitaNaturali.checked = false
      }
    }
  }

  uncheckCondizioniAggiuntiveMalattia(
    garanzia:
      | {
          value: string
          label: string
          name: string
          checked?: boolean | undefined
          sommaAssicurata?: number | undefined
        }
      | undefined
  ) {
    if (garanzia && garanzia.value == 'diariaMalattia' && !garanzia.checked) {
      const raddoppioDiriaRicGrandIntChirurgico = this.listGaranzie.find(
        (element) => element.value === 'raddoppioDiriaRicGrandIntChirurgico'
      )
      if (raddoppioDiriaRicGrandIntChirurgico) {
        raddoppioDiriaRicGrandIntChirurgico.checked = false
      }
    }
    if (garanzia && garanzia.value == 'invaliditaPermanente' && !garanzia.checked) {
      const condAggIPM = this.listGaranzie.find((element) => element.value === 'condAggIPM')
      if (condAggIPM) {
        condAggIPM.checked = false
      }
    }
  }

  changeGaranzie(event: any) {
    if (event) {
      const match = this.listGaranzie.find((element) => element.value === event.value)
      if (match) {
        match.checked = !match.checked
        this.uncheckCondizioniAggiuntiveInfortuni(match)
        this.uncheckCondizioniAggiuntiveMalattia(match)
      }
    }
  }

  next() {
    const dataScadenzaFrm = this.form.get('dataScadenza')?.value
    let dataScadenzaValue = ''
    let dataScadenza = ''
    let dataEffetto = ''
    let dataEffettoValue = ''
    if (dataScadenzaFrm) {
      dataScadenza = moment(dataScadenzaFrm).format('DD/MM/YYYY')
      dataScadenzaValue = moment(dataScadenzaFrm).format('YYYY-MM-DD')
    }
    const dataEffettoFrm = this.form.get('dataEffetto')?.value
    if (dataEffettoFrm) {
      dataEffetto = moment(dataEffettoFrm).format('DD/MM/YYYY')
      dataEffettoValue = moment(dataEffettoFrm).format('YYYY-MM-DD')
    }
    const classeRischio = this.form.get('classeRischio')?.value
    const anni = this.form.get('anni')?.value

    console.log(this.listGaranzie)
    const zeroImprevistiFormObj = {
      garanzie: this.listGaranzie,
      dataEffetto: dataEffetto,
      dataEffettoValue: dataEffettoValue,
      anni: anni,
      dataScadenza: dataScadenza,
      dataScadenzaValue: dataScadenzaValue,
      categoariaLavorativaSelected: this.categoariaLavorativaSelected,
      durataSelected: this.durataSelected,
      frazionamentoSelected: this.frazionamentoSelected,
      attivitaProfessionaleSelected: this.attivitaProfessionaleSelected,
      provinciaSelected: this.provinciaSelected,
      combinazioneAssicuratoSelected: this.combinazioneAssicuratoSelected,
      classeRischio: classeRischio,
      totalSM: this.totalSM,
      franchigiaDiariaSelected: this.franchigiaDiaria,
      franchigiaIPSelected: this.franchigiaIP,
      franchigiaCondAggIPMSelected: this.franchigiaCondAggIPM,
      speseCuraInfortunioSelected: this.speseCuraInfortunio
    }
    console.log(zeroImprevistiFormObj)

    this.router.navigate(['zero-imprevisti-sm'], {
      queryParams: { zeroImprevistiFormObj: JSON.stringify(zeroImprevistiFormObj) },
      skipLocationChange: true
    })
  }
}
