import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { forkJoin, of } from 'rxjs'
import { map, mergeMap, switchMap, take } from 'rxjs/operators'
import { ApsaQuoteDetail, ApsaQuoteSummary, User } from '../../interfaces'
import { MOCK_LISTA_PREVENTIVI } from '../../mocks'
import { AbstractItemsService } from '../abstract-item.service'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root'
})
export class PreventiviService implements AbstractItemsService<any> {
  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private env: Environment,
    private user: UserService
  ) {
    console.log('env', env)
  }

  getList() {
    console.log('PreventiviService > getList > loggedInAs', this.user.loggedInAs())
    return this.http
      .get<ApsaQuoteSummary[]>(`${this.env.findPreventivi}?codiceCliente=${this.user.loggedInAs()?.clientId}`, {
        withCredentials: true
      })
      .pipe(
        mergeMap((quotes) => {
          const allIds = quotes.map((quote) => this.findById(quote.numeroPreventivo as string))
          return forkJoin([...allIds]).pipe(
            map((statusArray) => {
              console.log(statusArray)
              quotes.forEach((quote, index) => {
                if (statusArray[index].data[0]) quote.status = statusArray[index].data[0].status || ''
              })
              return quotes
            })
          )
        })
      )
  }

  findOne(preventivoNumero: string) {
    console.log('findOne', preventivoNumero)
    console.log('env.findOnePreventivi', this.env.findOnePreventivi)
    return this.http
      .get<ApsaQuoteDetail>(
        `${this.env.findOnePreventivi}?codiceCliente=${
          this.user.loggedInAs()?.clientId
        }&chiaveDiStart=${preventivoNumero}`,
        {
          withCredentials: true
        }
      )
      .pipe(take(1))
  }

  findById(id: string) {
    return this.http
      .get<{ data: ApsaQuoteDetail[] }>(`${this.env.apiServer}api/quotes/${id}`, {
        withCredentials: true
      })
      .pipe(take(1))
  }
}

@Injectable({
  providedIn: 'root'
})
export class MockPreventiviService implements AbstractItemsService<any> {
  constructor() {}

  getList(loggedUser?: any) {
    return of(MOCK_LISTA_PREVENTIVI)
  }
}
