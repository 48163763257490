export interface QuotationRequest {
  visitorId?: string
  touchPoint?: string
  productCode?: string
  packageCode?: string
  variables?: { [name: string]: string | number | boolean | Array<string> | any }
  guarantees?: { [name: string]: QuotationRequestGuarantee }
  policyHolder?: PolicyHolder
  insured?: Insurable[]
  productNode?: string
  ageDescription?: string
  survey?: Survey
  referer?: string
  remoteSelling?: RemoteSelling
  quotationNumber?: string
  asShop?: any
  agent?: any
}
export interface QuotationRequestGuarantee {
  clauses: { [name: string]: string }
}
export interface Survey {
  result?: string
  code: string
  title: string
  text: string
  questions: { [code: string]: SurveyQuestion }
}

export enum SurveyQuestionType {
  TEXT = 'TEXT',
  GROUP = 'GROUP',
  MULTICHOISE = 'MULTICHOISE',
  SINGLECHOISE = 'SINGLECHOISE'
}

export enum SurveyQuestionResult {
  ADEGUATO = 'ADEGUATO',
  NONADEGUATO = 'NONADEGUATO',
  NONVALUTABILE = 'NONVALUTABILE'
}

export interface SurveyQuestion {
  code: string
  type: SurveyQuestionType
  title: string
  text?: string
  textNONADEGUATO?: string
  message?: string
  formula?: { [key: string]: string }
  answers?: { [code: string]: SurveyAnswer }
  textAnswer?: string
  result?: SurveyQuestionResult
  questions?: { [code: string]: SurveyQuestion }
}

export interface SurveyAnswer {
  code: string
  text: string
  value?: boolean
  textNONADEGUATO?: string
}

export interface RemoteSelling {
  agenteId: string
  agenteEmail: string
  product: string
}

export interface PolicyGuarantee extends QuotationRequestGuarantee {
  code: string
  description: string
  name: string
  totalAmount: number
  totalTax: number
  totalNet: number
  price: number
  taxes: number
  net: number
  taxPerc: number
  formulas: { [key: string]: string }
}

export interface PolicyHolder extends PhysicalPeople {
  email?: string
  phone?: string
  residentialAddress?: Address
  privacyRead?: boolean
  checkPrivacy?: boolean
  checkMarketingPrivacy?: boolean
  checkProfilePrivacy?: boolean
  terminiUso?: boolean
  flyerRead?: boolean
}
export interface Insurable {
  type: 'PhysicalPeople' | 'Vehicle' | 'Motorbike' | 'Bike'
  idx?: number
  plate?: string
  variables?: { [key: string]: string }
  tipoOpzione?: string
  secondPlate?: string
}

export interface PhysicalPeople extends Insurable {
  subSystemCustomerId?: string
  surname?: string
  firstName?: string
  fiscalCode?: string
  sex?: Sex
  birth?: Birth
  italianResidential?: boolean
}
export interface Address {
  address?: string
  addressNumber?: string
  zipCode?: string
  city?: string
  district?: string
  nation?: string
}
export enum Sex {
  MALE = 'M',
  FEMALE = 'F'
}

export interface Birth {
  date: string
  place: string
  district: string
  nation: string
}
