import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Observable, of } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { PurchaseData, PurchaseQuoteUploadableFiles, PurchaseResult, QuoteDocument } from '../../interfaces'

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  constructor(private http: HttpClient, @Inject(EnvironmentServiceToken) private environment: Environment) {}

  uploadFile(data: PurchaseData): Observable<PurchaseResult> {
    const formData: FormData = new FormData()
    formData.append('quoteNumber', data.quoteNumber)

    const mappa: { [key: string]: string } = {}

    if (data.attachmentList) {
      data.attachmentList.forEach((a) => {
        mappa[a.filename] = a.code
        formData.append(a.filename, a.content)
        formData.append('mappa', JSON.stringify(mappa))
      })
    }
    return this.http.post<PurchaseResult>(
      `${this.environment.apiServer}api/apsa/uploadFilesPurchase/${data.quoteNumber}`,
      formData,
      {
        withCredentials: true
      }
    )
  }

  updateFile(data: PurchaseData): Observable<PurchaseResult> {
    const formData: FormData = new FormData()
    formData.append('quoteNumber', data.quoteNumber)

    const mappa: { [key: string]: string } = {}

    if (data.attachmentList) {
      data.attachmentList.forEach((a) => {
        mappa[a.filename] = a.code
        formData.append(a.filename, a.content)
        formData.append('mappa', JSON.stringify(mappa))
      })
    }
    return this.http.put<PurchaseResult>(
      `${this.environment.apiServer}api/apsa/uploadFilesPurchase/${data.quoteNumber}`,
      formData,
      {
        withCredentials: true
      }
    )
  }

  postQuotes(quote: any) {
    return this.http.post<any>(`${this.environment.apiServer}api/quotes`, quote, {
      observe: 'response',
      withCredentials: true
    })
  }

  // get detail quote per verificare esistenza del quote
  getQuotesDetail(quoteNumber: string) {
    return this.http
      .get<any>(`${this.environment.apiServer}api/quotes/${quoteNumber}`)
      .pipe(map((result) => result.data[0]))
  }

  // buy-quote
  public buyQuote(quoteId: string, body: any, paymentType?: string) {
    let url: string
    if (paymentType) {
      url = `${this.environment.apiServer}api/buy-quote/${paymentType}/${quoteId}`
    } else {
      url = `${this.environment.apiServer}api/buy-quote/${quoteId}`
    }

    return this.http
      .post<any>(url, body, {
        withCredentials: true
      })
      .pipe(take(1))
  }

  public checkFeaAcceptance(quoteId: string, body: any) {
    const url = `${this.environment.apiServer}api/check-fea-acceptance/${quoteId}`

    return this.http
      .post<any>(url, body, {
        withCredentials: true
      })
      .pipe(take(1))
  }

  getQuotesDocuments(quoteNumber: string): Observable<QuoteDocument[]> {
    return this.http
      .get<any>(`${this.environment.apiServer}api/quotes/documentsQuotes/${quoteNumber}`)
      .pipe(map((res) => res.data))
  }

  getAvailableFiles(): Observable<
    {
      code: string
      fileDescription: string
      fileName: PurchaseQuoteUploadableFiles
    }[]
  > {
    return of([
      {
        code: 'CERTCIRC',
        fileDescription: 'CERTCIRCFileName',
        fileName: PurchaseQuoteUploadableFiles.CERTCIRC
      },
      {
        code: 'PASSPROP',
        fileDescription: 'PASSPROPFileName',
        fileName: PurchaseQuoteUploadableFiles.PASSPROP
      },
      {
        code: 'POLPREC',
        fileDescription: 'POLPRECFileName',
        fileName: PurchaseQuoteUploadableFiles.POLPREC
      },
      {
        code: 'CERTANTF',
        fileDescription: 'CERTANTFFileName',
        fileName: PurchaseQuoteUploadableFiles.CERTANTF
      },
      {
        code: 'POLSCAD',
        fileDescription: 'POLSCADFileName',
        fileName: PurchaseQuoteUploadableFiles.POLSCAD
      },
      {
        code: 'CERTLEAS',
        fileDescription: 'CERTLEASFileName',
        fileName: PurchaseQuoteUploadableFiles.CERTLEAS
      },
      {
        code: 'OTHER',
        fileDescription: 'OTHERFileName',
        fileName: PurchaseQuoteUploadableFiles.OTHER
      }
    ])
  }
}
