/* eslint-disable no-secrets/no-secrets */
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { IPaganiniDoc } from '@helvetia-italia/shared/business'
import { constants } from 'buffer'
import { Observable, of } from 'rxjs'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root'
})
export class PaganiniService {
  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private env: Environment,
    private user: UserService
  ) {}

  getList(params: string): Observable<any> {
    console.log(`Call Filenet API passing Codice Fiscale / P.IVA : ${params}`)
    const queryUrl = `${this.env.getPaganiniDocsUrl}${params}`
    console.log(queryUrl)
    return this.http.get<IPaganiniDoc[]>(`${queryUrl}`)
  }

  downloadDOcument(idDoc: string): Observable<any> {
    console.log(`Download document : ${idDoc}`)
    const queryUrl = `${this.env.downloadPaganiniDocUrl}${idDoc}`
    return this.http.get(`${queryUrl}`)
  }
}
