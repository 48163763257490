import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SummaryQuestionarioComponent } from './summary-questionario.component'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatDividerModule } from '@angular/material/divider'
import { MatListModule } from '@angular/material/list'
import {
  UxNgAlertModule,
  UxNgClosableBoxModule,
  UxNgIconModule,
  UxNgIconTeaserModule
} from '@helvetiait/ux-ng-material-library'
import { FlexLayoutModule } from '@angular/flex-layout'
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
const material_modules = [MatIconModule, MatProgressBarModule, MatDividerModule, MatListModule]
const ux_modules = [UxNgAlertModule, UxNgClosableBoxModule, UxNgIconModule, UxNgIconTeaserModule]
@NgModule({
  declarations: [SummaryQuestionarioComponent],
  imports: [CommonModule, FlexLayoutModule, RouterModule, HttpClientModule, ...material_modules, ...ux_modules],
  exports: [SummaryQuestionarioComponent]
})
export class SummaryQuestionarioModule {}
