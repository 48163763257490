import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { isNil } from 'lodash'
import { UserType } from '../interfaces'
import { UserService } from '../ui-services'

@Injectable({
  providedIn: 'root'
})
export class FeaAgentGuard implements CanActivate {
  constructor(private user: UserService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(): boolean {
    const user = this.user.loggedInAs()?.username
    const type = this.user.getLocalUserState()?.type
    if (isNil(user)) {
      return false
    }
    if (type === UserType.DIREZIONE) {
      return false
    }
    return true
  }
}
