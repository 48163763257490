import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ModalInput } from '@helvetia-italia/angular-libs/ng-shared'

/**
 * Modal that displays some dynamic content passed by caller and a list of expandable text
 * @Input data: ModalInput
 */
@Component({
  selector: 'helvetia-italia-invalid-survey-modal',
  templateUrl: './invalid-survey-modal.component.html',
  styleUrls: ['./invalid-survey-modal.component.scss']
})
export class InvalidSurveyModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalInput,
    public dialogRef: MatDialogRef<InvalidSurveyModalComponent>
  ) {}

  confirm() {
    this.dialogRef.close({ confirm: true })
  }
}
