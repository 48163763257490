import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UxNgIconTeaserModule, UxNgIconModule } from '@helvetiait/ux-ng-material-library'
import { ProductComponent } from './product/product.component'
import { ProductsListComponent } from './products-list/products-list.component'
import { MatCardModule } from '@angular/material/card'
import { FlexModule } from '@angular/flex-layout'

@NgModule({
  declarations: [ProductsListComponent, ProductComponent],
  imports: [CommonModule, UxNgIconTeaserModule, UxNgIconModule, MatCardModule, FlexModule],
  exports: [ProductsListComponent, ProductComponent]
})
export class ProductsListModule {}
