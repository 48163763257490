import { Injectable } from '@angular/core'
import get from 'lodash/get'

const regexp = /{{[ ]*[\w.]+[ ]*}}/gm
@Injectable({ providedIn: 'root' })
export class InterpolationService {
  interpolate(message: any, context: any) {
    if (typeof message !== 'string') {
      Object.keys(message).forEach((key: string) => {
        const value = message[key]
        if (typeof value === 'string') {
          message[key] = this.interpolate(value, context)
        }
      })
      return message
    }
    regexp.lastIndex = 0
    if (regexp.exec(message)) {
      message = message.replace(regexp, (substring: string) => {
        const content = get(context, this.purgeString(substring))
        return this.evaluateContent(content, context)
      })
    }
    return message
  }

  private evaluateContent(content: any, context: any) {
    let returnValue = ''
    switch (typeof content) {
      case 'string':
        returnValue = content
        break
      case 'function':
        returnValue = content()
        break
    }
    if (regexp.exec(returnValue)) return this.interpolate(returnValue, context)
    return returnValue
  }

  private purgeString(token: string) {
    return token.replace('{{', '').replace('}}', '').trim()
  }
}
