import get from 'lodash/get'

const regexp = /{{[ ]*[\w.]+[ ]*}}/gm

export function interpolate(message: any, context: any) {
  if (typeof message !== 'string') {
    Object.keys(message).forEach((key: string) => {
      const value = message[key]
      if (typeof value === 'string') {
        message[key] = interpolate(value, context)
      }
    })
    return message
  }
  regexp.lastIndex = 0
  if (regexp.exec(message)) {
    message = message.replace(regexp, (substring: string) => {
      const content = get(context, purgeString(substring))
      return evaluateContent(content, context)
    })
  }
  return message
}

function evaluateContent(content: any, context: any) {
  let returnValue = ''
  switch (typeof content) {
    case 'string':
      returnValue = content
      break
    case 'function':
      returnValue = content()
      break
  }
  if (regexp.exec(returnValue)) return interpolate(returnValue, context)
  return returnValue
}

function purgeString(token: string) {
  return token.replace('{{', '').replace('}}', '').trim()
}
