import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { ObjectSerializer } from '@kubernetes/client-node'
import { Observable, of } from 'rxjs'
import { QuotationRequest, Survey, SurveyQuestion } from '../../interfaces'
import { MOCK_SURVEY } from '../../mocks'
import { QuotationService } from '../quotation/quotation.service'

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(
    @Inject(EnvironmentServiceToken) private environment: Environment,
    private http: HttpClient,
    private quotation: QuotationService
  ) {}

  find(packageCode: string, productCode: string): Observable<Survey> {
    return this.http.get<Survey>(`${this.environment.findSurvey}/${productCode}/${packageCode}`)
  }

  evaluate(quotation: QuotationRequest): Observable<SurveyResultMessage> {
    return this.http.post<SurveyResultMessage>(`${this.environment.apiServer}api/purchase/survey?mode=full`, quotation)
  }

  countLocalAnwers(): number {
    const survey = this.quotation.currentRequest.survey
    if (!survey) return 0
    let givenAnswers = 0
    const questions = survey.questions
    Object.keys(questions).forEach((q_code) => {
      const question = questions[q_code]
      const answers = question.answers
      if (answers) {
        Object.keys(answers).forEach((answer_code) => {
          const answer = answers[answer_code]
          if (answer.value) {
            givenAnswers++
          }
        })
      }
      if (question.questions) {
        const sub_questions = Object.keys(question.questions).map((code) =>
          question.questions ? question.questions[code] : null
        )
        sub_questions.forEach((question) => {
          if (question?.answers) {
            const sub_answers = Object.keys(question?.answers).map((code) =>
              question?.answers ? question?.answers[code] : null
            )
            sub_answers.forEach((answer) => (answer?.value ? givenAnswers++ : null))
          }
        })
      }
    })
    return givenAnswers
  }

  countTotalQuestions(survey: Survey) {
    let totalQuestions = 0
    const questions = Object.keys(survey.questions).map((code) => survey.questions[code])
    questions.forEach((question) => {
      if (question.answers) totalQuestions++
      else if (!question.answers && question.questions) {
        const sub_questions = Object.keys(question.questions).map((code) =>
          question.questions ? question.questions[code] : null
        )
        sub_questions.forEach(() => totalQuestions++)
      }
    })
    return totalQuestions
  }

  getQuestion(survey: Survey, code: string): SurveyQuestion | undefined {
    const questions = Object.keys(survey.questions).map((code) => survey.questions[code])
    let questionByCode: SurveyQuestion | undefined = questions.find((question) => question.code === code)
    if (!questionByCode) {
      questions.forEach((question) => {
        if (question.questions) {
          const sub_questions = Object.keys(question.questions).map((code) =>
            question.questions ? question.questions[code] : null
          )
          sub_questions.forEach((sub_question) => {
            if (sub_question && sub_question.code === code) questionByCode = sub_question
          })
        }
      })
    }
    return questionByCode
  }
}
export interface SurveyResultMessage extends Survey {
  result: string
}

@Injectable({
  providedIn: 'root'
})
export class MockSurveyService extends SurveyService {
  constructor(
    @Inject(EnvironmentServiceToken) environment: Environment,
    http: HttpClient,
    quotation: QuotationService
  ) {
    super(environment, http, quotation)
  }

  find(packageCode: string, productCode: string): Observable<Survey> {
    return of(MOCK_SURVEY)
  }
}
