import { trigger, state, style, transition, animate } from '@angular/animations'

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'enter',
      style({
        height: '100%'
      })
    ),
    state(
      'leave',
      style({
        height: '0px'
      })
    ),
    transition('leave => enter', [
      animate(
        '500ms ease-in-out',
        style({
          height: '100%'
        })
      )
    ]),
    transition('enter => leave', [
      animate(
        '500ms ease-in-out',
        style({
          height: '0'
        })
      )
    ])
  ])
]
