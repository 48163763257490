import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { UserType } from '../interfaces/user.models'
import { AuthenticationService } from '../ui-services/login/authentication.service'
import { Environment } from '@helvetia-italia/angular-libs/environment'
import { BaseGuard } from './base.guard'
import get from 'lodash/get'

export const ApplicationGuardForUserTypeBuilder = (userType: UserType) => {
  return (environment: Environment, authenticationService: AuthenticationService, router: Router) => {
    return new ApplicationGuardByUserType(userType, authenticationService, environment, router)
  }
}

export class ApplicationGuardByUserType extends BaseGuard {
  constructor(
    private userType: UserType,
    private authenticationService: AuthenticationService,
    protected environment: Environment,
    protected router: Router
  ) {
    super(router, environment)
  }
  /* istanbul ignore next */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (
      !this.environment ||
      !this.environment.urls ||
      !this.environment.urls.login ||
      !get(this.environment.urls.login, this.userType)
    ) {
      // istanbul ignore next line
      throw Error('Application Missing Configuration')
    }

    if (super.canActivate(route, state)) {
      // se utente è loggato e type è AFFINITY
      const user = this.authenticationService.isAuthenticated()
      if (user && user.userState && user.userState.logged && user.userState.type === this.userType) {
        return true
      }

      // utente non loggato: mando alla login
      sessionStorage.clear()
      sessionStorage.setItem('originUrl', state.url)

      window.location.href = get(this.environment.urls.login, this.userType)
    }
    return false
  }
}
