export interface Anagrafica {
  gender: string
  name: string
  surname: string
  dateOfBirth: Date
  placeOfBirth: string
  fiscalCode: string
}

export interface AnagraficaOptions {
  type: 'light' | 'complete' | 'none'
  layoutSchema: AnagraficaLayout
}

export interface AnagraficaLayout {
  rows: AnagraficaRow[]
}

export interface AnagraficaRow {
  components: AnagraficaComponents[]
}
export interface AnagraficaComponents {
  name: AnagraficaComponent
  flex: number
  classes?: string
}

export enum AnagraficaComponent {
  Genere = 'gender',
  Nome = 'name',
  Cognome = 'surname',
  DataDiNascita = 'dateOfBirth',
  LuogoDiNascita = 'placeOfBirth',
  CodiceFiscale = 'fiscalCode'
}

export interface ValidateFiscalCodeRequestPayload {
  physicalPeople?: {
    name?: string
    surname?: string
    gender?: 'M' | 'F'
    fiscalCode?: string
    italianResidence?: boolean
    birth?: {
      date: string // 'yyyy-mm-dd'
      city: string // 'Milano'
      district: string // 'MI'
      nation: string // 'IT'
    }
  }
  validationType?: 'full' | 'light'
}
