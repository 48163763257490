import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { EnvironmentService, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { ValidateFiscalCodeRequestPayload } from '../anagrafica'
@Injectable({
  providedIn: 'root'
})
export class AnagraficaService {
  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private environmentService: EnvironmentService
  ) {}

  getFiscalCodeValidity(payload: ValidateFiscalCodeRequestPayload) {
    return this.http.post(this.environmentService.fiscalCodeValidityUrl, payload, {
      headers: { secret: this.environmentService.fiscalCodeValiditySecret }
    })
  }
}
