import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, AfterViewInit } from '@angular/core'
import { ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms'
import { LoggedInAs, User, UserState } from '@helvetia-italia/angular-libs/ng-shared'
import { Subscription } from 'rxjs'
import { ControlErrorsMessageService, GroupValueAccessorBase } from '../../inputs'

@Component({
  selector: 'helvetia-italia-indirizzo-utente',
  templateUrl: './indirizzo-utente.component.html',
  styleUrls: ['./indirizzo-utente.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: IndirizzoUtenteComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IndirizzoUtenteComponent,
      multi: true
    }
  ]
})
export class IndirizzoUtenteComponent
  extends GroupValueAccessorBase
  implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit
{
  @Input() user!: LoggedInAs
  @Input() confirmLabel = "Confermo l' indirizzo inserito in precedenza"
  @Input() newAddressLabel = 'Scelgo un altro indirizzo per la spedizione'

  newAddressControl = new FormControl()
  confirmAddressControl = new FormControl(true)

  public subscriptions: Subscription[] = []

  constructor(
    private builder: FormBuilder,
    public controlErrorsMessageService: ControlErrorsMessageService,
    public cdRef: ChangeDetectorRef
  ) {
    super(cdRef)

    this.form = this.builder.group({
      name: '',
      lastname: '',
      dateOfBirth: '',
      address: '',
      postalCode: '',
      city: '',
      district: ''
    })
  }

  ngOnInit() {
    this.form.disable()
  }
  ngAfterViewInit(): void {
    // this.form.patchValue({
    //   name: this.user?.name,
    //   lastname: this.user?.surname,
    //   dateOfBirth: this.user?.dateOfBirth,
    //   address: this.user?.address,
    //   postalCode: this.user?.postalCode,
    //   city: this.user?.city,
    //   district: this.user?.district
    // })
    this.newAddressControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.enable()
        this.confirmAddressControl.setValue(false, { emitEvent: false })
      } else {
        this.form.disable()
        this.confirmAddressControl.setValue(true, { emitEvent: false })
      }
    })
    this.confirmAddressControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.disable()
        this.newAddressControl.setValue(false, { emitEvent: false })
      } else {
        this.form.enable()
        this.newAddressControl.setValue(true, { emitEvent: false })
      }
    })
  }

  fillFormByAddress(result: google.maps.places.PlaceResult) {
    if (result && result.address_components) {
      const cap = this.form.get('postalCode')
      const provincia = this.form.get('district')
      const citta = this.form.get('city')

      const address_provincia = result.address_components.find((comp) =>
        comp.types.includes('administrative_area_level_2')
      )
      const address_citta = result.address_components.find((comp) => comp.types.includes('administrative_area_level_3'))
      const address_postalcode = result.address_components.find((comp) => comp.types.includes('postal_code'))
      // TODO

      //istanbul ignore next line
      address_citta ? citta?.setValue(address_citta.short_name) : citta?.setValue('')
      // TODO
      //istanbul ignore net line
      address_provincia ? provincia?.setValue(address_provincia.short_name) : provincia?.setValue('')
      // TODO
      //istanbul ignore net line
      address_postalcode ? cap?.setValue(address_postalcode.short_name) : cap?.setValue('')
    }
  }

  fillFormByCity(result: google.maps.places.PlaceResult) {
    if (result && result.address_components) {
      const cap = this.form.get('postalCode')
      const provincia = this.form.get('district')
      const address_provincia = result.address_components.find((comp) =>
        // TODO

        comp.types.includes('administrative_area_level_2')
      )
      const address_postalcode = result.address_components.find((comp) => comp.types.includes('postal_code'))
      // TODO
      //istanbul ignore net line
      address_provincia ? provincia?.setValue(address_provincia.short_name) : provincia?.setValue('')
      // TODO
      //istanbul ignore net line
      address_postalcode ? cap?.setValue(address_postalcode.short_name) : cap?.setValue('')
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }
  validate() {
    return null
  }
}
