import { HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { UserService, HeaderFeaService } from '@helvetia-italia/angular-libs/ng-shared'
import {
  UxNgAlertModule,
  UxNgEnvironmentIndicatorModule,
  UxNgFileuploadModule,
  UxNgFooterModule,
  UxNgHeaderModule,
  UxNgHorizontalShadowModule,
  UxNgIconModule,
  UxNgSpinnerModule,
  UxNgVerticalSpacerModule,
  UxNgWaitDialogModule
} from '@helvetiait/ux-ng-material-library'

import { MatIconModule } from '@angular/material/icon'
import { NgModule } from '@angular/core'
import { HeaderFeaComponent } from './header-fea.component'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'

const material_modules = [MatIconModule, MatButtonModule, MatIconModule, MatFormFieldModule]
const helvetia_modules = [
  UxNgVerticalSpacerModule,
  UxNgHeaderModule,
  UxNgHorizontalShadowModule,
  UxNgFileuploadModule,
  UxNgSpinnerModule,
  UxNgWaitDialogModule,
  UxNgVerticalSpacerModule,
  UxNgEnvironmentIndicatorModule,
  UxNgFooterModule,
  UxNgIconModule,
  UxNgAlertModule
]

@NgModule({
  declarations: [HeaderFeaComponent],
  imports: [HttpClientModule, BrowserModule, BrowserAnimationsModule, ...helvetia_modules, ...material_modules],
  providers: [UserService, HeaderFeaService],
  exports: [HeaderFeaComponent]
})
export class HeaderFeaModule {}
