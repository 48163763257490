export const MOCK_HOMEPAGE_TABS = [
  {
    group: 'TEMPO_LIBERO',
    title: 'Tempo libero',
    active: true
  },
  {
    group: 'FUTURO',
    title: 'Futuro',
    active: false
  },
  {
    group: 'BENI',
    title: 'Per i tuoi beni',
    active: false
  }
]
