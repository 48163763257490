import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { SummaryStatus, SummaryConfig } from '@helvetia-italia/angular-libs/ng-shared'
@Component({
  selector: 'helvetia-italia-summary-questionario',
  templateUrl: './summary-questionario.component.html',
  styleUrls: ['./summary-questionario.component.scss']
})
export class SummaryQuestionarioComponent implements OnInit, OnChanges {
  @Input() config: SummaryConfig = {}
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  getProgressValue(): number {
    if (this.config.partialAnswers && this.config.totalAnswers) {
      return (100 * this.config.partialAnswers) / this.config.totalAnswers
    } else {
      return 0
    }
  }

  getUIByStatus(): { progressbar: string; alert: any } {
    if (this.config && this.config.status) {
      switch (this.config.status) {
        case SummaryStatus.Approved:
          return { progressbar: 'primary', alert: 'success' }
        case SummaryStatus.NotApproved:
          return { progressbar: 'warn', alert: 'error' }
        case SummaryStatus.Pending:
          return { progressbar: 'accent', alert: 'info' }
      }
    } else {
      return { progressbar: 'grey', alert: 'info' }
    }
  }
}
