import { Insurable } from '.'
import { Atrc } from './atrc'
import { InfoCar } from './info-car'
import { Sivi } from './sivi'

export interface Vehicle extends Insurable {
  atrc2?: Atrc
  atrc?: Atrc
  cilindrata?: any
  codiceFiscale2?: string
  dataImmatricolazione?: any
  dataRetrieve?: any
  info?: InfoCar | any
  motorbikeCilindrata?: any
  plate: string
  provincia?: string
  secondPlate?: string
  sivi2?: Sivi
  sivi?: Sivi
  tipoOpzione?: any
  type: any
}

export enum VehicleType {
  Vehicle = 'Vehicle',
  Motorbike = 'Motorbike',
  Bike = 'Bike'
}
