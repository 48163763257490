import { Injectable } from '@angular/core'
import { FormControl, ValidationErrors, Validators } from '@angular/forms'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CommonValidators {
  constructor() {}

  ValidatorsDictionary = {
    requiredTrue: Validators.requiredTrue,
    required: Validators.required,
    iban: this.ibanValidator,
    email: this.emailValidator,
    carPlate: this.carPlateValidator
  }

  getValidator(name: string): any {
    // @ts-ignore next line
    return this.ValidatorsDictionary[name]
  }

  ibanValidator(formControl: FormControl): ValidationErrors | null {
    if (!formControl.value) {
      return null
    }
    const value = formControl.value as string
    const r = new RegExp(
      /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/gm
    )

    return !r.test(value) ? { ibanNotValid: true } : null
  }

  postalCodeValidator(formControl: FormControl): ValidationErrors | null {
    if (!formControl.value) {
      return null
    }
    const value = formControl.value as string
    const r = new RegExp(/^\d{5}$/)

    return !r.test(value) ? { postalCodeNotValid: true } : null
  }

  emailValidator(formControl: FormControl): ValidationErrors | null {
    if (!formControl.value) {
      return null
    }
    const value = formControl.value as string
    const r = new RegExp(
      /^[^<>()[\]\\+,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/gm
    )

    return !r.test(value) ? { emailNotValid: true } : null
  }

  carPlateValidator(formControl: FormControl): ValidationErrors | null {
    if (!formControl.value) {
      return null
    }
    const value = formControl.value as string
    const r = new RegExp(
      /^[ABCDEFGHJKLMNPRSTVWXYZabcdefghjklmnprstvwxyz]{2}[0-9]{3}[ABCDEFGHJKLMNPRSTVWXYZabcdefghjklmnprstvwxyz]{2}$/gm
    )

    return !r.test(value) ? { carPlateNotValid: true } : null
  }
}
