import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AnagraficaCodiceFiscaleComponent } from './anagrafica-codice-fiscale.component'
import { InputWithLabelModule } from '../../inputs/input-with-label/input-with-label.component.module'
import { DateInputComponentModule } from '../../inputs/date-input/date-input.component.module'
import { GoogleMapsAutocompleterModule } from '../../inputs/google-maps-autocompleter/google-maps-autocompleter.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { RadioGroupModule } from '../../inputs/radio-group/radio-group.module'

@NgModule({
  declarations: [AnagraficaCodiceFiscaleComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    InputWithLabelModule,
    DateInputComponentModule,
    GoogleMapsAutocompleterModule,
    MatFormFieldModule,
    RadioGroupModule
  ],
  exports: [AnagraficaCodiceFiscaleComponent]
})
export class AnagraficaCodiceFiscaleModule {}
