/* eslint-disable no-secrets/no-secrets */
import { Component, Inject } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DynamicForm, FormComposerLoaderService } from '@helvetia-italia/angular-libs/ng-form-composer'
import {
  PolizzaService,
  ProdottiService,
  TipoRichiesta,
  UserRequestService,
  UserService
} from '@helvetia-italia/angular-libs/ng-shared'
import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
import moment from 'moment'
import { EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs'
import { delay, map, switchMap } from 'rxjs/operators'

import { InputWithLabelModule, SelectModule, TextareaModule, DateInputComponentModule } from '../../inputs'
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'

@Component({
  selector: 'helvetia-italia-modal-nuova-richiesta',
  templateUrl: './modal-nuova-richiesta.component.html',
  styleUrls: ['./modal-nuova-richiesta.component.scss']
})
export class ModalNuovaRichiestaComponent {
  form: FormGroup
  config!: DynamicForm | null

  tipiRichiesta: { value: string; label: string }[]
  filtroRichiesta = new FormControl([])
  showFormBtn = false
  showInvalidPolicyMessage = false
  disableSubmitButton = false
  errorBox = {
    show: false,
    title: '',
    content: ' '
  }
  selectedPolicy = ''
  formValueChanges$!: Subscription
  constructor(
    @Inject(MAT_DIALOG_DATA) public origin: string,
    private builder: FormBuilder,
    public userRequestService: UserRequestService,
    private composerLoader: FormComposerLoaderService,
    public userService: UserService,
    public polizzaService: PolizzaService,
    private prodottiService: ProdottiService,
    public dialogRef: MatDialogRef<ModalNuovaRichiestaComponent>,
    public spinner: UxNgSpinnerService,
    public snack: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.composerLoader.add(InputWithLabelModule)
    this.composerLoader.add(SelectModule)
    this.composerLoader.add(TextareaModule)
    this.composerLoader.add(DateInputComponentModule)

    this.form = this.builder.group({
      richiesta: []
    })
    this.tipiRichiesta = this.userRequestService.getFilterList()
  }

  updateFormConfiguration(event: any) {
    if (event && event.value) {
      this.initForm(event.value)
    }
  }

  getFormJoin(form: TipoRichiesta): Observable<[DynamicForm, any, any]> {
    const today = moment().format('YYYY-MM-DD')

    const polizzeObsv = this.polizzaService.getList(
      `?_q={"status":{"$nin":[${
        form === TipoRichiesta.DomandaGenerica
          ? `"TO_CASH","CANCELED", "ABORTED"`
          : `"TO_CASH","CANCELED","REPLACED", "ABORTED"`
      }]},
      "productCode":{"$eq": "PROTECTH"},
      "dates.expiry.date":{"$gte":"${today}"},
      "dates.termination.date":{"$exists":false}}&_l=200&_sk=0`
    )

    const configObsv = this.userRequestService.getFormDefinition(form)

    const prodottiObsv = this.prodottiService.getList(this.origin)

    return forkJoin([configObsv, polizzeObsv, prodottiObsv])
  }
  // istanbul ignore next
  initForm(form: TipoRichiesta) {
    this.config = null
    this.showInvalidPolicyMessage = false
    this.disableSubmitButton = false
    this.formValueChanges$ ? this.formValueChanges$.unsubscribe() : null
    this.getFormJoin(form).subscribe((combined) => {
      const config: any = combined[0]
      const polizze = combined[1]
      const prodotti = combined[2]
      if (polizze.length === 0 && this.hasPolicyField(config)) {
        this.errorBox = {
          show: true,
          title: 'Spiacenti',
          content: 'Non è possibile aprire questo tipo di richiesta senza una polizza attiva !'
        }
        return
      }
      /* Assign policies and products to relative components */
      config.rows.forEach((row: any) => {
        row.components.forEach((component: any, index: number) => {
          if (component.name === 'policyNumber' && polizze.length > 0) {
            Object.assign(component.view.inputs, {
              options: polizze.map((polizza: any) => {
                return {
                  value: polizza.policyNumber,
                  label: `${polizza.policyNumber} - ${polizza.productName}`
                }
              })
            })
          }
          if (component.name === 'productCode') {
            Object.assign(component.view.inputs, {
              options: prodotti.map((prodotto: any) => {
                return {
                  value: prodotto.code,
                  label: `${prodotto.name}`
                }
              })
            })
          }
        })
      })
      /* */
      this.config = config
      this.fillForm()

      if (
        form === TipoRichiesta.AnnulloDisdettaContraente
        //  || form === TipoRichiesta.AnnulloCessazioneRischio
      ) {
        this.formValueChanges$ = this.form.valueChanges
          .pipe(
            switchMap((value: any) => {
              this.showInvalidPolicyMessage = false
              this.disableSubmitButton = false
              if (value && value.richiesta && value.richiesta.policyNumber) {
                return this.polizzaService.getTerminationDate(value.richiesta.policyNumber)
              } else {
                return of(EMPTY)
              }
            })
          )
          .subscribe((result) => {
            this.form.get('richiesta')?.patchValue(
              {
                terminationDate: moment(result?.terminationDateFormatted),
                policyNumber: this.form.get('richiesta')?.value.policyNumber
              },
              { emitEvent: false }
            )
          })
      } else if (form === TipoRichiesta.AnnulloDirittoRipensamento) {
        this.formValueChanges$ = this.form.valueChanges
          .pipe(
            switchMap((value: any) => {
              if (
                value &&
                value.richiesta &&
                value.richiesta.policyNumber &&
                this.policyNumberHasChanged(value.richiesta.policyNumber)
              ) {
                return this.policyValidityCheck(value.richiesta.policyNumber)
              } else {
                return of({ valid: true })
              }
            })
          )
          .subscribe((policy) => {
            this.showInvalidPolicyMessage = !policy.valid
            this.disableSubmitButton = !policy.valid
            this.selectedPolicy = this.form.get('richiesta')?.value.policyNumber
          })
      }
    })
  }

  fillForm() {
    const user = this.userService.loggedInAs()
    if (user) {
      this.form.get('richiesta')?.patchValue({
        surname: user.surname,
        name: user.name,
        email: user.email
      })
    }
  }

  confirm() {
    if (this.form && this.form.valid) {
      const payload = { ...this.form.get('richiesta')?.value }
      console.log(this.form.value)
      const user = this.userService.loggedInAs()
      Object.assign(payload, { type: this.filtroRichiesta.value })
      Object.assign(payload, { fiscalCode: user?.fiscalCode })
      Object.assign(payload, { name: user?.name })
      Object.assign(payload, { surname: user?.surname })
      this.dialog
        .open(ModalConfirmComponent)
        .afterClosed()
        .pipe(
          switchMap((confirmed: boolean) => {
            if (confirmed) {
              return this.handleUseRequestType(payload)
            } else {
              return of(null)
            }
          })
        )
        .subscribe(
          (res) => {
            if (res) {
              this.spinner.stop()
              this.snack.open(`La richiesta al Contact Center è stata aperta con successo !`, 'Chiudi')
              this.dialogRef.close(true)
            }
          },
          (error) => {
            // istanbul ignore next line
            if (error === 'Policy') {
              this.spinner.stop()
              this.errorBox = {
                show: true,
                title: 'Spiacenti',
                content: 'Il diritto di ripensamento per questa polizza è terminato'
              }
            }
          }
        )
    }
  }

  // istanbul ignore next
  handleUseRequestType(payload: any): Observable<any> {
    switch (payload.type) {
      default:
        return this.userRequestService.create(payload)
      // case 'cancel-by-risk-termination':
      //   return this.userRequestService.createException(payload.policyNumber, 'cancelByRiskTermination')
      case 'cancel-by-right-to-reconsider':
        return this.userRequestService.createException(payload.policyNumber, 'cancelByRightOfReconsider', {
          IBAN: this.form.get('richiesta')?.value.IBAN,
          bank_account_header: this.form.get('richiesta')?.value.bank_account_header
        })
      case 'cancel-by-customer-termination':
        return this.userRequestService.createException(payload.policyNumber, 'cancelByCustomerTermination')
    }
  }
  // istanbul ignore next
  policyValidityCheck(policyNumber: any): Observable<{ valid: boolean }> {
    return this.polizzaService.getDetail(policyNumber).pipe(
      delay(200),
      map((policy: any) => {
        const startCoverage = moment(policy.dates.issue.date)
        const diff = moment().diff(startCoverage, 'days')
        const isValidPolicy = diff > 15 ? false : true
        return { valid: isValidPolicy }
      })
    )
  }

  private hasPolicyField(config: DynamicForm): boolean {
    return !!config.rows.find((row) => {
      return row.components.find((component) => component.name === 'policyNumber')
    })
  }

  policyNumberHasChanged(policyNumber: string): boolean {
    return policyNumber === this.selectedPolicy ? false : true
  }
}
