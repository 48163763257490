import { HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { UserService, PipesModule, AgentsGuard } from '@helvetia-italia/angular-libs/ng-shared'
import {
  UxNgAlertModule,
  UxNgEnvironmentIndicatorModule,
  UxNgFileuploadModule,
  UxNgFooterModule,
  UxNgHeaderModule,
  UxNgHorizontalShadowModule,
  UxNgIconModule,
  UxNgSpinnerModule,
  UxNgVerticalSpacerModule,
  UxNgWaitDialogModule
} from '@helvetiait/ux-ng-material-library'
import {
  AnagraficaCodiceFiscaleModule,
  BoxInputModule,
  CheckboxModule,
  DateInputComponentModule,
  DatiVeicoloModule,
  DrawerModule,
  DynamicStepperModule,
  FooterComponentsModule,
  InputWithLabelModule,
  ProductsListModule,
  RadioGroupModule,
  RadioTextGroupModule,
  RecapitoUtenteModule,
  SummaryQuestionarioModule,
  TableDefaultModule,
  SelectModule
} from '@helvetia-italia/angular-libs/ng-ui-components'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { NgFormComposerModule } from '@helvetia-italia/angular-libs/ng-form-composer'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { ZeroImprevistiComponent } from './zero-imprevisti.component'
import { ZeroImprevistiSMComponent } from './sm/zero-imprevisti-sm.component'
import { registerLocaleData } from '@angular/common'
import localeIt from '@angular/common/locales/it'
registerLocaleData(localeIt)

const material_modules = [
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatDividerModule,
  MatCheckboxModule,
  MatCardModule,
  MatListModule,
  MatProgressBarModule,
  MatProgressSpinnerModule
]
const helvetia_modules = [
  UxNgVerticalSpacerModule,
  UxNgHeaderModule,
  UxNgHorizontalShadowModule,
  UxNgFileuploadModule,
  UxNgSpinnerModule,
  UxNgWaitDialogModule,
  UxNgVerticalSpacerModule,
  UxNgEnvironmentIndicatorModule,
  UxNgFooterModule,
  UxNgIconModule,
  UxNgAlertModule
]
const helvetia_nx_modules = [
  TableDefaultModule,
  SelectModule,
  ProductsListModule,
  AnagraficaCodiceFiscaleModule,
  RecapitoUtenteModule,
  InputWithLabelModule,
  CheckboxModule,
  RadioGroupModule,
  DynamicStepperModule,
  DatiVeicoloModule,
  DateInputComponentModule,
  RadioGroupModule,
  RadioTextGroupModule,
  NgFormComposerModule,
  BoxInputModule,
  FooterComponentsModule,
  SummaryQuestionarioModule,
  FooterComponentsModule,
  DrawerModule,
  PipesModule
]

@NgModule({
  declarations: [ZeroImprevistiComponent, ZeroImprevistiSMComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    ...helvetia_modules,
    ...helvetia_nx_modules,
    ...material_modules
    /** 
    RouterModule.forRoot(
      [ {
          path: 'zero-imprevisti-sm',
          component: ZeroImprevistiSMComponent,
          canActivate: [AgentsGuard]
        }
      ],
      { initialNavigation: 'enabled' }
    )*/
  ],
  providers: [UserService],
  exports: [ZeroImprevistiComponent]
})
export class ZeroImprevistiModule {}
