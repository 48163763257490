import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Observable, of } from 'rxjs'
import { User, UserState, LoggedInAs, UserType } from '../../interfaces/user.models'

export interface UserServiceEnvironment {
  sendEmailVerificationUrl: string
  checkEmailVerificationUrl: string
  sendOtpVerificationUrl: string
  checkOtpVerificationUrl: string
  registerUrl: string
  signUpLoginUrl: string
  updateUrl: string
  forgotPasswordUrl: string
  changePasswordUrl: string
  uploadToMediaStorageUrl: string
  uploadMediaIdUrl: string
  uploadMediaIdUrlProfile: string
  userDataUrl: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, @Inject(EnvironmentServiceToken) private env: UserServiceEnvironment) {}

  loggedInAs(): LoggedInAs | undefined {
    const userState = this.getLocalUserState()
    if (userState && userState.loggedInAs) {
      return userState.loggedInAs
    } else {
      return undefined
    }
  }

  createUserState(loginState: any, origin: string): string {
    const userState: UserState = {}
    const user = loginState.userData
    // // costruisco userData con tutte le proprietà di dataUser
    // Object.entries(user).forEach(([key, value]) => {
    //   if (key === 'telephone') {
    //     Object.assign(userData, { phone_number: value })
    //   } else if (!['cap', 'mobile_phone_number', 'business_name'].includes(key)) {
    //     Object.assign(userData, { [key]: value })
    //   }
    //   Object.assign(userData, { userId: loginState.user_id })
    // })
    // let userState: UserState = {}
    // if (sessionStorage.getItem('userState')) {
    //   userState = JSON.parse(sessionStorage.getItem('userState') as string)
    // }
    const userData: LoggedInAs = {
      city: user.city,
      clientId: user.clientId,
      dateOfBirth: user.dateOfBirth,
      district: user.district,
      documents: user.documents,
      email: user.email,
      fiscalCode: user.fiscalCode,
      gender: user.gender,
      name: user.name,
      phone_number: user.telephone,
      phoneNumber: user.telephone,
      placeOfBirth: user.placeOfBirth,
      postalCode: user.postalCode,
      surname: user.surname,
      userId: loginState.user_id,
      origin: origin,
      privacy_consent: user.privacy_consent,
      profiling_privacy: user.profiling_privacy,
      service_privacy: user.service_privacy,
      marketing_privacy: user.marketing_privacy,
      username: user.email,

      zip: user.postalCode,
      fea_enabled: user.fea_enabled,
      cb_user_id: loginState.user_id
    }
    if (user.address) {
      userData.address = user.address
      if (user.street_number) userData.address = userData.address?.concat(`, ${user.street_number}`)
    } else {
      userData.address = null
    }

    if (user.agreement) {
      origin = 'selly4affinity'
    }

    switch (origin) {
      case 'selly4customer':
        userState.type = UserType.CUSTOMER
        break
      case 'selly4affinity':
        userState.type = UserType.AFFINITY
        break
    }
    userState.loggedInAs = userData
    userState.loginToken = loginState.login_token
    userState.productNode = '09000'
    userState.logged = true
    sessionStorage.setItem('userState', JSON.stringify(userState))
    return origin
  }

  /**
   *
   */
  getLocalUserState(): UserState | undefined {
    const userState = sessionStorage.getItem('userState')
    /* istanbul ignore next */
    if (userState) {
      return JSON.parse(userState)
    } else return undefined
  }

  /**
   * @param userState
   */
  setLocalUserState(userState: UserState) {
    sessionStorage.setItem('userState', JSON.stringify(userState))
  }

  /**
   *
   */
  deleteLocalUserState(): void {
    sessionStorage.removeItem('userState')
  }

  /**
   *
   * @param user
   */
  setPartialUser(user: User): void {
    localStorage.setItem('partialUser', JSON.stringify(user))
  }

  /**
   *
   */
  getPartialUser(): User | undefined {
    const partialUser = localStorage.getItem('partialUser')
    if (partialUser) {
      return JSON.parse(partialUser)
    } else return undefined
  }

  /**
   *
   */
  deletePartialUser() {
    localStorage.removeItem('partialUser')
  }

  /**
   *
   * @param token
   */
  setRegistrationSessionToken(token: string): void {
    localStorage.setItem('RegistrationToken', token)
  }

  /**
   *
   * @returns
   */
  getRegistrationSessionToken(): string | null | undefined {
    const token = localStorage.getItem('RegistrationToken')
    if (token) {
      return token
    } else return undefined
  }

  /**
   *
   */
  deleteRegistrationSessionToken() {
    localStorage.removeItem('RegistrationToken')
  }

  /**
   *
   */
  getLoginToken(): string | undefined {
    const userState = this.getLocalUserState()
    if (!userState) return undefined
    const token = userState.loginToken
    /* istanbul ignore next */
    if (token) {
      return token
    } else return undefined
  }

  /**
   *
   * @param user
   * @param token
   * @returns
   */
  sendEmailVerificationToken(user: User, token: string): Observable<any> {
    return this.http.post(this.env.sendEmailVerificationUrl, {
      userData: user.userData,
      email: user.userData?.email,
      token: token,
      origin: user.userData?.origin
    })
  }

  /**
   *
   * @param otp
   * @param token
   * @returns
   */
  checkEmailVerificationToken(otp: string, token: string): Observable<{ userData: any; valid: boolean }> {
    return this.http.post<any>(this.env.checkEmailVerificationUrl, { otp: otp, token: token })
  }

  /**
   *
   * @param telephone
   * @returns
   */
  sendOTPVerificationToken(telephone: string): Observable<{ message: string; token: string }> {
    return this.http.post<any>(this.env.sendOtpVerificationUrl, { to: telephone })
  }

  /**
   *
   * @param otp
   * @param token
   * @returns
   */
  checkOTPVerificationToken(otp: string, token: string): Observable<{ valid: true }> {
    return this.http.post<any>(this.env.checkOtpVerificationUrl, { otp: otp, token: token })
  }

  // /**
  //  *
  //  * @param files
  //  * @returns
  //  */
  // uploadDocument(files: any): Observable<{ uploadedDocs: boolean }> {
  //   return of({ uploadedDocs: true })
  // }

  /**
   *
   * @param user
   * @returns
   */
  register(user: User): Observable<any> {
    return this.http.post<any>(this.env.registerUrl, {
      password: user.password,
      username: user.userData?.email,
      token: this.getRegistrationSessionToken(),
      userData: user.userData
    })
  }

  /**
   *
   * @param username
   * @param password
   * @param origin
   * @returns
   */
  login(username: string, password: string, origin: string) {
    return this.http.post<any>(this.env.signUpLoginUrl, {
      password: password,
      username: username,
      origin: origin
    })
  }

  /**
   *
   * @param user
   * @returns
   */
  update(user: User) {
    return this.http.put<any>(this.env.updateUrl, user)
  }

  /**
   *
   * @param email
   * @returns
   */
  forgotPassword(email: string, origin: string): Observable<any> {
    return this.http.post<any>(this.env.forgotPasswordUrl, {
      email: email,
      origin: origin
    })
  }

  /**
   *
   * @param email
   * @param token
   * @param password
   * @returns
   */
  updatePassword(email: string, token: string, password: string, origin: string): Observable<any> {
    return this.http.post<any>(this.env.forgotPasswordUrl, {
      email: email,
      token: token,
      password: password,
      origin: origin
    })
  }

  /**
   *
   * @param username
   * @param oldPassword
   * @param password
   * @returns
   */
  changeOldPassword(username: string, oldPassword: string, password: string): Observable<any> {
    return this.http.post<any>(this.env.changePasswordUrl, {
      username: username,
      oldPassword: oldPassword,
      password: password
    })
  }

  /**
   *
   * @param formData
   * @param mimeType
   * @returns
   */
  uploadDocumentToMediaStorage(formData: any, mimeType: string): Observable<any> {
    /* istanbul ignore next line */
    return this.http.post<any>(`${this.env.uploadToMediaStorageUrl}`, formData)
  }

  /**
   *
   * @param documentPayload
   * @returns
   */
  uploadDocumentMediaId(documentPayload: {
    userId: string
    owner: string
    mediaId: string
    documentType: string
  }): Observable<any> {
    return this.http.post<any>(this.env.uploadMediaIdUrl, documentPayload)
  }

  /**
   *
   * @param documentPayload
   * @returns
   */
  uploadDocumentMediaIdProfile(documentPayload: {
    userId: string
    owner: string
    mediaId: string
    documentType: string
  }): Observable<any> {
    console.log('called')
    return this.http.post<any>(this.env.uploadMediaIdUrlProfile, documentPayload)
  }

  getUserData(): Observable<User> {
    return this.http.get<any>(this.env.userDataUrl)
  }

  anonymousLogin() {
    window.location.href = 'http://localhost:4000/auth/4Customer'
  }
}
