import { Injectable } from '@angular/core'
import { StepperController } from '@helvetia-italia/angular-libs/ng-shared'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DynamicStepperService {
  action: BehaviorSubject<StepperController> = new BehaviorSubject<StepperController>({})
}
