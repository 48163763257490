import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RecapitoUtenteComponent } from './recapito-utente.component'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
import { GoogleMapsAutocompleterModule } from '../../inputs/google-maps-autocompleter/google-maps-autocompleter.module'
import { InputWithLabelModule } from '../../inputs/input-with-label/input-with-label.component.module'
@NgModule({
  declarations: [RecapitoUtenteComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    GoogleMapsAutocompleterModule,
    InputWithLabelModule
  ],
  exports: [RecapitoUtenteComponent]
})
export class RecapitoUtenteModule {}
