import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { AuthenticationService } from '@helvetia-italia/angular-libs/ng-shared'
import { ModalWithLabelComponent } from '../../modal-components/modal-with-label/modal-with-label.component'

@Component({
  selector: 'helvetia-italia-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup
  constructor(private builder: FormBuilder, private auth: AuthenticationService, public dialog: MatDialog) {
    this.form = this.builder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit(): void {}

  sendChangePasswordLink() {
    if (this.form && this.form.valid) {
      this.auth.sendChangePasswordLink(this.form.get('email')?.value).subscribe((linkHasBeenSent: any | null) => {
        if (linkHasBeenSent.sent) {
          this.dialog.open(ModalWithLabelComponent, {
            data: { title: "<p><b>Un link per il cambio password è stato inviato all' indirizzo specificato</b></p>" }
          })
        }
      })
    }
  }
}
