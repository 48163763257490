import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { NgFormComposerModule } from '@helvetia-italia/angular-libs/ng-form-composer'
import { UxNgSpinnerModule, UxNgTelInputModule, UxNgVerticalSpacerModule, UxNgWaitDialogModule } from '@helvetiait/ux-ng-material-library'

import { InputWithLabelModule, SelectModule } from '../inputs'
import { InvalidQuestionComponent } from './invalid-survey-modal/invalid-question/invalid-question.component'
import { InvalidSurveyModalComponent } from './invalid-survey-modal/invalid-survey-modal.component'
import { ModalDettaglioRichiestaComponent } from './modal-dettaglio-richiesta/modal-dettaglio-richiesta.component'
import { ModalFooterComponent } from './modal-footer/modal-footer.component'
import { ModalNuovaRichiestaComponent } from './modal-nuova-richiesta/modal-nuova-richiesta.component'
import { ModalTelephoneOtpComponent } from './modal-telephone-otp/modal-telephone-otp.component'
import { ModalWithLabelComponent } from './modal-with-label/modal-with-label.component'
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component'

const material_modules = [MatDialogModule, MatIconModule, MatButtonModule, MatDividerModule, MatFormFieldModule]

@NgModule({
  declarations: [
    ModalWithLabelComponent,
    InvalidSurveyModalComponent,
    InvalidQuestionComponent,
    ModalFooterComponent,
    ModalTelephoneOtpComponent,
    ModalNuovaRichiestaComponent,
    ModalDettaglioRichiestaComponent,
    ModalConfirmComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    InputWithLabelModule,
    FormsModule,
    ReactiveFormsModule,
    ...material_modules,
    UxNgSpinnerModule,
    UxNgTelInputModule,
    UxNgWaitDialogModule,
    UxNgVerticalSpacerModule,
    NgFormComposerModule,
    SelectModule
  ],
  entryComponents: [
    ModalWithLabelComponent,
    InvalidSurveyModalComponent,
    ModalTelephoneOtpComponent,
    ModalNuovaRichiestaComponent,
    ModalConfirmComponent
  ],
  exports: [
    ModalWithLabelComponent,
    InvalidQuestionComponent,
    ModalFooterComponent,
    ModalTelephoneOtpComponent,
    ModalNuovaRichiestaComponent,
    ModalDettaglioRichiestaComponent,
    ModalConfirmComponent
  ]
})
export class ModalComponentsModule {}
