import { Component, Injector, Input } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends ControlValueAccessorBase implements ControlValueAccessor {
  @Input()
  placeholder?: string

  @Input()
  type = 'text'

  @Input()
  showCancel = true

  @Input()
  autocomplete!: string

  @Input()
  rows = 1

  constructor(public injector: Injector, private controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }
}
