export enum HeaderTemplate {
  Default = 'default',
  HomePage = 'homePage'
}

export interface Header {
  template?: HeaderTemplate
  title?: string
  backButton?: boolean
  backButtonText?: string
  backButtonLink?: string
  icon?: string
}
