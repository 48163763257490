export * from './anagrafica-codice-fiscale/index'
export * from './recapito-utente/index'
export * from './change-password/change-password.module'
export * from './consensi-utente/consensi-utente.module'
export * from './consensi-utente-web/consensi-utente-web.module'
export * from './credenziali-utente/credenziali-utente.module'
export * from './forgot-password/forgot-password.module'
export * from './form-login/form-login.component.module'
export * from './change-old-password/change-old-password.module'
export * from './indirizzo-utente/indirizzo-utente.module'
