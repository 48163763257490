import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

export interface PolizzaServiceEnvironment {
  polizzeUrl: string
  terminationDateUrl: string
}

@Injectable({
  providedIn: 'root'
})
export class PolizzaService {
  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private environmentService: PolizzaServiceEnvironment
  ) {}

  getList(queryParams: string = ''): Observable<any> {
    return this.http.get(`${this.environmentService.polizzeUrl}${queryParams}`)
  }

  getDetail(policyNumber: string): Observable<any> {
    return this.http
      .get(`${this.environmentService.polizzeUrl}?policyNumber=${policyNumber}`)
      .pipe(map((polizze: any) => polizze[0]))
  }

  getTerminationDate(policyNumber: string): Observable<any> {
    return this.http.get(`${this.environmentService.terminationDateUrl}/${policyNumber}`)
  }
}
