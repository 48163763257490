export interface InsuranceProduct {
  disclaimer?: string
  helvetiaLink: string
  maxDuration: number
  maxiumCoverageAge: number
  greetings: string
  reasonWhy: Array<string>
  productType: InsuranceProductType
  type: InsuranceProductDuration
  iconName: string
  weight: number
  code: string
  name: string
  description: string
  longDescription: string
  status: boolean
  date_from: number
  date_to: number | undefined
  flayers?: any[]
}
export enum InsuranceProductType {
  PRODUCT = 'PRODUCT',
  APPENDAGE = 'APPENDAGE',
  PRE_SELLING = 'PRE-SELLING'
}

export enum InsuranceProductDuration {
  TEMPORARY = 'TEMPORARY',
  ANNUAL = 'ANNUAL'
}
