import { BreakpointObserver } from '@angular/cdk/layout'
import { STEPPER_GLOBAL_OPTIONS, StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Type,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatStepper } from '@angular/material/stepper'
import { Step, StepperComponent, StepperConfig } from '@helvetia-italia/angular-libs/ng-shared'
import { Observable } from 'rxjs'

import { DynamicStepperService } from './dynamic-stepper-service/dynamic-stepper.service'
import { DynamicStepperDirective } from './dynamic-stepper-directive/dynamic-stepper.directive'

@Component({
  selector: 'helvetia-italia-dynamic-stepper',
  templateUrl: './dynamic-stepper.component.html',
  styleUrls: ['./dynamic-stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class DynamicStepperComponent implements OnInit, AfterViewInit {
  @Input() config: StepperConfig = {}
  @ViewChildren(DynamicStepperDirective) dynamicStepperHosts!: QueryList<DynamicStepperDirective>
  @ViewChild('stepper') stepper!: MatStepper
  @Output()
  stepChanged: EventEmitter<StepperSelectionEvent> = new EventEmitter<StepperSelectionEvent>()

  stepperOrientation!: Observable<StepperOrientation> // not used yet (Angular Material version incompatibility)

  get formValue(): any {
    let value = {}
    if (this.config && this.config.steps && this.config.steps.length > 0) {
      this.config.steps.map((step) => (value = { ...value, ...step.component.form.value }))
      return value
    } else {
      return {}
    }
  }

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    public cdr: ChangeDetectorRef,
    public controller: DynamicStepperService,
    breakpointObserver: BreakpointObserver
  ) {
    // this.stepperOrientation = breakpointObserver
    //   .observe('(min-width: 800px)')
    //   .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')))
  }

  ngOnInit() {
    this.orderSteps()
  }

  // TODO

  ngAfterViewInit() {
    if (Object.keys(this.config).length > 0) {
      const steps = this.config.steps
      if (steps?.length == 0) {
        throw new Error('No steps provided!')
      } else {
        this.loadComponent(steps as Step[])
        this.enableStepperController()
      }
    } else {
      throw new Error('No config provided!')
    }
  }

  // TODO

  loadComponent(steps: Step[]) {
    this.dynamicStepperHosts.forEach((host, index) => {
      const step = steps.find((step) => step.order === index)

      if (!step?.component.component) {
        throw new Error(
          `Provided invalid component at index ${index}. ${step?.component.component} is not a valid ComponentRef`
        )
      }
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        step?.component?.component as Type<any>
      )
      const viewContainerRef = host.viewContainerRef
      viewContainerRef.clear()
      const componentRef = viewContainerRef.createComponent<StepperComponent>(componentFactory)
      componentRef.instance.form = step?.component?.form as FormGroup
      this.cdr.detectChanges()
    })
  }

  selectionChange(event: StepperSelectionEvent) {
    this.stepChanged.emit(event)
  }

  enableStepperController() {
    this.controller.action.subscribe((ctrl) => {
      if (ctrl && ctrl.action && this.config.id === ctrl.id) {
        switch (ctrl.action) {
          case 'next':
            this.stepper.next()
            break
          case 'previous':
            this.stepper.previous()
            break
          case 'reset':
            this.stepper.reset()
            break
        }
      }
    })
  }
  // TODO

  orderSteps() {
    if (this.config && this.config.steps && this.config.steps.length > 0) {
      let steps = { ...this.config.steps }
      steps = this.config.steps.sort((a, b) => {
        return a.order - b.order
      })
      steps = steps.map((step, index) => {
        step.order = index
        return step
      })
      this.config.steps = steps || []
    } else {
      this.config = {}
    }
  }
}
