import { MapsAPILoader } from '@agm/core'
import { Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-google-maps-autocompleter',
  templateUrl: './google-maps-autocompleter.component.html',
  styleUrls: ['./google-maps-autocompleter.component.scss']
})
export class GoogleMapsAutocompleterComponent extends ControlValueAccessorBase implements ControlValueAccessor, OnInit {
  @Input() placeholder = ''

  @Input() type: '(cities)' | '(regions)' | 'address' | 'establishment' | 'geocode' = '(cities)'

  @Input() country = 'it'

  @Output() placeHasChanged: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter()
  @ViewChild('search')
  public searchElementRef!: ElementRef
  public autocomplete!: google.maps.places.Autocomplete

  constructor(
    public injector: Injector,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private controlErrorsMessageService: ControlErrorsMessageService
  ) {
    super(injector)
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: [this.type],
        componentRestrictions: {
          country: this.country
        }
      })
      // istanbul ignore next
      this.autocomplete.addListener('place_changed', () => {
        // TODO

        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = this.autocomplete.getPlace()
          this._control.control?.setValue(place.name)
          this.placeHasChanged.emit(place)
        })
      })
    })
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }
}
