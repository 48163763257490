import { Injectable } from '@angular/core'
import { FormComponent, FormViewComponentAttributes, FormViewComponentInputs } from '../model/models'
import map from 'lodash/map'

const AUTO_CLOSE_TAG_LIST = ['input']

/*
@Injectable({
  providedIn: NgFormComposerModule
})
*/
@Injectable()
export class FormComposerHtmlCompilerService {
  /**
   * This method generate HTML required to put a FormComponent in the page.
   * @param formComponent formComponent we need to render in page
   * @returns the string of HTML
   */
  createHtml(formComponent: FormComponent): string {
    /* istanbul ignore next line */
    if (formComponent.htmlContent) {
      /* istanbul ignore next line */

      return formComponent.view.component
    }
    const openTag = this.createOpenTag(formComponent)
    const contentTag = this.createContentTag()
    const closeTag = this.createCloseTag(formComponent)

    let html = `${openTag}${contentTag}${closeTag}`
    /* istanbul ignore next line */
    if (formComponent.view.wrapped) {
      /* istanbul ignore next line */
      html = `<mat-form-field ${this.attributesToString(
        formComponent.view.attributes
      )}>${openTag}${contentTag}${closeTag}</mat-form-field>`
    } else if (formComponent.parent) {
      html = `<ng-container formGroupName="${formComponent.parent}">
      ${html}
       </ng-container>`
    }
    return html
  }

  /**
   * This method convert the hashMap FormViewComponentAttributes in a string to inject in the HTML
   * to create the component in the format
   *
   * attributeName=value
   *
   * for example:
   * {
   *  attribute1: "value1",
   *  attribute2: "value12"
   * }
   * becomes:
   *
   * attribute1="value1" attribute2="value2"
   *
   * @param attributes HashMap of attribute to inject in html
   * @returns string of attributes in format name=value
   */
  private attributesToString(attributes: FormViewComponentAttributes) {
    return map(attributes, (entry, name) => {
      return `${name}="${entry}"`
    }).join(' ')
  }

  /**
   * This method convert the hashMap FormViewComponentInputs in a string to inject in the HTML
   * to create the component in the format
   *
   * inputName=value
   *
   * for example:
   * {
   *  input1: "value1",
   *  input2: "value12"
   * }
   * becomes:
   *
   * attribute1="value1" attribute2="value2"
   *
   * @param inputs HashMap of input to inject in html
   * @returns string of inputs in format name=value
   */
  // TODO
  /* istanbul ignore next function */
  private inputsToString(inputs: FormViewComponentInputs) {
    return map(inputs, (entry, name) => {
      return `[${name}]='${JSON.stringify(entry)}'`
    }).join(' ')
  }

  /**
   * Create an open tag got html from a component, it use an array AUTO_CLOSE_TAG_LIST to handle cases when a tag is a
   * self closing tag or not.
   * For example:
   * input -> <input/>
   * p -> <p></p>
   * @param formComponent the component to render
   * @returns the string of open tag for the formComponent
   */
  private createOpenTag(formComponent: FormComponent) {
    const componentTag = formComponent.view.component
    const attributes = this.attributesToString(formComponent.view.attributes)
    const inputs = this.inputsToString(formComponent.view.inputs)
    /* istanbul ignore next line */
    const closeTag = AUTO_CLOSE_TAG_LIST.includes(formComponent.view.component) ? '/>' : '>'
    if (!formComponent.htmlContent) {
      /* istanbul ignore next line */
      return `<${componentTag} ${inputs} ${!formComponent.view.wrapped ? attributes : null}  formControlName="${
        formComponent.name
      }" ${closeTag}`
    } else if (formComponent.htmlContent) {
      /* istanbul ignore next line */
      return `${componentTag}`
    }
    throw 'FormComposerHtmlCompilerService no tag returned'
  }

  /**
   * Create a close tag got html from a component, it use an array AUTO_CLOSE_TAG_LIST to handle cases when a tag is a
   * self closing tag or not.
   * For example:
   * input -> n/a
   * p -> </p>
   * @param formComponent the component to render
   * @returns the string of open tag for the formComponent
   */
  private createCloseTag(formComponent: FormComponent) {
    const componentTag = formComponent.view.component
    const closeTag = AUTO_CLOSE_TAG_LIST.includes(componentTag) ? '' : `</${componentTag}>`

    return `${closeTag}`
  }

  private createContentTag() {
    return ''
  }
}
