import { Product } from '@helvetia-italia/angular-libs/ng-shared'

export const MOCK_PRODUCT_LIST: Product[] = [
  {
    code: 'Vehicle',
    title: 'Autovettura (uso privato)',
    description: 'Testo descrittivo del box, su una linea oppure su due',
    iconName: 'car',
    company: 'HI'
  },
  {
    code: 'Motorbike',
    title: 'Moto',
    description: 'Testo descrittivo del box, su una linea oppure su due',
    iconName: 'motorbike',
    company: 'HI'
  },
  {
    code: 'Bike',
    title: 'Ciclomotore',
    description: 'Testo descrittivo del box, su una linea oppure su due',
    iconName: 'bike',
    company: 'HI'
  },
  {
    code: 'PROVA',
    title: 'Prodotto HR',
    description: 'Testo descrittivo del box, su una linea oppure su due',
    iconName: '',
    company: 'HR'
  },
  {
    code: 'PROVA',
    title: 'Prodotto HV',
    description: 'Testo descrittivo del box, su una linea oppure su due',
    iconName: '',
    company: 'HV'
  }
]
