import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms'
import { EnvironmentServiceToken, EnvironmentService } from '@helvetia-italia/angular-libs/environment'
import { DynamicForm, FormComposerLoaderService, FormRow } from '@helvetia-italia/angular-libs/ng-form-composer'
import { CommonValidators, Consent, RegistrationFormService } from '@helvetia-italia/angular-libs/ng-shared'
import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
import { of, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { CheckboxModule, ControlErrorsMessageService, GroupValueAccessorBase, RadioGroupModule } from '../../inputs'

@Component({
  selector: 'helvetia-italia-consensi-utente-web',
  templateUrl: './consensi-utente-web.component.html',
  styleUrls: ['./consensi-utente-web.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConsensiUtenteWebComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ConsensiUtenteWebComponent,
      multi: true
    }
  ]
})
export class ConsensiUtenteWebComponent extends GroupValueAccessorBase implements OnInit {
  public subscriptions: Subscription[] = []
  consents: Consent[] = []
  //@ts-ignore
  @Input() company = this.environmentService.defaultConsentsCellectionPoint
  @Input() email!: string
  @Input() requiredFormPlaceholder = 'Si prega di esprimere una preferenza per ogni consenso'

  config!: DynamicForm | null
  form: FormGroup = new FormGroup({})

  requiredMessage = false

  constructor(
    public registrationForm: RegistrationFormService,
    // private loader: FormComposerLoaderService,
    public spinner: UxNgSpinnerService,
    private builder: FormBuilder,
    public controlErrorMessageService: ControlErrorsMessageService,

    private cdr: ChangeDetectorRef,
    public commonValidators: CommonValidators,
    @Inject(EnvironmentServiceToken) private environmentService: EnvironmentService
  ) {
    super(cdr)
    // this.loader.add(CheckboxModule)
    // this.loader.add(RadioGroupModule)
  }

  ngOnInit(): void {
    this.initConsents().subscribe(
      (res) => {
        this.spinner.stop()
      },
      (err) => {
        this.spinner.stop()
        // this.initError.emit({ error: true })
      }
    )
  }
  // istanbul ignore next
  initConsents() {
    this.spinner.start()
    this.form.reset()
    this.config = null
    return this.getConsents().pipe(
      switchMap((consents) => {
        if (consents && consents.length > 0) {
          consents.forEach((consent) => {
            this.form.addControl(
              consent.name,
              this.builder.control(
                '',
                consent.mappedValidators?.map((validator) => this.commonValidators.getValidator(validator))
              )
            )
            this.form.updateValueAndValidity()
            this.consents.push(consent)
          })
        }
        // istanbul ignore next line
        return of([])
      }),
      switchMap((consentStatus) => {
        this.form.statusChanges.subscribe((status) => {
          status === 'INVALID' ? (this.requiredMessage = true) : (this.requiredMessage = false)
          // istanbul ignore next line
          // this.changeConsentStatus.emit(status)
        })
        this.form.updateValueAndValidity()
        if (this.email) {
          return this.getStoredConsents()
        }
        return of({ initialized: true })
      })
    )
  }
  // istanbul ignore next
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.email &&
      changes.email.currentValue &&
      this.form &&
      Object.keys(this.form.controls).length > 0
    ) {
      this.getStoredConsents().subscribe(
        (res) => {
          this.spinner.stop()
        },
        (err) => {
          this.spinner.stop()
          // this.initError.emit({ error: true })
        }
      )
    }
  }
  // istanbul ignore next
  getConsents() {
    this.spinner.start()
    return this.registrationForm.getConsents(this.company)
  }
  // istanbul ignore next
  getStoredConsents() {
    this.spinner.start()
    return this.registrationForm.getStoredConsents(this.email).pipe(
      switchMap((storedConsents) => {
        if (storedConsents && storedConsents.consents) {
          storedConsents.consents.map((consent: Consent) => {
            if (consent && consent.id) {
              this.form.get(consent.id)?.setValue(consent.value)
            }
          })

          // istanbul ignore next line
        }
        return of({ storedConsentsRetrieved: true })
      })
    )
  }

  // istanbul ignore next
  validate() {
    return this.form.valid ? null : { consentsNotValid: true }
  }
}
