import { Component, EventEmitter, Injector, Input, Output } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { ThemePalette } from '@angular/material/core'
import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends ControlValueAccessorBase implements ControlValueAccessor {
  @Input()
  label?: string
  @Input()
  labelPosition: 'after' | 'before' = 'after'
  @Input()
  color: ThemePalette
  @Output()
  elementChecked: EventEmitter<any> = new EventEmitter()

  constructor(public injector: Injector, private controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  onElementChecked(value: any) {
    this.elementChecked.emit(value)
  }
}
