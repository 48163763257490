import {
  AfterViewChecked,
  ChangeDetectorRef,
  Directive,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'

/**
 * Generic integration of ControlValueAccessor for custom form components
 */

@Directive()
export abstract class GroupValueAccessorBase implements AfterViewChecked {
  @Input() disabledControl = false
  constructor(public cdRef: ChangeDetectorRef) {}
  form!: FormGroup
  subscriptions: Subscription[] = []
  get value(): any {
    return this.form.getRawValue()
  }

  set value(value: any) {
    this.form.patchValue(value)
    this.onChange(value)
    this.onTouched()
  }

  // Control Value Accessor Implementation
  onChange: any = () => {}
  onTouched: any = () => {}
  onValidatorChange: any = () => {}

  ngAfterViewChecked(): void {
    // //@ts-ignore
    // this.subscriptions.push(
    //   this.form.valueChanges.subscribe((value) => {
    //     this.onChange(value)
    //     this.onTouched()
    //   })
    // )
    this.cdRef.detectChanges()
  }

  registerOnChange(fn: any) {
    this.form.valueChanges.subscribe(fn)
  }

  writeValue(value: any) {
    if (value) {
      this.value = value
    }

    if (value === null) {
      this.form.reset()
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn
  }

  registerOnValidatorChange(fn: any) {
    this.onValidatorChange = fn
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable()
  }
}
