import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'helvetia-italia-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
