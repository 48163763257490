import { Inject, Injectable } from '@angular/core'
import { AbstractItemsService } from '../abstract-item.service'
import { Document } from '../../interfaces'

import { Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DocumentsService implements AbstractItemsService<Document> {
  constructor() {}

  getList(): Observable<Document[]> {
    const userState = sessionStorage.getItem('userState')
    /* istanbul ignore next */
    if (userState) {
      const loggedIn = JSON.parse(userState).loggedInAs
      if (loggedIn && loggedIn.documents && loggedIn.documents?.length > 0) {
        return of(loggedIn.documents)
      } else {
        return of([])
      }
    } else {
      return of([])
    }
  }

  findOne(id: string) {}
}
