import { Type } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { CdkStep } from '@angular/cdk/stepper'
export interface StepperConfig {
  id?: string
  steps?: Step[]
  // inherit from MatStepper
  disableRipple?: boolean
  color?: 'primary' | 'accent' | 'warn'
  selected?: CdkStep
  selectedIndex?: number
  linear?: boolean
  labelPosition?: 'bottom' | 'end'
  buttonAlign?: string
  type?: 'horizontal' | 'vertical'
}

export interface Step {
  id?: string
  component: StepperItem
  order: number
  icon?: string
  backBtn?: boolean
  backBtnLabel?: string
  nextBtn?: boolean
  nextBtnLabel?: string
  // inherit from MatStep
  label?: string
  color?: 'primary' | 'accent' | 'warn'
  completed?: boolean
  editable?: boolean
  errorMessage?: string
  hasError?: boolean
  optional?: boolean
  nextButtonFn?: () => void
  backButtonFn?: () => void
}

export interface StepperController {
  id?: string
  action?: 'previous' | 'next' | 'reset'
}
/**
 * Form is used by DynamicStepperComponent to validate each mat-step
 * (In case the component has no need to have a FormGroup, pass an empty one)
 *
/* istanbul ignore next */
export class StepperItem {
  /* istanbul ignore next */
  constructor(public component: Type<any>, public form: FormGroup) {}
}

export interface StepperComponent {
  form: FormGroup
}
