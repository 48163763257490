export interface RecapitoUtente {
  tipo?: 'Domicilio' | 'Residenza'
  address?: string
  city?: string
  postalCode?: string
  district?: string
}

export interface RecapitoOptions {
  country: string
  layoutSchema: RecapitoLayout
}

export interface RecapitoLayout {
  rows: RecapitoRow[]
}

export interface RecapitoRow {
  components: RecapitoComponents[]
}
export interface RecapitoComponents {
  name: RecapitoComponent
  flex: number
  classes?: string
}

export enum RecapitoComponent {
  Indirizzo = 'address',
  Citta = 'city',
  Provincia = 'district',
  Cap = 'postalCode'
}
