import { Component, Input } from '@angular/core'

@Component({
  selector: 'form-element',
  template: ` <ng-container *ngTemplateOutlet="element"></ng-container> `
})
export class FormElementComponent {
  @Input()
  element = ''
}
