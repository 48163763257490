import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout'
import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { ThemePalette } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { ModalWithLabelComponent } from '../../modal-components/modal-with-label/modal-with-label.component'
import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-box-input',
  templateUrl: './box-input.component.html',
  styleUrls: ['./box-input.component.scss']
})
export class BoxInputComponent extends ControlValueAccessorBase implements ControlValueAccessor {
  @Input()
  title?: string

  @Input()
  type!: 'text' | 'select' | 'radio' | 'checkbox'

  @Input()
  placeholder!: string

  @Input()
  icon!: string

  @Input()
  tooltip!: string // html content

  @Input()
  footerValue!: string

  @Input()
  options!: { value: ''; label: '' }[]

  @Input()
  color: ThemePalette = undefined

  @ViewChild('checkbox') checkbox!: any

  value = ''
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall)

  constructor(
    public injector: Injector,
    private controlErrorsMessageService: ControlErrorsMessageService,
    private dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver
  ) {
    super(injector)
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  getClass() {
    if (this.color === undefined) return 'box-input-default'
    switch (this.color) {
      case undefined:
        return 'box-input-default'
      case 'primary':
        return 'box-input-primary'
      case 'accent':
        return 'box-input-accent'
      case 'warn':
        return 'box-input-warn'
    }
  }

  openTooltipModal() {
    const dialog = this.dialog.open(ModalWithLabelComponent, {
      data: { content: this.tooltip },
      width: 'calc(100% - 50px)',
      maxWidth: '100vw'
    })

    const smallDialogSubscription = this.isExtraSmall.subscribe((size) => {
      console.log(size)
      if (size.matches) {
        dialog.updateSize('100vw', '100vh')
      } else {
        dialog.updateSize('50em', '')
      }
    })
  }

  _clickHandler() {
    if (this.type === 'checkbox') {
      this.checkbox.nativeElement.click()
    }
  }
}
