import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DatiVeicoloComponent } from './dati-veicolo.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { InputWithLabelModule, AutocompleterModule } from '../inputs'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ApsaService, PipesModule } from '@helvetia-italia/angular-libs/ng-shared'
import { UxNgSpinnerModule } from '@helvetiait/ux-ng-material-library'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
@NgModule({
  declarations: [DatiVeicoloComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    InputWithLabelModule,
    AutocompleterModule,
    UxNgSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule
  ],
  exports: [DatiVeicoloComponent],
  providers: [ApsaService]
})
export class DatiVeicoloModule {}
