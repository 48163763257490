import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms'
import { ControlErrorsMessageService } from '../../inputs/helpers/control-errors-message.service'
import { Subscription } from 'rxjs'
import { GroupValueAccessorBase } from '../../inputs'

@Component({
  selector: 'helvetia-italia-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormLoginComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FormLoginComponent
    }
  ]
})
export class FormLoginComponent extends GroupValueAccessorBase implements ControlValueAccessor, OnDestroy {
  currentValue?: string
  disabled = false
  @Input() labelClasses: string[] = []
  public subscriptions: Subscription[] = []

  constructor(public controlErrorsMessageService: ControlErrorsMessageService, public cdRef: ChangeDetectorRef) {
    super(cdRef)
    this.form = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  validate() {
    return this.form.valid ? null : { profile: { valid: false } }
  }
}
