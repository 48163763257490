import { Component, Input, OnInit } from '@angular/core'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'helvetia-italia-product-icon-teaser',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() title: string | undefined
  @Input() description: string | undefined
  @Input() textColor: 'primary' | 'secondary' | 'tertiary' | 'white' | 'iron' | 'merlot' | undefined
  @Input() iconName?: string

  constructor() {}

  ngOnInit(): void {}
}
