import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core'
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms'
import { of, Subscription } from 'rxjs'

import { GroupValueAccessorBase } from '../../inputs'

@Component({
  selector: 'helvetia-italia-change-old-password',
  templateUrl: './change-old-password.component.html',
  styleUrls: ['./change-old-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChangeOldPasswordComponent,
      multi: true
    },

    {
      provide: NG_VALIDATORS,
      useExisting: ChangeOldPasswordComponent,
      multi: true
    }
  ]
})
export class ChangeOldPasswordComponent extends GroupValueAccessorBase implements ControlValueAccessor {
  subscriptions: Subscription[] = []

  constructor(private builder: FormBuilder, public cdRef: ChangeDetectorRef) {
    super(cdRef)
    this.form = this.builder.group(
      {
        oldPassword: ['', Validators.required],
        password: ['', Validators.required],
        passwordConfirm: ['', Validators.required]
      },
      { validator: this.passwordValidator }
    )
  }

  passwordValidator(form: FormGroup) {
    const passControl = form.get('password')
    const confirmPassControl = form.get('passwordConfirm')
    let err = null
    if (passControl && confirmPassControl) {
      const pass = passControl.value
      const confirmPass = confirmPassControl.value

      if (
        pass &&
        (pass.length < 8 ||
          !/[!@#$%^&*(),.?'":{}|<>;:°#§]/.test(pass) ||
          !/[A-Z]|[a-z]/.test(pass) ||
          !/[0-9]/.test(pass))
      ) {
        err = err
          ? Object.assign(err, {
              invalidPassword: true
            })
          : {
              invalidPassword: true
            }
      }

      if (pass !== confirmPass) {
        err = err
          ? Object.assign(err, {
              passwordsDoNotMatch: true
            })
          : {
              passwordsDoNotMatch: true
            }
      }
    }
    return err
  }

  //TODO

  validate() {
    return this.form.valid ? null : { passwordNotValid: true }
  }
}
