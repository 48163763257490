import { Component, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'helvetia-italia-icon-expandable-component',
  templateUrl: './icon-expandable-component.component.html',
  styleUrls: ['./icon-expandable-component.component.scss']
})
export class IconExpandableComponentComponent implements OnInit {
  @Output() openDrawer = new EventEmitter()
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  open() {
    this.openDrawer.emit('enter')
  }
}
