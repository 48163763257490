import { APP_BASE_HREF, DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  baseUrl: string

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.baseUrl = document.location.protocol + '//' + document.location.hostname
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    window.location.href = `${this.baseUrl}/${route.data['loginRoute']}`

    return true
  }
}
