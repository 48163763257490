import { Inject, Injectable } from '@angular/core'
import { EnvironmentService, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'

@Injectable({
  providedIn: 'root'
})
export class ControlErrorsMessageService {
  constructor(@Inject(EnvironmentServiceToken) private environmentService: EnvironmentService) {}

  getErrorMessage(errorKey: string) {
    // if (this.env && !this.env.formErrorMessages) throw new Error('Env does not have formErrorMessages')
    const message = this.environmentService.formErrorMessages
      ? this.environmentService.formErrorMessages[errorKey]
      : errorKey
    return message
  }
}
