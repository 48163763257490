import { Inject, Injectable } from '@angular/core'
import { EnvironmentService, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  constructor(@Inject(EnvironmentServiceToken) private environmentService: EnvironmentService) {}

  getMessage(errorCode: string): string {
    const errorMessages = this.environmentService.errorMessages
    return errorMessages[errorCode] || errorCode || 'Attenzione si è verificato un errore non previsto'
  }
}
