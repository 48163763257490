import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ModalInput } from '@helvetia-italia/angular-libs/ng-shared'
/**
 * Modal that displays some dynamic content passed by caller
 * @Input data: ModalInput
 */
@Component({
  selector: 'helvetia-italia-modal-with-label',
  templateUrl: './modal-with-label.component.html',
  styleUrls: ['./modal-with-label.component.scss']
})
export class ModalWithLabelComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalInput,
    public dialogRef: MatDialogRef<ModalWithLabelComponent>
  ) {}

  confirm() {
    this.dialogRef.close({ confirm: true })
  }
}
