import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { DynamicForm, FormComposerLoaderService, FormRow } from '@helvetia-italia/angular-libs/ng-form-composer'
import {
  CommonValidators,
  Consent,
  OnetrustRegisterPayload,
  RegistrationFormService
} from '@helvetia-italia/angular-libs/ng-shared'

import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
import { of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

@Component({
  selector: 'helvetia-italia-web-consents',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent implements OnInit, OnChanges {
  //@ts-ignore
  @Input() company!: string
  @Input() token!: string
  @Input() email
  @Input() requiredFormPlaceholder!: string

  @Input()
  set storeConsentsStatus(state) {
    // istanbul ignore next line
    this.storeConsents()
  }
  @Output() valueChanges: EventEmitter<any> = new EventEmitter()
  @Output() changeConsentStatus: EventEmitter<any> = new EventEmitter()
  @Output() registrationError: EventEmitter<any> = new EventEmitter()
  @Output() registrationSuccess: EventEmitter<any> = new EventEmitter()
  @Output() initError: EventEmitter<any> = new EventEmitter()

  consents: Consent[] = []
  form: FormGroup = new FormGroup({})

  requiredMessage: boolean

  constructor(
    public registrationForm: RegistrationFormService,
    public commonValidators: CommonValidators,
    private loader: FormComposerLoaderService,
    public spinner: UxNgSpinnerService,
    private builder: FormBuilder
  ) {
    // istanbul ignore next
    this.form.valueChanges.subscribe((value) => this.valueChanges.emit(value))
  }

  // istanbul ignore next
  ngOnInit(): void {
    if (this.company) {
      this.initConsents().subscribe(
        (res: any) => {
          if (res.init) {
            this.form.statusChanges.subscribe((status) => {
              status === 'INVALID' ? (this.requiredMessage = true) : (this.requiredMessage = false)
              // istanbul ignore next line
              this.changeConsentStatus.emit(status)
            })
          }
        },
        (err) => {
          this.spinner.stop()
          this.initError.emit({ error: true })
        }
      )
    }
  }

  initConsents() {
    this.spinner.start()
    this.form.reset()
    this.consents = []

    return this.getConsents().pipe(
      map((consents) => {
        if (consents && consents.length > 0) {
          consents.forEach((consent) => {
            this.form.addControl(
              consent.name,
              this.builder.control(
                '',
                consent.mappedValidators?.map((validator) => this.commonValidators.getValidator(validator))
              )
            )
            this.form.updateValueAndValidity()
            this.consents.push(consent)
          })
          setTimeout(() => this.changeConsentStatus.emit(this.form.status))
          this.spinner.stop()
        }
        return { init: true }
      })
    )
  }

  // istanbul ignore next
  ngOnChanges(changes) {
    if (changes && changes.company && changes.company.currentValue) {
      this.initConsents().subscribe(
        (res: any) => {
          if (res.init) {
            this.form.statusChanges.subscribe((status) => {
              status === 'INVALID' ? (this.requiredMessage = true) : (this.requiredMessage = false)
              // istanbul ignore next line
              this.changeConsentStatus.emit(status)
            })
          }
        },
        (err) => {
          this.spinner.stop()
          this.initError.emit({ error: true })
        }
      )
    }
    if (changes && changes.email && changes.email.currentValue) {
      this.getStoredConsents().subscribe(
        (res) => {
          this.spinner.stop()
        },
        (err) => {
          this.spinner.stop()
          this.initError.emit({ error: true })
        }
      )
    }
  }

  getConsents() {
    this.spinner.start()
    return this.registrationForm.getConsents(this.company)
  }
  // istanbul ignore next
  getStoredConsents() {
    this.spinner.start()
    return this.registrationForm.getStoredConsents(this.email).pipe(
      switchMap((storedConsents) => {
        if (storedConsents && storedConsents.consents) {
          storedConsents.consents.map((consent: Consent) => {
            if (consent && consent.id) {
              this.form.get(consent.id)?.setValue(consent.value)
            }
          })

          // istanbul ignore next line
        }
        return of({ storedConsentsRetrieved: true })
      })
    )
  }

  // istanbul ignore next function
  createFormConfig(consents: Consent[]): DynamicForm {
    return <DynamicForm>{
      model: {},
      name: 'web-consents',
      rows: consents.map((consent) => {
        const row = <FormRow>{
          attributes: {},
          components: [
            {
              name: consent.name,
              mappedValidators: consent.mappedValidators,
              view: {
                component: consent.type === 'check' ? 'helvetia-italia-checkbox' : 'helvetia-italia-radio-group',
                attributes: {
                  label: consent.label,
                  hint: consent.hint || '',
                  classes: consent.classes
                  // required: `${consent.required}`
                },
                inputs: {}
              }
            }
          ]
        }

        if (consent.type === 'radio') {
          row.components[0].view.inputs = { options: consent.options }
        }
        return row
      })
    }
  }

  storeConsents() {
    if (this.form && this.form.valid) {
      const consents = this.form.controls
      const payload: OnetrustRegisterPayload = {
        identifier: this.email,
        requestInformation: this.token,
        purposes: Object.keys(consents).map((key) => {
          return {
            Id: key,
            TransactionType: consents[key].value === true ? 'CONFIRMED' : 'NOTGIVEN'
          }
        })
      }
      this.registrationForm.registerConsents(payload).subscribe(
        (res) => this.registrationSuccess.emit({ stored: true }),
        (err) => this.registrationError.emit({ error: true })
      )
    } else {
      this.requiredMessage = true
    }
  }
}
