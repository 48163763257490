import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { AbstractTableComponent } from './abstract/table-default.abstract'
import * as XLSX from 'xlsx'
import { Tooltip } from '@helvetia-italia/angular-libs/ng-shared'

export function HelvetiaPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl()

  customPaginatorIntl.itemsPerPageLabel = 'Risultati per pagina'
  customPaginatorIntl.nextPageLabel = 'Avanti'
  customPaginatorIntl.previousPageLabel = 'Indietro'
  customPaginatorIntl.firstPageLabel = 'Prima pagina'
  customPaginatorIntl.lastPageLabel = 'Ultima pagina'
  customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return '0 di ' + length
    }

    length = Math.max(length, 0)
    const startIndex = page * pageSize
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
    return startIndex + 1 + ' - ' + endIndex + ' di ' + length
  }
  return customPaginatorIntl
}
@Component({
  selector: 'helvetia-italia-table-default',
  templateUrl: './table-default.component.html',
  styleUrls: ['./table-default.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useValue: HelvetiaPaginator() }]
})
export class TableDefaultComponent extends AbstractTableComponent<any> implements AfterViewInit {
  @Input() noResultsMessage = 'Spiacenti, non ci sono risultati da visualizzare'
  @Input() loadingMessage = ''
  @Input() errorMessage = ''
  @Input() sortDirection: 'asc' | 'desc' = 'desc'
  @Input() fileNameExcel = 'table-export-excel'
  @Input() sheetNameExcel = 'Sheet-1'
  @Input() launchTitle = 'Launch'
  @Input() toolTipColumns: Tooltip[] = []

  @Output() rowClick: EventEmitter<any> = new EventEmitter()
  @Output() deleteClick: EventEmitter<any> = new EventEmitter()
  @Output() launchClick: EventEmitter<any> = new EventEmitter()
  @Output() downloadClick: EventEmitter<any> = new EventEmitter()
  @Output() mouseEnterCell: EventEmitter<any> = new EventEmitter()
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('TABLE') table: ElementRef | undefined
  /* istanbul ignore next line */
  @Input() sortingDataAccessor = (item: any, property: string) => {
    if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
    return item[property]
  }
  constructor() {
    super()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor
    this.dataSource.sort = this.sort
  }

  reload() {
    this.initTable()
  }

  onRowClick(row: any) {
    this.rowClick.emit(row)
  }

  onMouseEnterCell(row: any) {
    this.mouseEnterCell.emit(row)
  }

  checkTooltip(colId?: string) {
    if (this.toolTipColumns.find((t) => t.id == colId)) {
      return true
    }
    return false
  }

  showTooltip(row: any, col: any) {
    const tooltip = this.toolTipColumns.find((t) => t.id == col.id)
    if (tooltip && !tooltip.title) return `${row[tooltip.text]}`
    if (tooltip && tooltip.title) return `${tooltip.title} : ${row[tooltip.text]}`
    return `No text provided for tooltip ${col.id}`
  }

  onDeleteClick(row: any) {
    this.deleteClick.emit(row)
  }

  onLaunchClick(row: any) {
    this.launchClick.emit(row)
  }

  onDownloadClick(row: any) {
    this.downloadClick.emit(row)
  }

  ExportTOExcel() {
    if (this.table) {
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement) //converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

      /* save to file */
      XLSX.writeFile(wb, 'SheetJS.xlsx')
    }
  }
}
