import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Product } from '@helvetia-italia/angular-libs/ng-shared'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'helvetia-italia-product-icon-teaser-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {
  @Input() products: Product[] | undefined
  @Input() company: string | undefined
  @Output() productSelected = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}

  goTo(code: string) {
    this.productSelected.emit(code)
  }
}
