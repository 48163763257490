import { AfterViewInit, Component, Injector, Input } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent extends ControlValueAccessorBase implements ControlValueAccessor, AfterViewInit {
  @Input()
  label?: string
  @Input()
  layout?: string = 'column'
  @Input()
  limitColumn = 10
  @Input() options: {
    label: string
    value: string
  }[] = []

  slicedOptions!: {
    label: string
    value: string
  }[][]

  constructor(public injector: Injector, private controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
    // this.slicedOptions = this.generateConsentsColumn(this.limitColumn)
  }

  ngAfterViewInit(): void {
    this.slicedOptions = this.generateConsentsColumn(this.limitColumn)
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  generateConsentsColumn(limit: number): Array<
    {
      label: string
      value: string
    }[]
  > {
    if (this.options && this.options.length < limit) return [this.options]
    if (this.options && this.options.length > limit) {
      const slicedOptions = []
      while (this.options.length) {
        slicedOptions.push(this.options.splice(0, limit))
      }
      return slicedOptions
    }
    return [this.options]
  }
}
