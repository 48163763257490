export enum PurchaseResultStatus {
  // @ts-ignore
  SENT = 'sent',
  // @ts-ignore
  ERROR = 'error'
}

export interface PurchaseResult {
  status: PurchaseResultStatus
  msg?: string
  error?: any
}
