import { Validators } from '@angular/forms'
import { Consent } from '../interfaces/consent'

export const MOCK_REGISTRATION_FORM_CONSENTS: Consent[] = [
  {
    name: 'privacy_consent',
    label: `Dichiaro di aver letto e ricevuto l'Informativa in materia di Privacy prevista dall'art. 13 del Regolamento UE 679/2016 e mi impegno a fornire, in nome e per conto del Titolare e conformemente al testo ricevuto, l'informativa stessa agli eventuali altri interessati di cui abbia comunicato o comunicherò al Titolare i dati personali.`,
    type: 'check',
    validators: [Validators.required],
    hint: 'Per poter sottoscrivere la polizza è necessario acconsentire a quanto indicato nella Dichiarazione presentata sopra',
    classes: 'uxngm-typography--l',
    required: true
  },
  {
    name: 'service_privacy',
    label: `Il consenso al trattamento dei miei dati personali compresi quelli relativi alla salute, per le finalità di cui al punto 2 lettere a) dell'informativa, compresa la loro comunicazione ai soggetti indicati al punto 3 della stessa informativa`,
    type: 'radio',
    options: [
      { value: true, label: 'Do il consenso' },
      { value: false, label: 'Non do il consenso' }
    ],
    validators: [Validators.requiredTrue],
    hint: "Per poter procedere, in caso di sinistro, all'apertura della pratica e alla gestione del sinistro stesso è necessario fornire il consenso sopra indicato",
    classes: 'uxngm-typography--l',
    required: true
  },
  {
    name: 'marketing_privacy',
    label: `Il consenso al trattamento dei propri dati personali, da parte del titolare, per finalità di marketing con strumenti tradizionali e automatizzati, così come descritto al punto 2, lettera b dell'informativa.`,
    type: 'radio',
    options: [
      { value: true, label: 'Do il consenso' },
      { value: false, label: 'Non do il consenso' }
    ],
    validators: [Validators.required],
    classes: 'uxngm-typography--l',
    required: true
  },
  {
    name: 'profiling_privacy',
    label: `Il consenso al trattamento dei propri dati personali, da parte del titolare, per finalità di profilazione connessa al marketing`,
    type: 'radio',
    options: [
      { value: true, label: 'Do il consenso' },
      { value: false, label: 'Non do il consenso' }
    ],
    validators: [Validators.required],
    classes: 'uxngm-typography--l',
    required: true
  }
]
