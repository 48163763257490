import { ApsaQuoteSummary } from '../interfaces'

export const MOCK_LISTA_PREVENTIVI: ApsaQuoteSummary[] = [
  // {
  //   numeroPolizza: "1000",
  //   numeroPreventivo: "1000",
  //   modello: 'Fiat Panda 2018',
  //   targa: 'CF325FR',
  //   premio: '10',
  //   dataPreventivo: '2023-01-01',
  //   dataDecorrenza: '2023-01-01',
  //   giorniDiValidita: "60"
  // },
  // {
  //   preventivo: 'Autovettura',
  //   modello: 'Ford Fiesta 3C',
  //   targa: 'TH378OP',
  //   data: new Date('December 20, 2020 10:00:00'),
  //   stato: StatoPreventivo.AttesaVerifica
  // },
  // {
  //   preventivo: 'Ciclomotore',
  //   modello: 'Ciao Piaggio 1980',
  //   targa: 'LM678IR',
  //   data: new Date('August 27, 2019 08:24:00'),
  //   stato: StatoPreventivo.NonApprovato
  // },
  // {
  //   preventivo: 'Ciclomotore',
  //   modello: 'Ciao Piaggio 1980',
  //   targa: 'LM678IR',
  //   data: new Date('August 21, 2017 08:24:00'),
  //   stato: StatoPreventivo.ConveritoInPolizza
  // },
  // {
  //   preventivo: 'Ciclomotore',
  //   modello: 'Ciao Piaggio 1980',
  //   targa: 'LM678IR',
  //   data: new Date('March 30, 2021 08:24:00'),
  //   stato: StatoPreventivo.DaCompletare
  // }
]
