export function getAgeByFiscalCode(fiscalCode: string) {
  const monthsTable: any = {
    A: '01',
    B: '02',
    C: '03',
    D: '04',
    E: '05',
    H: '06',
    L: '07',
    M: '08',
    P: '09',
    R: '10',
    S: '11',
    T: '12'
  }
  return fiscalCode.replace(/^(?:\w{6})(\d{2})(\w)(\d{2}).+$/, (data, yy, mm, dd) => {
    const year2digit = parseInt(yy, 10)
    const currentYear = parseInt(new Date().getFullYear().toString().substr(-2), 10)
    const century = year2digit <= currentYear ? '20' : '19'
    const year = [century, yy].join('')

    let day = parseInt(dd, 10)
    const gender = day > 31 ? 'F' : 'M'
    if (gender === 'F') day -= 40

    let month = mm.toUpperCase()
    month = monthsTable[month]
    const birthdate = [year, month, day].join('/')
    const birthDate = new Date(birthdate)
    const today = new Date()

    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    // console.log(age);

    return age.toString()
  })
}

export const formatBrokerId = (brokerId: string) => {
  const firstSection = Number(brokerId.split('.')[0])
  const lastSection = brokerId.split('.')[1].toLowerCase()
  return `${firstSection}.${lastSection}`
}
