export interface SummaryConfig {
  alertText?: string
  title?: string
  description?: string
  icon?: string
  totalAnswers?: number
  partialAnswers?: number
  action?: string // action link,
  status?: SummaryStatus
  loadingText?: string
}

export enum SummaryStatus {
  Approved = 'approved',
  Pending = 'pending',
  NotApproved = 'not_approved'
}
