import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RadioTextGroupComponent } from './radio-text-group.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatRippleModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FlexLayoutModule } from '@angular/flex-layout'
import { InputWithLabelModule } from '../input-with-label/input-with-label.component.module'
import { MatRadioModule } from '@angular/material/radio'

@NgModule({
  declarations: [RadioTextGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    FlexLayoutModule,
    InputWithLabelModule
  ],
  exports: [RadioTextGroupComponent]
})
export class RadioTextGroupModule {}
