import { TipoRichiesta } from '../interfaces/user-request'

export const richiestaInformativaProdotto = {
  name: 'Richiesta-informativa-prodotto',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'productCode',
          mappedValidators: ['required'],
          view: {
            component: 'helvetia-italia-select',
            attributes: { fxFlex: '50', 'fxFlex.lt-lg': '100', placeholder: 'Prodotto' },
            inputs: {
              classes: ['uxngm-typography--m']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', fxLayoutGap: '12px', style: 'text-align:left' },
      components: [
        {
          name: 'surname',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Cognome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        },
        {
          name: 'name',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Nome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'email',
          mappedValidators: ['email', 'required'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', 'fxFlex.lt-lg': '100', placeholder: 'Email' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'free_text',
          required: true,
          view: {
            component: 'helvetia-italia-textarea',
            attributes: { fxFlex: '75', 'fxFlex.lt-lg': '100', placeholder: 'Note', rows: 5 },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    }
  ]
}

export const richiestaOperativaPiattaforma = {
  name: 'Richiesta-operativa-piattaforma',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', fxLayoutGap: '12px', style: 'text-align:left' },
      components: [
        {
          name: 'surname',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Cognome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        },
        {
          name: 'name',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Nome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'email',
          mappedValidators: ['email', 'required'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', 'fxFlex.lt-lg': '100', placeholder: 'Email' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'free_text',
          view: {
            component: 'helvetia-italia-textarea',
            attributes: { fxFlex: '75', 'fxFlex.lt-lg': '100', placeholder: 'Note', rows: 5 },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    }
  ]
}

export const richiestaGenerica = {
  name: 'Richiesta-operativa-piattaforma',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', fxLayoutGap: '12px', style: 'text-align:left' },
      components: [
        {
          name: 'surname',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Cognome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        },
        {
          name: 'name',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Nome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'email',
          mappedValidators: ['email', 'required'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', 'fxFlex.lt-lg': '100', placeholder: 'Email' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'policyNumber',
          view: {
            component: 'helvetia-italia-select',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Numero polizza' },
            inputs: {
              classes: ['uxngm-typography--m'],
              options: []
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'free_text',
          view: {
            component: 'helvetia-italia-textarea',
            attributes: { fxFlex: '75', 'fxFlex.lt-lg': '100', placeholder: 'Note', rows: 5 },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    }
  ]
}

export const reInvioDocumentoPolizza = {
  name: 'Richiesta-operativa-piattaforma',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', fxLayoutGap: '12px', style: 'text-align:left' },
      components: [
        {
          name: 'surname',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Cognome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        },
        {
          name: 'name',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Nome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'email',
          mappedValidators: ['email', 'required'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', 'fxFlex.lt-lg': '100', placeholder: 'Email' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'policyNumber',
          mappedValidators: ['required'],
          view: {
            component: 'helvetia-italia-select',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Numero polizza' },
            inputs: {
              classes: ['uxngm-typography--m'],
              options: []
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'free_text',
          view: {
            component: 'helvetia-italia-textarea',
            attributes: { fxFlex: '75', 'fxFlex.lt-lg': '100', placeholder: 'Note', rows: 5 },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    }
  ]
}

export const annulloDisdettaContraente = {
  name: 'Richiesta-operativa-piattaforma',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'policyNumber',
          mappedValidators: ['required'],
          view: {
            component: 'helvetia-italia-select',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Numero polizza' },
            inputs: {
              classes: ['uxngm-typography--m'],
              options: []
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'terminationDate',
          disabled: true,
          view: {
            component: 'helvetia-italia-date-input',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Termine polizza' },
            inputs: {
              classes: ['uxngm-typography--m']
            }
          }
        }
      ]
    }
  ]
}

export const annulloCessazioneRischio = {
  name: 'Richiesta-operativa-piattaforma',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'policyNumber',
          mappedValidators: ['required'],
          view: {
            component: 'helvetia-italia-select',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Numero polizza' },
            inputs: {
              classes: ['uxngm-typography--m'],
              options: []
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'terminationDate',
          disabled: true,
          view: {
            component: 'helvetia-italia-date-input',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Termine polizza' },
            inputs: {
              classes: ['uxngm-typography--m']
            }
          }
        }
      ]
    }
  ]
}

export const annulloDirittoRipensamento = {
  name: 'Richiesta-operativa-piattaforma',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'policyNumber',
          mappedValidators: ['required'],
          view: {
            component: 'helvetia-italia-select',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Numero polizza' },
            inputs: {
              classes: ['uxngm-typography--m'],
              options: []
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', fxLayoutGap: '12px', style: 'text-align:left' },
      components: [
        {
          name: 'IBAN',
          mappedValidators: ['required', 'iban'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'IBAN' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        },
        {
          name: 'bank_account_header',
          mappedValidators: ['required'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Intestazione' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    }
  ]
}

export const annulloRecessoSinistroContraente = {
  name: 'Richiesta-operativa-piattaforma',
  model: {},
  errorsPosition: 'bottom',
  rows: [
    {
      attributes: { fxLayout: 'row', fxLayoutGap: '12px', style: 'text-align:left' },
      components: [
        {
          name: 'surname',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Cognome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        },
        {
          name: 'name',
          disabled: true,
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Nome' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'email',
          mappedValidators: ['email', 'required'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', 'fxFlex.lt-lg': '100', placeholder: 'Email' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'policyNumber',
          view: {
            component: 'helvetia-italia-select',
            attributes: { fxFlex: '70', 'fxFlex.lt-lg': '100', placeholder: 'Numero polizza' },
            inputs: {
              classes: ['uxngm-typography--m'],
              options: []
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', fxLayoutGap: '12px', style: 'text-align:left' },
      components: [
        {
          name: 'IBAN',
          mappedValidators: ['required', 'iban'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'IBAN' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        },
        {
          name: 'bank_account_header',
          mappedValidators: ['required'],
          view: {
            component: 'helvetia-italia-input-with-label',
            attributes: { fxFlex: '50', placeholder: 'Intestazione' },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    },
    {
      attributes: { fxLayout: 'row', style: 'text-align:left' },
      components: [
        {
          name: 'free_text',
          view: {
            component: 'helvetia-italia-textarea',
            attributes: { fxFlex: '75', 'fxFlex.lt-lg': '100', placeholder: 'Note', rows: 5 },
            inputs: {
              classes: ['uxngm-typography--l']
            }
          }
        }
      ]
    }
  ]
}

export const Mock_lista_richieste = [
  {
    dataCreazione: '',
    dataAggiornamento: '',
    // tipo: TipoRichiesta['cancel-by-right-to-reconsider'],
    stato: 'In lavorazione',
    polizza: '0000000000001'
  },
  {
    dataCreazione: '',
    dataAggiornamento: '',
    // tipo: TipoRichiesta['contract-clarification'],
    stato: 'Presa in carico',
    polizza: '0000000000002'
  }
]
