import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms'
import { ControlErrorsMessageService } from '../../inputs/helpers/control-errors-message.service'
import { Subscription } from 'rxjs'
import { GroupValueAccessorBase } from '../../inputs'

@Component({
  selector: 'helvetia-italia-credenziali-utente',
  templateUrl: './credenziali-utente.component.html',
  styleUrls: ['./credenziali-utente.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CredenzialiUtenteComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CredenzialiUtenteComponent,
      multi: true
    }
  ]
})
export class CredenzialiUtenteComponent extends GroupValueAccessorBase implements ControlValueAccessor, OnDestroy {
  public subscriptions: Subscription[] = []

  constructor(
    private builder: FormBuilder,
    public controlErrorsMessageService: ControlErrorsMessageService,
    public cdRef: ChangeDetectorRef
  ) {
    super(cdRef)
    this.form = this.builder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        passwordConfirm: ['', [Validators.required]]
      },
      { validator: this.passwordValidator }
    )
    this.form.get('email')?.disable()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  passwordValidator(form: FormGroup) {
    const passControl = form.get('password')
    const confirmPassControl = form.get('passwordConfirm')
    let err = null
    if (passControl && confirmPassControl) {
      const pass = passControl.value
      const confirmPass = confirmPassControl.value

      if (
        pass &&
        (pass.length < 8 ||
          !/[!@#$%^&*(),.?'":{}|<>;:°#§]/.test(pass) ||
          !/[A-Z]|[a-z]/.test(pass) ||
          !/[0-9]/.test(pass))
      ) {
        err = err
          ? Object.assign(err, {
              invalidPassword: true
            })
          : {
              invalidPassword: true
            }
      }

      // njsscan-ignore: node_timing_attack
      const test = pass !== confirmPass
      if (test) {
        err = err
          ? Object.assign(err, {
              passwordsDoNotMatch: true
            })
          : {
              passwordsDoNotMatch: true
            }
      }
    }
    return err
  }

  validate() {
    return this.form.valid ? null : { credentialsNotValid: true }
  }
}
