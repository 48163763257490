import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ConsensiUtenteComponent } from './consensi-utente.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatFormFieldModule } from '@angular/material/form-field'
import { CheckboxModule } from '../../inputs/checkbox/checkbox.module'
import { RadioGroupModule } from '../../inputs/radio-group/radio-group.module'

@NgModule({
  declarations: [ConsensiUtenteComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    CheckboxModule,
    RadioGroupModule
  ],
  exports: [ConsensiUtenteComponent]
})
export class ConsensiUtenteModule {}
