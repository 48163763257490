import { Component, Injector, Input } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { DateFilterFn } from '@angular/material/datepicker'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-date-range-input',
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.scss']
})
export class DateRangeInputComponent extends ControlValueAccessorBase implements ControlValueAccessor {
  @Input() appearance = 'standard'
  @Input() placeholder = ''
  @Input() startPlaceholder = ''
  @Input() endPlaceholder = ''
  @Input() separator = ''
  @Input() max!: Date
  @Input() min!: Date
  @Input() touchUi = false
  @Input() startAtDate = new Date()
  @Input() startValue!: Date
  @Input() endValue!: Date

  currentValue = {
    start: '',
    end: ''
  }

  @Input() dateFilter: DateFilterFn<Date> = (date) => true

  constructor(public injector: Injector, private controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
  }
  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }
}
