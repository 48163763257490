import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { UserService } from '@helvetia-italia/angular-libs/ng-shared'
import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
import { Observable } from 'rxjs'
import { delay, tap } from 'rxjs/operators'

@Component({
  selector: 'helvetia-italia-modal-telephone-otp',
  templateUrl: './modal-telephone-otp.component.html',
  styleUrls: ['./modal-telephone-otp.component.scss']
})
export class ModalTelephoneOtpComponent {
  form: FormGroup
  telephoneForm: FormGroup
  telephoneFormIsVisible = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public sessionData: { token: string; telephone: string },
    private builder: FormBuilder,
    public dialogRef: MatDialogRef<ModalTelephoneOtpComponent>,
    public userService: UserService,
    public spinner: UxNgSpinnerService,
    public snack: MatSnackBar,
    private router: Router
  ) {
    this.form = this.builder.group({
      otpCode: ['', Validators.required]
    })
    this.telephoneForm = this.builder.group({
      telephone: ['', [Validators.required, this.isValidPhoneNumber]]
    })
  }

  confirm() {
    if (!this.form || !this.form.valid) {
      throw Error("Form either not valid or doesn't exists!")
    }
    this.checkOtp().subscribe(
      (res) => {
        if (res && res.valid) {
          // this allows the registration to continue
          this.dialogRef.close({
            valid: true,
            token: this.sessionData.token,
            telephone: this.sessionData.telephone
          })
        } else {
          this.snack.open('Spiacenti, il codice Otp inserito non è corretto', 'Chiudi')
        }
      },
      (err) => {
        this.snack.open('Spiacenti, il codice Otp inserito non è corretto', 'Chiudi')
      }
    )
  }

  checkOtp(): Observable<any> {
    const otpCode = this.form.get('otpCode')?.value
    return this.userService.checkOTPVerificationToken(otpCode, this.sessionData.token).pipe(delay(1000))
  }

  setNewTelephoneNumber() {
    this.sessionData.telephone = this.telephoneForm.get('telephone')?.value
    this.sendOtpCode()
    this.changeFormView()
  }

  sendOtpCode() {
    this.userService.sendOTPVerificationToken(this.sessionData.telephone).subscribe(
      (state) => {
        if (state && state.token) {
          this.sessionData.token = state.token
          this.snack.open('Un codice di verifica è stato inviato al numero di telefono associato', 'Chiudi', {
            duration: 2000
          })
        }
      },
      (err) => {
        this.snack.open('Spiacenti, non è stato possibile inviare il codice di verifica', 'Chiudi')
      }
    )
  }

  isValidPhoneNumber(formControl: FormControl): ValidationErrors | null {
    if (!formControl.value) {
      return null
    }
    const formValue = formControl.value as string
    const value = formValue.startsWith('+') ? formValue.substring(1).replace(/\s/g, '') : formValue.replace(/\s/g, '')
    const r = new RegExp(/^[0-9]+$/)

    return !r.test(value) ? { phoneNumberNotValid: true } : null
  }

  changeFormView() {
    this.spinner.start()
    setTimeout(() => {
      this.telephoneFormIsVisible = !this.telephoneFormIsVisible
      this.spinner.stop()
    }, 700)
  }
}
