export interface UserRequest {
  _id?: string
  status?: string
  customerId?: string
  updatedAt?: Date | string
  createdAt?: Date | string
  case: {
    caseId?: string
    type: string
    fiscalCode?: string
    surname: string
    name: string
    email: string
    productCode?: string
    policyNumber?: string
    reason?: string
    IBAN?: string
    bank_account_header?: string
    free_text?: string
    history?: any[]
  }
}

export enum TipoRichiesta {
  DomandaProdotto = 'contract-clarification',
  // RichiestaOperativaSpecificaPiattaforma = 'Richiesta operativa specifica sulla piattaforma',
  DomandaGenerica = 'generic-question',
  ReInvioDocumentoPolizza = 'resend-insurance-document',
  AnnulloDisdettaContraente = 'cancel-by-customer-termination',
  // AnnulloCessazioneRischio = 'cancel-by-risk-termination',
  AnnulloDirittoRipensamento = 'cancel-by-right-to-reconsider',
  AnnulloRecessoSinistroContraente = 'cancel-by-withdrawal-contractor-claim'
}

export enum TipoRichiestaLabel {
  'contract-clarification' = 'Domanda sul prodotto',
  // RichiestaOperativaSpecificaPiattaforma = 'Richiesta operativa specifica sulla piattaforma',
  'generic-question' = 'Domanda generica',
  'resend-insurance-document' = 'Re-invio documento di polizza',
  'cancel-by-customer-termination' = 'Annullo disdetta del contraente',
  // 'cancel-by-risk-termination' = 'Annullo per cessazione rischio',
  'cancel-by-right-to-reconsider' = 'Annullo per diritto di ripensamento',
  'cancel-by-withdrawal-contractor-claim' = 'Annullo per recesso sinistro Contraente'
}

export enum RichiestaRegistrazione {
  'customer-registration-validation' = 'Convalida registrazione utente'
}

export enum CASE_STATUS_CODE {
  'SENT' = 'Inviato',
  'EVALUATING' = 'In fase di valutazione',
  'REFUSED' = 'Rifiutato',
  'IN_PROGRESS' = 'Preso in carico',
  'CLOSED' = 'Chiuso',
  'OPENED' = 'Aperto'
}
