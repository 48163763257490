import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { SlideInOutAnimation } from '../animations'

@Component({
  selector: 'helvetia-italia-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: [SlideInOutAnimation]
})
export class DrawerComponent implements OnInit, OnDestroy {
  animationState = 'leave'
  @Input() state: Subject<string> = new Subject()
  constructor() {}

  ngOnInit(): void {
    this.state.subscribe((state) => (this.animationState = state))
  }
  close() {
    this.animationState = 'leave'
  }
  ngOnDestroy() {
    this.state.unsubscribe()
  }
}
