import { Component, Input } from '@angular/core'

@Component({
  selector: 'helvetia-italia-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent {
  @Input() icon = ''
  @Input() content = ''
  constructor() {}
}
