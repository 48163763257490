import { Document } from './document'

export enum UserType {
  AGENT = 'agent',
  DIREZIONE = 'direzione',
  PAGANINI = 'paganini',
  CUSTOMER = 'customer',
  CUSTOMER_AS_AGENT = 'CUSTOMER_AS_AGENT',
  LIQUIDATORE = 'LIQUIDATORE',
  ASSHOP = 'AS_SHOP',
  AFFINITY = 'affinity'
}

export class UserState {
  iat?: number
  logged?: boolean | undefined | null
  type?: UserType | null
  productNode?: string | undefined | null
  ageDescription?: string
  loggedInAs?: LoggedInAs | undefined | null
  shop?: any
  groups?: string[]
  tokenInfo?: any
  loginToken?: string
}

export class LoggedInAs {
  address?: string | null
  city?: string
  clientId?: string
  dateOfBirth?: Date | string
  district?: string
  documents?: Document[]
  email?: string
  fiscalCode?: string
  gender?: string
  name?: string
  phone_number?: string
  placeOfBirth?: string
  postalCode?: string
  surname?: string
  userId?: number
  origin?: string
  privacy_consent?: any
  profiling_privacy?: any
  service_privacy?: any
  marketing_privacy?: any
  username?: string
  phoneNumber?: string
  zip?: string
  fea_enabled?: any
  cb_user_id?: string
}

export class User {
  user_id?: number | undefined
  connection_id?: string | undefined | null
  connection?: string | undefined | null
  login_token?: string | undefined | null
  username?: string | undefined | null
  password?: string | undefined | null
  authdata?: string
  // @ts-ignore
  userState?: {
    logged: boolean | null
    productNode: string | null
    type: string | null
    loggedInAs: {
      name: string | null
      email: string | null
    }
  }
  userData?: {
    email?: string
    telephone?: string
    address?: string
    city?: string
    cap?: string
    postalCode?: string
    district?: string
    gender?: string
    name?: string
    surname?: string
    dateOfBirth?: Date
    placeOfBirth?: string
    fiscalCode?: string
    origin?: string
    visitorId?: string
    privacy_consent?: ConsentPayload
    service_privacy?: ConsentPayload
    marketing_privacy?: ConsentPayload
    profiling_privacy?: ConsentPayload
    consents?: { [key: string]: boolean }[]
  }
}

export interface ConsentPayload {
  company_code?: string
  consent?: boolean
  timestamp?: string
}
