import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
// import { ModalWithLabelComponent } from '@helvetia-italia/angular-libs/ng-ui-components'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { UserService } from '../ui-services/user/user.service'

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(public snack: MatSnackBar, public userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token = this.userService.getLoginToken()
    // const user = this.userService.getPartialUser()
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       // @ts-ignore
    //       'x-user-id': `${user?.user_id}`,
    //       Authorization: `${token}`
    //     }
    //   })
    // }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.snack.open('Si è verificato un errore, riprova più tardi', 'Chiudi')
        return throwError(error.message)
      })
    )
  }
}
