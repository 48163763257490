/* eslint-disable no-secrets/no-secrets */
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { IPolicyFea, FilterPolizza } from '@helvetia-italia/shared/business'
import { Observable } from 'rxjs'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root'
})
export class PolizzaFeaAgentsService {
  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private env: Environment,
    private user: UserService
  ) {}

  getList(params?: FilterPolizza): Observable<any> {
    let USER_NAME = this.user.loggedInAs()?.username?.substring(4)

    const tempArr = this.user.loggedInAs()?.username?.split('.')
    if (tempArr) {
      tempArr[0] = parseInt(tempArr[0]).toString()
    }
    USER_NAME = tempArr?.join('.')

    let queryUrl = `${this.env.feaStatusUrl}/${USER_NAME}`

    if (params) {
      if (params.status && !(params.dataCreazioneDa && params.dataCreazioneA)) {
        queryUrl = `${queryUrl}?status=${params.status}`
      }

      if (params.dataCreazioneDa && params.dataCreazioneA) {
        queryUrl = `${queryUrl}?dataCreazioneDa=${params.dataCreazioneDa}&dataCreazioneA=${params.dataCreazioneA}`
      }

      if (params.status && params.dataCreazioneDa && params.dataCreazioneA) {
        queryUrl = `${queryUrl}&status=${params.status}`
      }

      if (queryUrl.indexOf('?') == -1 && params.numeroPolizza) {
        queryUrl = `${queryUrl}?numeroPolizza=${params.numeroPolizza}`
      }

      if (queryUrl.indexOf('?') != -1 && params.numeroPolizza && queryUrl.indexOf('?numeroPolizza=') == -1) {
        queryUrl = `${queryUrl}&numeroPolizza=${params.numeroPolizza}`
      }

      if (queryUrl.indexOf('?') == -1 && params.nodo) {
        queryUrl = `${queryUrl}?nodo=${params.nodo}`
      }

      if (queryUrl.indexOf('?') != -1 && params.nodo && queryUrl.indexOf('?nodo=') == -1) {
        queryUrl = `${queryUrl}&nodo=${params.nodo}`
      }
    }

    return this.http.get<IPolicyFea[]>(`${queryUrl}`, {
      headers: { 'x-user-id': `${USER_NAME}` }
    })
  }
}
