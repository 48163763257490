import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { InputWithLabelModule } from '../../inputs/input-with-label/input-with-label.component.module'

import { FormLoginComponent } from './form-login.component'

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputWithLabelModule, MatFormFieldModule],
  declarations: [FormLoginComponent],
  exports: [FormLoginComponent]
})
export class FormLoginModule {}
