import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PopoverLoginComponent } from './popover-login.component.component'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { UxNgPopoverModule } from '@helvetiait/ux-ng-material-library'
import { ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ForgotPasswordModule } from '../input-components/forgot-password/forgot-password.module'
import { InputWithLabelModule } from '../inputs/input-with-label/input-with-label.component.module'

const material_modules = [MatIconModule, MatButtonModule]

@NgModule({
  declarations: [PopoverLoginComponent],
  imports: [
    CommonModule,
    UxNgPopoverModule,
    ...material_modules,
    ReactiveFormsModule,
    FlexLayoutModule,
    InputWithLabelModule,
    ForgotPasswordModule
  ],
  exports: [PopoverLoginComponent]
})
export class PopoverLoginModule {}
