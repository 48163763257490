import { Directive, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { AbstractItemsService } from '@helvetia-italia/angular-libs/ng-shared'
import moment from 'moment'
import { Subscription } from 'rxjs'

import { TableDefaultColumn } from '../table-default-column'

@Directive()
export class AbstractTableComponent<T> implements OnInit, OnDestroy, OnChanges {
  @Input() columns: TableDefaultColumn<T>[] = []
  @Input() displayedColumns: string[] = []
  @Input() dateTypeColumns: string[] = []
  @Input() service: AbstractItemsService<T> = {}
  @Input() params: any
  @Input() exportExcel = false
  dataSource = new MatTableDataSource<T>()
  showError = false
  showSpinner = false
  showNoResults = false
  subscription$!: Subscription
  ngOnInit() {
    this.initTable()
  }

  ngOnDestroy(): void {
    this.subscription$ ? this.subscription$.unsubscribe() : null
  }

  ngOnChanges() {
    this.showNoResults = false
    this.initTable()
  }

  initTable() {
    if (this.service && this.service.getList) {
      this.showSpinner = true
      this.subscription$ = this.service.getList(this.params).subscribe(
        (items) => {
          if (!items || items.length === 0) {
            this.dataSource.data = []
            this.showNoResults = true
          }
          this.showSpinner = false
          this.setDataSource(items)
          this.dataSource.sortingDataAccessor = (item: any, property) => {
            if (this.dateTypeColumns.includes(property)) {
              const dateField = moment(item[property], 'DD/MM/YYYY').toDate()
              return dateField.getTime()
            } else {
              return item[property] as string
            }
          }
        },
        (err) => {
          this.showError = true
          this.showNoResults = false
          this.showSpinner = false
        }
      )
    }
  }

  setDataSource(items: T[]) {
    this.dataSource.data = items
  }
}
