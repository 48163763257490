import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms'
import { RecapitoOptions, RecapitoUtente } from './recapito'
import { Subscription } from 'rxjs'
import { GroupValueAccessorBase } from '../../inputs'
import { CommonValidators } from '@helvetia-italia/angular-libs/ng-shared'
@Component({
  selector: 'helvetia-italia-recapito-utente',
  templateUrl: './recapito-utente.component.html',
  styleUrls: ['./recapito-utente.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RecapitoUtenteComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: RecapitoUtenteComponent,
      multi: true
    }
  ]
})
export class RecapitoUtenteComponent extends GroupValueAccessorBase implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() data!: RecapitoUtente
  @Input() options!: RecapitoOptions

  public subscriptions: Subscription[] = []

  constructor(
    private builder: FormBuilder,
    public cdRef: ChangeDetectorRef,
    public commonValidators: CommonValidators
  ) {
    super(cdRef)
    this.form = this.builder.group({
      tipo: '',
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', [Validators.required, commonValidators.postalCodeValidator]],
      district: ['', Validators.required]
    })
  }

  ngOnInit() {
    if (this.data) {
      this.form.patchValue(this.data)
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  fillFormByAddress(result: google.maps.places.PlaceResult) {
    if (result && result.address_components) {
      const cap = this.form.get('postalCode')
      const provincia = this.form.get('district')
      const citta = this.form.get('city')

      const address_provincia = result.address_components.find((comp) =>
        comp.types.includes('administrative_area_level_2')
      )
      const address_citta = result.address_components.find((comp) => comp.types.includes('administrative_area_level_3'))
      const address_postalcode = result.address_components.find((comp) => comp.types.includes('postal_code'))
      // TODO

      //istanbul ignore next line
      address_citta ? citta?.setValue(address_citta.short_name) : citta?.setValue('')
      // TODO
      //istanbul ignore net line
      address_provincia ? provincia?.setValue(address_provincia.short_name) : provincia?.setValue('')
      // TODO
      //istanbul ignore net line
      address_postalcode ? cap?.setValue(address_postalcode.short_name) : cap?.setValue('')
    }
  }

  fillFormByCity(result: google.maps.places.PlaceResult) {
    if (result && result.address_components) {
      const cap = this.form.get('postalCode')
      const provincia = this.form.get('district')
      const address_provincia = result.address_components.find((comp) =>
        // TODO

        comp.types.includes('administrative_area_level_2')
      )
      const address_postalcode = result.address_components.find((comp) => comp.types.includes('postal_code'))
      // TODO
      //istanbul ignore net line
      address_provincia ? provincia?.setValue(address_provincia.short_name) : provincia?.setValue('')
      // TODO
      //istanbul ignore net line
      address_postalcode ? cap?.setValue(address_postalcode.short_name) : cap?.setValue('')
    }
  }

  validate() {
    return this.form.valid ? null : { profile: { valid: false } }
  }
}
