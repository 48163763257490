import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms'
import { ApsaService, QuotationService, Vehicle } from '@helvetia-italia/angular-libs/ng-shared'
import { Observable, of, Subscription, throwError } from 'rxjs'
import { map } from 'rxjs/operators'

import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
import moment from 'moment'
// @ts-ignore
@Component({
  selector: 'helvetia-italia-dati-veicolo',
  templateUrl: './dati-veicolo.component.html',
  styleUrls: ['./dati-veicolo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatiVeicoloComponent,
      multi: true
    }
  ]
})
export class DatiVeicoloComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  form: FormGroup
  subscriptions: Subscription[] = []

  @Input() listBrands: any[] = []
  @Input() listModels: any[] = []
  @Input() listCarFittings: any[] = []
  @Input() vehicle!: Vehicle
  @Input() type!: string
  @Input() formValue!: any

  checkForUpdate = { brand: false, model: false, fitting: false }

  get value(): any {
    return this.form.value
  }

  set value(value: any) {
    this.form.setValue(value)
    this.onChange(value)
    this.onTouched()
  }

  constructor(
    private builder: FormBuilder,
    public apsaService: ApsaService,
    private quotationService: QuotationService,
    public spinner: UxNgSpinnerService
  ) {
    this.form = this.builder.group({
      brand: '',
      model: '',
      carFitting: '',
      carValue: ''
    })
    this.form.get('carValue')?.disable()
  }

  ngOnInit() {
    //@ts-ignore
    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value)
        this.onTouched()
      })
    )
  }

  ngAfterViewInit() {
    this.form.get('brand')?.valueChanges.subscribe((newBrand) => {
      this.updateList('marca', newBrand)?.subscribe((res) => {
        this.spinner.stop()
      })
    })
    this.form.get('model')?.valueChanges.subscribe((newModel) => {
      this.updateList('modello', newModel)?.subscribe((res) => {
        this.spinner.stop()
      })
    })
    this.form.get('carFitting')?.valueChanges.subscribe((newFitting) => {
      this.updateList('allestimento', newFitting)?.subscribe((res) => {
        this.spinner.stop()
      })
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  updateList(list: string, value: string): Observable<any> | undefined {
    console.log('updateList', list, value)
    this.spinner.start()
    let marca = undefined
    let modello = undefined
    let allestimento = undefined
    let dataImmatricolazione = ''
    let parsedCilindrata = undefined
    let cavalli = undefined
    if (!value || value.trim().length === 0) return

    if (this.vehicle && this.vehicle.dataRetrieve && this.vehicle.dataRetrieve.sivi) {
      dataImmatricolazione = moment(this.vehicle.dataRetrieve.sivi.dataImmatricolazione, 'YYYY-MM-DD').format('YYYYMM')
      parsedCilindrata = parseInt(this.vehicle.dataRetrieve.sivi.cilindrata, 10) / 100
      cavalli =
        this.vehicle.dataRetrieve.sivi.cavalliFiscali === '000' ? '' : this.vehicle.dataRetrieve.sivi.cavalliFiscali
    } else {
      throwError({ message: 'Sivi non é presente nei dati veicolo' })
    }
    this.resetFormOnListUpdate(list)

    if (this.type !== 'Vehicle' && list === 'allestimento') {
      const allestimentoSelected = this.listCarFittings.filter((allestimento) => {
        return allestimento.code === value
      })
      this.quotationService.addVariable(
        'carFitting',
        this.listCarFittings.find((b) => b.code === value)
      )

      return this.apsaService
        .infoBike(
          dataImmatricolazione,
          this.vehicle.info.codiceMarca,
          this.vehicle.info.codiceModello,
          parsedCilindrata,
          allestimentoSelected[0].code,
          this.vehicle.dataRetrieve.sivi.marcaModelloVeicolo,
          this.vehicle.dataRetrieve.sivi.nomeCostruttore,
          this.vehicle.dataRetrieve.sivi.giriMotore,
          this.vehicle.dataRetrieve.sivi.pesoVeicolo,
          this.vehicle.dataRetrieve.sivi.cavalliFiscali,
          this.vehicle.dataRetrieve.sivi.potenzaMassima,
          allestimentoSelected[0].progressivo
        )
        .pipe(
          map((result: any) => {
            this.vehicle.info = result
            this.form.get('carValue')?.setValue(parseInt(result.valoreUsato, 10), { emitEvent: false })
            this.quotationService.addVariable('carValue', this.form.get('carValue')?.value)
          })
        )
    }

    if (this.type === 'Vehicle' && list === 'allestimento') {
      this.vehicle.info.codiceAllestimento = value
      allestimento = value
      marca = this.form.get('brand')?.value
      modello = this.form.get('model')?.value
      this.quotationService.addVariable(
        'carFitting',
        this.listCarFittings.find((b) => b.code === value)
      )

      return this.apsaService.infocarDetail(cavalli, dataImmatricolazione, marca, modello, allestimento).pipe(
        map((result: any) => {
          this.vehicle.info = result
          this.form.get('carValue')?.setValue(parseInt(result.valoreUsato, 10), { emitEvent: false })
          this.quotationService.addVariable('carValue', this.form.get('carValue')?.value)
        })
      )
    }

    switch (list) {
      case 'marca':
        this.vehicle.info.codiceMarca = value
        marca = value
        this.quotationService.addVariable(
          'brand',
          this.listBrands.find((b) => b.code === value)
        )
        break
      case 'modello':
        this.vehicle.info.codiceModello = value
        modello = value
        marca = this.form.get('brand')?.value
        this.quotationService.addVariable(
          'model',
          this.listModels.find((b) => b.code === value)
        )

        break
    }

    if (this.type === 'Vehicle') {
      return this.apsaService.infoCarLists(cavalli, dataImmatricolazione, marca, modello, allestimento).pipe(
        map((result: any) => {
          switch (list) {
            case 'marca':
              this.listModels = result.list
              this.quotationService.addVariable(
                'brand',
                this.listBrands.find((b) => b.code === value)
              )
              break
            case 'modello':
              this.listCarFittings = result.list
              this.quotationService.addVariable(
                'model',
                this.listModels.find((b) => b.code === value)
              )

              break
          }
        })
      )
    } else {
      return this.apsaService.infoBikeLists(dataImmatricolazione, parsedCilindrata, marca, modello).pipe(
        map((result: any) => {
          switch (list) {
            case 'marca':
              this.listModels = result.list
              this.quotationService.addVariable(
                'brand',
                this.listBrands.find((b) => b.code === value)
              )
              break
            case 'modello':
              result.list.map((allestimenti: any) => {
                allestimenti.description = allestimenti.description + ' - ' + allestimenti.Im
                allestimenti.code = allestimenti.code + '-' + allestimenti.progressivo
                return allestimenti
              })
              this.listCarFittings = result.list
              this.quotationService.addVariable(
                'model',
                this.listModels.find((b) => b.code === value)
              )
              break
          }
        })
      )
    }
  }

  resetFormOnListUpdate(
    // item: 'marca' | 'modello' | 'allestimento'
    item: any
  ) {
    switch (item) {
      case 'marca':
        this.form.get('model')?.reset(null, { emitEvent: false })
        this.form.get('carFitting')?.reset(null, { emitEvent: false })
        this.form.get('carValue')?.reset(null, { emitEvent: false })
        break
      case 'modello':
        this.form.get('carFitting')?.reset(null, { emitEvent: false })
        this.form.get('carValue')?.reset(null, { emitEvent: false })
        break
      case 'allestimento':
        this.form.get('carValue')?.reset(null, { emitEvent: false })
        break
    }
  }

  // Control Value Accessor Implementation
  onChange: any = () => {}
  onTouched: any = () => {}
  onValidatorChange: any = () => {}

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  writeValue(value: any) {
    console.log('Dativeicolo', value)
    if (value) {
      this.form.patchValue(value)
    }

    if (value === null) {
      this.form.reset()
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn
  }

  //TODO
  /*istanbul ignore next function */
  validate() {
    return of(this.form.valid ? null : { dataVeicoloNotValid: true })
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable()
  }
}
