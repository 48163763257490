import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import {
  FooterTemplate,
  HeaderTemplate,
  Product,
  StateService,
  AbstractItemsService,
  UserService,
  UserType
} from '@helvetia-italia/angular-libs/ng-shared'
import { TableDefaultColumn } from '../table-default/table-default-column'
import { IPolicyFea, FilterPolizza } from '@helvetia-italia/shared/business'
import { isNil } from 'lodash'
import moment from 'moment'
import { MatSort } from '@angular/material/sort'
const s = (window as any).s

@Component({
  selector: 'helvetia-italia-policies-fea',
  templateUrl: './policies-fea.component.html',
  styleUrls: ['./policies-fea.component.scss']
})
export class PoliciesFeaComponent implements OnInit {
  @Input() polizzaFeaService: AbstractItemsService<IPolicyFea> = {}
  @Input() direzione = false
  currentURL: string
  filters: FilterPolizza
  form: FormGroup
  statusList: { value: string; label: string }[]
  statusSelected: string
  fileNameExcel = 'polizze-non-firmate'
  sheetNameExcel = 'POLIZZE NON FIRMATE'
  isVistaCompleta = false
  dispayColumnCheck = new FormControl([])
  dispayColumnCheckLabel = 'Vista Completa'
  status = new FormControl([])
  columns: TableDefaultColumn<IPolicyFea>[] = [
    {
      id: 'numberPolizza',
      title: 'Num. Polizza',
      type: 'text',
      value: (row: IPolicyFea) => row.numeroPolizza
    },
    {
      id: 'descProdotto',
      title: 'Desc. Prodotto',
      type: 'text',
      value: (row: IPolicyFea) => row.descProdotoo
    },
    {
      id: 'dataEffetto',
      title: 'Data Effetto',
      type: 'text',
      value: (row: IPolicyFea) => row.dataEffetto
    },
    {
      id: 'contraente',
      title: 'Contraente',
      type: 'text',
      value: (row: IPolicyFea) => row.contraente
    },
    {
      id: 'telefonoFirm',
      title: 'Tel. Firmatario',
      type: 'text',
      value: (row: IPolicyFea) => row.firmatario?.phoneNumber
    },
    {
      id: 'emailFirm',
      title: 'Email Firmatario',
      type: 'text',
      value: (row: IPolicyFea) => row.firmatario?.email
    },
    {
      id: 'identificativo',
      title: 'Cod. Fiscale/P.IVA',
      type: 'text',
      value: (row: IPolicyFea) => row.identificativo
    },
    {
      id: 'dataCreazioneLink',
      title: 'Data Creazione',
      type: 'text',
      value: (row: IPolicyFea) => row.dataCreazioneLink
    },
    {
      id: 'descDocumento',
      title: 'Desc. Documento',
      type: 'text',
      value: (row: IPolicyFea) => row.descCausale
    },
    {
      id: 'utente',
      title: 'Username',
      type: 'text',
      value: (row: IPolicyFea) => row.utente
    },
    {
      id: 'codAgente',
      title: 'Cod. Nodo',
      type: 'text',
      value: (row: IPolicyFea) => row.codAgente
    },
    {
      id: 'status',
      title: 'Stato',
      type: 'text',
      value: (row: IPolicyFea) => row.status
    }
  ]
  displayedColumns = this.reduceColumnDisplayFirm()

  dateTypeColumns = ['dataCreazioneLink', 'dataEffetto']

  matTableLen = 2100
  textCss = `.mat-table  {
    min-width: ${this.matTableLen}px !important;
  }`

  toolTips = [{ id: 'contraente', text: 'tooltipTextFirmatario', title: 'Firmatario ' }]
  company: string
  products: Product[] = []
  visitorId = ''
  constructor(
    private builder: FormBuilder,
    private stateService: StateService,
    public route: Router,
    private user: UserService
  ) {
    this.currentURL = this.route.url
    this.statusSelected = 'signLinkGenerated'
    const filters = {
      status: 'signLinkGenerated'
    }
    document.getElementsByTagName('style')[0].append(this.textCss)
    this.filters = filters
    // set header template
    this.form = this.builder.group({
      dataCreazioneDa: [''],
      dataCreazioneA: [''],
      status: [''],
      dispayColumnCheck: [''],
      numeroPolizza: [''],
      nodo: ['']
    })
    this.stateService.header$.next({
      template: HeaderTemplate.HomePage
    })
    this.stateService.footer$.next({
      template: FooterTemplate.Default
    })
    this.company = 'HI'
    this.statusList = [
      { label: 'All', value: '' },
      { value: 'signLinkGenerated', label: 'Non Firmate' },
      { value: 'documentStored', label: 'Firmate' },
      { value: 'signLinkDeleteProcessCompleted', label: 'Annullate' }
    ]
  }

  ngOnInit(): void {
    const user = this.user.loggedInAs()?.username
    const type = this.user.getLocalUserState()?.type
    if (isNil(user)) {
      this.route.navigate(['login'])
    }
    if (type === UserType.DIREZIONE && !this.direzione) {
      this.route.navigate(['login'])
    }
    if (type !== UserType.DIREZIONE && this.direzione) {
      this.route.navigate(['login'])
    }
    this.addLaunchColumn()
  }

  checkNumeroPolizza() {
    const numeroPolizza = this.form.controls.numeroPolizza.value
    if (numeroPolizza) {
      this.form.get('dataCreazioneDa')?.setErrors(null)
      this.form.get('dataCreazioneA')?.setErrors(null)
    } else {
      this.checkStatus({ value: this.statusSelected })
    }
  }

  checkNodo() {
    const nodo = this.form.controls.nodo.value
    if (nodo) {
      this.form.get('dataCreazioneDa')?.setErrors(null)
      this.form.get('dataCreazioneA')?.setErrors(null)
    } else {
      this.checkStatus({ value: this.statusSelected })
    }
  }

  eventDateChanged(date: any) {
    const dataCreazioneDa = this.form.controls.dataCreazioneDa.value
    this.form.get('dataCreazioneDa')?.setErrors(null)
    this.form.get('dataCreazioneA')?.setErrors(null)
    const dataCreazioneA = this.form.controls.dataCreazioneA.value
    const reqValidators = []
    const status = this.statusSelected
    if (date && status !== 'signLinkGenerated') {
      reqValidators.push(Validators.required)
      this.setMandatoryDates(reqValidators)
    } else {
      if (!dataCreazioneDa || !dataCreazioneA) this.setMandatoryDates([])
      if ((!dataCreazioneDa || !dataCreazioneA) && status !== 'signLinkGenerated') {
        reqValidators.push(Validators.required)
        this.setMandatoryDates(reqValidators)
      }
    }
    if (dataCreazioneDa && moment(dataCreazioneDa).isAfter(moment(dataCreazioneA))) {
      this.form
        .get('dataCreazioneDa')
        ?.setErrors({ '"data creazione da" deve essere minore o uguale di "data creazione a"': true })
    }
    if (moment(dataCreazioneDa).isBefore(moment(dataCreazioneA)) && dataCreazioneA.diff(dataCreazioneDa, 'days') > 62) {
      this.form
        .get('dataCreazioneA')
        ?.setErrors({ 'La diffferenza tra date supera il limite massimo di 62 giorni': true })
    }
    const numeroPolizza = this.form.controls.numeroPolizza.value
    const nodo = this.form.controls.nodo.value
    if (numeroPolizza | nodo) {
      this.form.get('dataCreazioneDa')?.setErrors(null)
      this.form.get('dataCreazioneA')?.setErrors(null)
    }
  }

  setMandatoryDates(validators: any[]) {
    const dataCreazioneDa = this.form.controls.dataCreazioneDa
    const dataCreazioneA = this.form.controls.dataCreazioneA
    dataCreazioneDa.setValidators(validators)
    dataCreazioneDa.updateValueAndValidity()
    dataCreazioneA.setValidators(validators)
    dataCreazioneA.updateValueAndValidity()
  }

  checkStatus(event: any) {
    if (event.value != 'signLinkGenerated') {
      const reqValidators = []
      reqValidators.push(Validators.required)
      this.setMandatoryDates(reqValidators)
    } else {
      this.setMandatoryDates([])
    }
    const numeroPolizza = this.form.controls.numeroPolizza.value
    if (numeroPolizza) {
      this.form.get('dataCreazioneDa')?.setErrors(null)
      this.form.get('dataCreazioneA')?.setErrors(null)
    }
    this.statusSelected = event.value
  }

  checkColumnDisplay(event: any) {
    if (event.checked) {
      this.isVistaCompleta = true
      this.displayedColumns = this.allColumnDisplayFirm()
    } else {
      this.isVistaCompleta = false
      this.displayedColumns = this.reduceColumnDisplayFirm()
    }
    this.addLaunchColumn()
  }

  addLaunchColumn() {
    const columnLaunch = this.displayedColumns.find((column) => column === 'launch')
    if (this.direzione && !columnLaunch) {
      this.displayedColumns.push('launch')
    }
  }

  reduceColumnDisplayFirm() {
    if (this.matTableLen != 2100) {
      this.updateTableLen(2100)
    }
    return [
      'dataCreazioneLink',
      'numberPolizza',
      'dataEffetto',
      'descProdotto',
      'descDocumento',
      'contraente',
      'telefonoFirm',
      'emailFirm'
    ]
  }

  updateTableLen(len: number) {
    this.matTableLen = len
    const textCss = `.mat-table  {
         min-width: ${this.matTableLen}px !important;
    }`
    document.getElementsByTagName('style')[0].append(textCss)
  }

  allColumnDisplayFirm() {
    if (this.matTableLen != 3600) {
      this.updateTableLen(3600)
    }
    return [
      'dataCreazioneLink',
      'numberPolizza',
      'dataEffetto',
      'descProdotto',
      'descDocumento',
      'contraente',
      'telefonoFirm',
      'emailFirm',
      'identificativo',
      'utente',
      'codAgente',
      'status'
    ]
  }

  launchClick(polizza: IPolicyFea) {
    console.log(polizza)
    const a = document.createElement('a')
    if (polizza.goSignLink) {
      a.href = polizza.goSignLink
      a.target = '_blank'
      a.click()
    }
  }

  downloadFile(data: any, filename: string) {
    const blob = new Blob([data], { type: 'application/pdf' })
    const a = document.createElement('a')
    document.body.appendChild(a)
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()
    console.log(filename)
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }

  search() {
    let dataCreazioneDa = this.form.get('dataCreazioneDa')?.value
    if (dataCreazioneDa) {
      dataCreazioneDa = moment(dataCreazioneDa).format('YYYY-MM-DD')
    }
    let dataCreazioneA = this.form.get('dataCreazioneA')?.value
    if (dataCreazioneA) {
      dataCreazioneA = moment(dataCreazioneA).add(1, 'days').format('YYYY-MM-DD')
    }

    const numeroPolizza = this.form.get('numeroPolizza')?.value
    const nodo = this.form.get('nodo')?.value

    this.fileNameExcel = this.statusSelected == 'documentStored' ? 'polizze-firmate' : 'polizze-annullate'
    this.sheetNameExcel = this.statusSelected == 'documentStored' ? 'POLIZZE FIRMATE' : 'POLIZZE ANNULLATE'
    if (this.statusSelected == 'signLinkGenerated') {
      this.fileNameExcel = 'polizze-non-firmate'
      this.sheetNameExcel = 'POLIZZE NON FIRMATE'
    }
    if (!this.statusSelected || this.statusSelected == '') {
      this.fileNameExcel = 'polizze-all'
      this.sheetNameExcel = 'POLIZZE'
    }

    const filters = {
      dataCreazioneDa: dataCreazioneDa,
      dataCreazioneA: dataCreazioneA,
      status: this.statusSelected,
      numeroPolizza: numeroPolizza,
      nodo: nodo
    }
    this.filters = filters
  }
}
