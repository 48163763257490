import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IndirizzoUtenteComponent } from './indirizzo-utente.component'
import {
  CheckboxModule,
  DateInputComponentModule,
  GoogleMapsAutocompleterModule,
  InputWithLabelModule,
  RadioGroupModule
} from '../../inputs'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatRadioModule } from '@angular/material/radio'
import { UxNgVerticalSpacerModule } from '@helvetiait/ux-ng-material-library'

const custom_modules = [
  InputWithLabelModule,
  GoogleMapsAutocompleterModule,
  DateInputComponentModule,
  CheckboxModule,
  RadioGroupModule
]

@NgModule({
  declarations: [IndirizzoUtenteComponent],
  imports: [
    CommonModule,
    UxNgVerticalSpacerModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ...custom_modules,
    MatIconModule,
    MatRadioModule
  ],
  exports: [IndirizzoUtenteComponent]
})
export class IndirizzoUtenteModule {}
