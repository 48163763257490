import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { Observable, of } from 'rxjs'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends ControlValueAccessorBase implements ControlValueAccessor, OnInit {
  @Input()
  placeholder?: string

  @Input()
  defaultValue?: string

  @Input()
  disabled?: boolean

  optSelectedValue?: string

  @Input()
  options: { value: string; label: string }[] = []

  @Output()
  optionSelected: EventEmitter<any> = new EventEmitter()

  constructor(public injector: Injector, public controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
  }

  // displayFn(value?: string) {
  //   return value ? this.options.find((_) => _.value === value)?.label : undefined
  // }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  onOptionSelected(value: any) {
    this.optionSelected.emit(value)
  }

  ngOnInit(): void {
    console.log('ngOnInit select')
    console.log(this.defaultValue)
    if (this.defaultValue) {
      console.log('init select')
      console.log(this.defaultValue)
      this.optSelectedValue = this.defaultValue
    }
  }
}
