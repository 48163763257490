import { Injectable } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { DynamicForm } from '../model/models'
import { CommonValidators } from '@helvetia-italia/angular-libs/ng-shared'
@Injectable({
  providedIn: 'root'
})
export class FormComposerGroupService {
  constructor(private builder: FormBuilder, public commonValidators: CommonValidators) {}

  getForm(config: DynamicForm): FormGroup {
    const res: FormGroup | any = {}
    config?.rows.forEach((row) => {
      row.components.forEach((component) => {
        if (!component.htmlContent && !component.parent) {
          const control = new FormControl('', component.validators, component.asyncValidators)
          config.model[component.name] ? control.setValue(config.model[component.name]) : null
          control.updateValueAndValidity()
          component.disabled ? control.disable() : null
          res[component.name] = control
        } else if (component.parent) {
          const group: FormGroup = res[component.parent] || new FormGroup({})
          group.addControl(component.name, new FormControl('', component.validators, component.asyncValidators))
          res[component.parent] = group
        }
      })
    })
    const formGroup = this.builder.group(res, {
      validators: config?.validators,
      asyncValidators: config?.asyncValidators
    })

    return formGroup
  }

  getValidatorsFromDictionary(validators: string[] = []) {
    return validators.map((validator) => {
      return this.commonValidators.getValidator(validator)
    })
  }
}
