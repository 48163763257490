import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'
import { HeaderFeaService, HeaderTemplate, StateService } from '@helvetia-italia/angular-libs/ng-shared'
import { Subscription } from 'rxjs'
import { Location } from '@angular/common'

@Component({
  selector: 'helvetia-italia-header-fea',
  templateUrl: './header-fea.component.html',
  styleUrls: ['./header-fea.component.scss']
})
export class HeaderFeaComponent implements OnInit {
  icon: string
  title: string
  backButton: boolean
  backButtonText: string
  backButtonLink: string
  subs: any
  hideBackButton = false

  constructor(
    public stateService: StateService,
    private location: Location,
    private headerFeaService: HeaderFeaService
  ) {
    this.title = ''
    this.icon = ''
    this.backButtonText = ''
    this.backButtonLink = ''
    this.backButton = true
  }
  ngOnInit(): void {
    this.subs = this.headerFeaService.onHide$.subscribe((value) => {
      this.hideBack(value)
    })
  }

  hideBack(value: boolean) {
    this.hideBackButton = value
  }

  back() {
    this.location.back()
  }
}
