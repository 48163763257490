import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, ENVIRONMENT, EnvironmentService, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { Observable, of } from 'rxjs'
import { Product, Tab } from '../../interfaces'
import { MOCK_HOMEPAGE_TABS } from '../../mocks/tabs'
const SESSION_STORAGE_PRODUCTS = 'ProductListService.list'

@Injectable({
  providedIn: 'root'
})
export class ProdottiService {
  _list!: Product[]
  apiUrl = this.env.apiServer
  constructor(private http: HttpClient, @Inject(EnvironmentServiceToken) private env: EnvironmentService) {}
  /* istanbul ignore next */
  getList(origin: string): Observable<any> {
    return this.http.get(`${this.env.getProdottiUrl}/${origin}/products/PRODUCT`)
  }

  getHomepageList(): Observable<Product[]> {
    const data = sessionStorage.getItem(SESSION_STORAGE_PRODUCTS)
    // if (data) {
    //   this._list = JSON.parse(data)
    //   return of(this._list)
    // }

    return this.http.get<Product[]>(`${this.apiUrl}api/prodotti`, {
      withCredentials: true
    })
  }

  getHomepageTabs(): Observable<Tab[]> {
    return of(MOCK_HOMEPAGE_TABS)
  }
}

@Injectable({
  providedIn: 'root'
})
export class MockProdottiService {
  _list!: Product[]
  apiUrl = this.env.apiServer
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}
  /* istanbul ignore next */
  getList(origin: string): Observable<any> {
    return of([])
  }

  getHomepageList(): Observable<Product[]> {
    return of([])
  }

  getHomepageTabs(): Observable<Tab[]> {
    return of(MOCK_HOMEPAGE_TABS)
  }
}
