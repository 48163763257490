import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatStepperModule } from '@angular/material/stepper'

import { DynamicStepperComponent } from './dynamic-stepper.component'
import { DynamicStepperDirective } from './dynamic-stepper-directive/dynamic-stepper.directive'

export const material_modules = [MatStepperModule, MatIconModule, MatButtonModule]
@NgModule({
  declarations: [DynamicStepperComponent, DynamicStepperDirective],
  imports: [CommonModule, FlexLayoutModule, ReactiveFormsModule, ...material_modules],
  exports: [DynamicStepperComponent]
})
export class DynamicStepperModule {}
