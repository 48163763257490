import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ConsensiUtenteWebComponent } from './consensi-utente-web.component'
import { UxNgSpinnerModule } from '@helvetiait/ux-ng-material-library'
import { NgFormComposerModule } from '@helvetia-italia/angular-libs/ng-form-composer'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { CheckboxModule, RadioGroupModule } from '../../inputs'

@NgModule({
  declarations: [ConsensiUtenteWebComponent],
  imports: [
    CommonModule,
    UxNgSpinnerModule,
    NgFormComposerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RadioGroupModule,
    CheckboxModule
  ],
  exports: [ConsensiUtenteWebComponent]
})
export class ConsensiUtenteWebModule {}
