import { User, UserType } from '../interfaces/user.models'

export const USER_MOCK: User = {
  user_id: 165312,
  connection_id: 'bdc4e0920d5f61bc75a6e40768933700e54d4c07bbd1eb22b030bbd844220bd8',
  connection:
    'https://api-staging.socialidnow.com/v1/marketing/login/connections/bdc4e0920d5f61bc75a6e40768933700e54d4c07bbd1eb22b030bbd844220bd8',
  login_token: '5430f40fc0bead9ce720030173c3fbc6d6ae635cc7bbc9a7294a664d1e7be76f',
  username: null,
  password: null,
  userState: {
    logged: true,
    productNode: '09000',
    type: UserType.CUSTOMER,
    loggedInAs: {
      name: null,
      email: null
    }
  }
}

export const USER_MOCK_AFFINITY: User = {
  user_id: 165312,
  connection_id: 'bdc4e0920d5f61bc75a6e40768933700e54d4c07bbd1eb22b030bbd844220bd8',
  connection:
    'https://api-staging.socialidnow.com/v1/marketing/login/connections/bdc4e0920d5f61bc75a6e40768933700e54d4c07bbd1eb22b030bbd844220bd8',
  login_token: '5430f40fc0bead9ce720030173c3fbc6d6ae635cc7bbc9a7294a664d1e7be76f',
  username: 'test@test.it',
  password: 'test',
  userState: {
    logged: true,
    productNode: '09000',
    type: UserType.AFFINITY,
    loggedInAs: {
      name: 'Agente 400',
      email: 'h400@helvetia.it'
    }
  }
}
