import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'
import { UxNgSpinnerService } from '@helvetiait/ux-ng-material-library'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
  constructor(
    public snack: MatSnackBar,
    // public userService: UserService,
    // public router: Router,
    private spinner: UxNgSpinnerService // private dialogRef: MatDialog
  ) {}

  /**
   *
   * Intercept method that works both on requests and responses
   * Requests: add local stored login and userId to the request headers
   * Response: automatically gives messages for every type of error; specifically for a 401 error, remove local stored userState and redirect user to login page
   * Handle the entire spinner activation. Every http  reuqest made by the application trigger a spinner
   *
   * @param request httpRequest intercepted
   * @param next HttpHandler managing generic error messages
   * @returns
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.start()
    // const token = this.userService.getLoginToken()
    // const user = this.userService.loggedInAs()
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       // @ts-ignore
    //       'x-user-id': `${user?.userId}`,
    //       Authorization: `${token}`
    //     }
    //   })
    // }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // this.userService.deleteLocalUserState()
          // this.dialogRef.closeAll()
          // this.router.navigate(['accedi'], { queryParams: { origin: this.originService.origin } })
          // this.snack.open('Sessione scaduta, accedi di nuovo', 'Chiudi')
          return throwError(error)
        }

        this.snack.open('Si è verificato un errore, riprova più tardi', 'Chiudi')
        return throwError(error)
      }),
      finalize(() => {
        this.spinner.stop()
      })
    )
  }
}
