import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { User, UserType } from '../../interfaces/user.models'
import isNil from 'lodash/isNil'
import { Router } from '@angular/router'
import { USER_MOCK, USER_MOCK_AFFINITY } from '../../mocks/user'
import { EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { of } from 'rxjs'

export interface AuthenticationServiceEnvironment {
  MOCK: boolean
  apiLogin: string
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>
  // @ts-ignore
  user: User

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(EnvironmentServiceToken) private environmentService: AuthenticationServiceEnvironment
  ) {
    this.setUser(USER_MOCK)
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(<string>sessionStorage.getItem('userState')))
    this.currentUser = this.currentUserSubject.asObservable()

    const user = sessionStorage.getItem('userState')
    if (!isNil(user)) {
      this.user = JSON.parse(user)
    }
  }

  public get currentUserValue(): User {
    console.log('-------------------', this.currentUserSubject.value)
    return this.currentUserSubject.value
  }

  setUser(user: User) {
    this.user = user
    sessionStorage.setItem('userState', JSON.stringify(this.user))
  }

  redirectUser() {
    switch (this.user && this.user.userState?.type) {
      case UserType.CUSTOMER:
      case UserType.ASSHOP:
      case UserType.CUSTOMER_AS_AGENT:
        this.navigateTo(['selly-4customer'])
        break
      case UserType.AGENT:
        this.navigateTo(['selly-4agents/catalog'])
        break
      case UserType.LIQUIDATORE:
        this.navigateTo(['selly-support/liquidatore'])
        break
      case UserType.AFFINITY:
        this.navigateTo(['selly-4affinity'])
        break
      default:
    }
  }

  navigateTo(url: string[]) {
    const originUrl = sessionStorage.getItem('originUrl')
    if (originUrl !== undefined && originUrl !== null) {
      sessionStorage.removeItem('originUrl')
      this.router.navigate([originUrl])
      return
    }
    this.router.navigate(url)
  }

  login(username: string, password: string) {
    return this.http.post<User>(`${this.environmentService.apiLogin}users/authenticate`, { username, password }).pipe(
      map((res) => {
        // console.log(res)
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        res.authdata = window.btoa(username + ':' + password)
        res.userState = {
          logged: USER_MOCK_AFFINITY.userState?.logged || null,
          productNode: USER_MOCK_AFFINITY.userState?.productNode || null,
          type: USER_MOCK_AFFINITY.userState?.type || null,
          loggedInAs: {
            name: USER_MOCK_AFFINITY.userState?.loggedInAs.name || null,
            email: USER_MOCK_AFFINITY.userState?.loggedInAs.email || null
          }
        }
        // localStorage.setItem('currentUser', JSON.stringify(user));

        this.setUser(res)
        this.currentUserSubject.next(res)
        return res
      })
    )
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('userState')
    this.setUser(USER_MOCK)
    // @ts-ignore
    this.currentUserSubject.next(null)
  }
  // todo
  /* istanbul ignore next */
  sendChangePasswordLink(email: string) {
    if (this.environmentService.MOCK === true) {
      this.router.navigate(['change-password'])
      return of({ sent: true })
    } else {
      return this.http.post<any>(`${this.environmentService.apiLogin}users/change-password`, { email }).pipe(
        map((res) => {
          return { sent: true }
        })
      )
    }
  }

  public isAuthenticated(): User {
    this.user = JSON.parse(<string>sessionStorage.getItem('userState'))
    // @ts-ignore
    return this.user
  }
}
