import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { Observable, of } from 'rxjs'

import { ControlErrorsMessageService } from '../helpers/control-errors-message.service'
import { ControlValueAccessorBase } from '../helpers/control-value-accessor-base'

@Component({
  selector: 'helvetia-italia-autocompleter',
  templateUrl: './autocompleter.component.html',
  styleUrls: ['./autocompleter.component.scss']
})
export class AutocompleterComponent extends ControlValueAccessorBase implements OnInit, ControlValueAccessor {
  @Input()
  placeholder?: string

  @Input()
  type = 'text'

  @Input()
  showCancel = true

  @Input()
  options: { value: string; label: string }[] = []

  @Output()
  optionSelected: EventEmitter<any> = new EventEmitter()

  filteredOptions: Observable<{ value: string; label: string }[]> = of([])

  onChange = (value: string) => {
    this.filteredOptions = of(this._filter(value))
  }

  constructor(public injector: Injector, private controlErrorsMessageService: ControlErrorsMessageService) {
    super(injector)
  }

  ngOnInit() {
    this.filteredOptions = of(this.options)
  }

  autocompleteHandler(event: any) {
    const text = (<HTMLInputElement>event.target).value
    if (text) {
      this.filteredOptions = of(this._filter(text))
    }
  }

  _filter(value: string): { value: string; label: string }[] {
    const filterValue = value.toLowerCase()
    return this.options.filter((option) => option.label.toLowerCase().includes(filterValue))
  }

  displayFn(value?: string) {
    return value ? this.options.find((_) => _.value === value)?.label : undefined
  }

  displayErrorMessage(error: string): string {
    return this.controlErrorsMessageService.getErrorMessage(error)
  }

  onOptionSelected(value: any) {
    this.optionSelected.emit(value)
  }
}
