/* eslint-disable no-secrets/no-secrets */
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Environment, EnvironmentServiceToken } from '@helvetia-italia/angular-libs/environment'
import { ZeroImprevisti } from '@helvetia-italia/shared/business'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root'
})
export class SASService {
  constructor(
    private http: HttpClient,
    @Inject(EnvironmentServiceToken) private env: Environment,
    private user: UserService
  ) {}

  calculatePreventivoSAS(zeroImprevistiForm: ZeroImprevisti) {
    console.log('calculatePreventivoSAS')
    console.log(JSON.stringify(zeroImprevistiForm))
    return this.http.post<any>(`${this.env.sasServiceUrl}`, zeroImprevistiForm, {
      observe: 'response',
      withCredentials: false
    })
  }
}
