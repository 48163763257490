import { Pipe, PipeTransform } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Product } from '../../interfaces'

@Pipe({ name: 'filtraProdotti' })
export class FiltraProdottiPipe implements PipeTransform {
  transform(list: Observable<Product[]>, group: string): any {
    return list.pipe(map((products) => products.filter((pdt: any) => pdt.group === group)))
  }
}

export const FILTER_PIPES = [FiltraProdottiPipe]
