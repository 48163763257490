import { Injectable } from '@angular/core'
import clone from 'lodash/clone'

@Injectable()
export class FormComposerLoaderService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private list: Array<any> = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(module: any): FormComposerLoaderService {
    this.list.push(module)
    return this
  }

  get() {
    return clone(this.list)
  }
}
